// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pass {
  color: #008000c5;
  text-align: center;
}

.fail {
  color: #ffffffa3;
  text-align: center;
}

.result-card {
  width: 500px;
  height: 300px;
  margin: auto;
  margin-top: 5%;
}

.ok-button {
  margin-left: 48%;
  margin-top: 1%;
  border: none;
  background-color: #6b6a6a;
  color: #fff;
  border: none;
  font-weight: bold;
}
.result-margin {
  padding-top: 12%;
}
.failresult-background {
  background: #000000b8;
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/modules/learners/Result.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".pass {\n  color: #008000c5;\n  text-align: center;\n}\n\n.fail {\n  color: #ffffffa3;\n  text-align: center;\n}\n\n.result-card {\n  width: 500px;\n  height: 300px;\n  margin: auto;\n  margin-top: 5%;\n}\n\n.ok-button {\n  margin-left: 48%;\n  margin-top: 1%;\n  border: none;\n  background-color: #6b6a6a;\n  color: #fff;\n  border: none;\n  font-weight: bold;\n}\n.result-margin {\n  padding-top: 12%;\n}\n.failresult-background {\n  background: #000000b8;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
