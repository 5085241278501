import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AreaSubareaState } from "../../models/redux-models";
import {
  createNewAreaSubarea,
  getAllAreaSubarea,
  getSubarea,
  updateAreaSubarea,
} from "../../service/areaSubareaApi";
import { API_FAILED, API_LOADING, API_SUCCEEDED } from "../../utilities/helper/constants";

const initialState: AreaSubareaState = {
  apiCreateStatus:"",
  apiGetStatus:"",
  apiUpdateStatus: "",
  areaSubareaData: [],
  subareaData: []
};

export const createAreaSubarea = createAsyncThunk(
  "/createAreaSubarea",
  async (data: any, thunkAPI) => {
    const response = await createNewAreaSubarea(data);
    return response;
  }
);

export const updateCurrentAreaSubarea = createAsyncThunk(
  "/updateAreaSubarea",
  async (data: any, thunkAPI) => {
    const response = await updateAreaSubarea(data);
    return response;
  }
);

export const getAreaSubarea = createAsyncThunk(
  "/getAllSubarea",
  async (data: any,thunkAPI) => {
    const response = await getAllAreaSubarea(data);
    return response;
  }
);
export const getSelectedSubarea = createAsyncThunk(
  "/getSubarea",
  async (data: any,thunkAPI) => {
    const response = await getSubarea(data);
    return response;
  }
);

const areaSubareaSlice = createSlice({
    name: "areaSubarea",
    initialState,
    reducers: {
      resetStatus: (state) => {
        state.apiCreateStatus = "";
        state.apiUpdateStatus = "";
      },
      resetAreaSubarea: () => initialState,
    },
    extraReducers(builder) {
      builder.addCase(getAreaSubarea.pending, (state) => {
        state.areaSubareaData = [];
        state.apiGetStatus = API_LOADING;
      });
      builder.addCase(getAreaSubarea.fulfilled, (state, {payload}) => {
        state.areaSubareaData = payload.data;
        state.apiGetStatus = API_SUCCEEDED;
      });
      builder.addCase(getAreaSubarea.rejected, (state) => {
        state.areaSubareaData = [];
        state.apiGetStatus = API_FAILED;
      });
      builder.addCase(getSelectedSubarea.pending, (state) => {
        state.subareaData = [];
        state.apiGetStatus = API_LOADING;
      });
      builder.addCase(getSelectedSubarea.fulfilled, (state, {payload}) => {
        state.subareaData = payload.data;
        state.apiGetStatus = API_SUCCEEDED;
      });
      builder.addCase(getSelectedSubarea.rejected, (state) => {
        state.subareaData = [];
        state.apiGetStatus = API_FAILED;
      });
      builder.addCase(createAreaSubarea.pending, (state) => {
        state.apiCreateStatus = API_LOADING;
      });
      builder.addCase(createAreaSubarea.fulfilled, (state, {payload}) => {
        state.apiCreateStatus = API_SUCCEEDED;
      });
      builder.addCase(createAreaSubarea.rejected, (state) => {
        state.apiCreateStatus = API_FAILED;
      });
      builder.addCase(updateCurrentAreaSubarea.pending, (state) => {
        state.apiUpdateStatus = API_LOADING;
      });
      builder.addCase(updateCurrentAreaSubarea.fulfilled, (state, {payload}) => {
        state.apiUpdateStatus = API_SUCCEEDED;
      });
      builder.addCase(updateCurrentAreaSubarea.rejected, (state) => {
        state.apiUpdateStatus = API_FAILED;
      });
  
      
    },
  });

  export default areaSubareaSlice;
  export { areaSubareaSlice };
  export const { resetAreaSubarea, resetStatus } = areaSubareaSlice.actions;