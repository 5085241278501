export const checkList = {
  checkListDetail: [
    {
      id: 216,
      userchecklistid: 42,
      action: "Trainings by LRL folks - QC, TFS, SNOW,  RIO, ITIL",
      whereData: "Lilly ODC",
      responsibility: "Tulika Pandey",
      timeline: "Monthly Activity",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 202,
      userchecklistid: 42,
      action: "VDI Client Installation",
      whereData: "GHD-> TT",
      responsibility: "Associate/Area leads",
      timeline: "ASAP after Machine Allocation",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 203,
      userchecklistid: 42,
      action: "Role Tagging in iCALMS and Competency Assignment",
      whereData: "iCALMS",
      responsibility: "Area leads",
      timeline: "Once WON allocation is done",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 204,
      userchecklistid: 42,
      action:
        "LRL On Boarding Request: VDI assignment, Success Factor Account Creation",
      whereData: "Service Now -> TT",
      responsibility: "Area leads/Fipnet Team",
      timeline: "Same Day when Lilly ID Arrives",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 205,
      userchecklistid: 42,
      action: "ITP assignment",
      whereData: "Area lead's machine",
      responsibility: "Area leads/ITP Coordinator",
      timeline: "Same Day when Lilly ID Arrives",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 206,
      userchecklistid: 42,
      action: "My password registration",
      whereData: "Q&A Update for resetting password",
      responsibility: "Associate/Area leads",
      timeline: "Same Day when Lilly ID Arrives",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 207,
      userchecklistid: 42,
      action: "Service Now  Trainings",
      whereData: "Training Site/SOP ITP",
      responsibility: "Associate/Area leads",
      timeline: "Same Day when Lilly ID Arrives",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 208,
      userchecklistid: 42,
      action: "LRL Collab Site Access Request",
      whereData: "LRL Collab Site ",
      responsibility: "Associate/Area leads",
      timeline: "Within 2 days when Lilly ID Arrives",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 209,
      userchecklistid: 42,
      action: "Request for inclusion in Lilly & LRL Email groups ",
      whereData: "Client E-mail",
      responsibility: "Area leads/Group Owners",
      timeline: "Within 2 days when Lilly ID Arrives ",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 210,
      userchecklistid: 42,
      action: "Lilly Profile Update",
      whereData: "LillyNow ",
      responsibility: "Associate/Area leads",
      timeline: "Within 2 days when Lilly ID Arrives",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 211,
      userchecklistid: 42,
      action: "Lilly Mandatory Trainings  (ITP) Completed",
      whereData: "LillyNow ",
      responsibility: "Associate/Area leads",
      timeline: "ASAP after VDI access arrives",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 212,
      userchecklistid: 42,
      action: "Service Now Queue Access granted",
      whereData: "Service Now",
      responsibility: "Associate/Area leads",
      timeline: "After completing the Lilly trainings",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 213,
      userchecklistid: 42,
      action: "Application Access Request and TT",
      whereData: "Service Now-> TT",
      responsibility: "Associate/Area leads",
      timeline: "After completing the Lilly trainings",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 214,
      userchecklistid: 42,
      action: "Regulus Access",
      whereData: "From Quality",
      responsibility: "Associate/Area leads",
      timeline: "After completing the Lilly trainings",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
    {
      id: 215,
      userchecklistid: 42,
      action: "Trainings by ITQS",
      whereData: "Lilly ODC",
      responsibility: "Leads",
      timeline: "Monthly Activity",
      status: "Not Started",
      updatedAt: "2020-05-08T10:12:40.576Z",
      updatedBy: "SYSTEM",
    },
  ],
};
