import { createSlice } from "@reduxjs/toolkit";
import { HrpState } from "../../models/redux-models";



const initialState: HrpState = {
  showChecklistUser: {},
  showChecklistUserType: "createChecklist",
  showHrpContent:"Hrp",
};

const hrpSlice = createSlice({
  name: "hrpNavigation",
  initialState,
  reducers: {
    setShowChecklistUserDetails: (state, action) => {
      state.showChecklistUser = action.payload;
    },
    setShowChecklistUserType: (state, action) => {
      state.showChecklistUserType = action.payload;
    },
    setShowHrpContent: (state, action) => {
      state.showHrpContent = action.payload;
    },
    resetHrp: () => initialState
  },
});

export default hrpSlice;
export { hrpSlice };
export const { setShowChecklistUserDetails, setShowChecklistUserType,setShowHrpContent,resetHrp } = hrpSlice.actions;
