import { Button, Form, InputGroup } from "react-bootstrap";
import { useAppSelector } from "../../../../../hooks/redux-hooks";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../ShowEditQuizNav.css";
import { useAppDispatch } from "../../../../../hooks/redux-hooks";
import {
  setSelectedAssignNav,
  addQues,
  getallqueslist,
} from "../../../../../store/slices/quizzesSlice";
import { useEffect, useState } from "react";
import "./Modify.css";
import { toast } from "react-toastify";
import {
  ACTIVE_FLAG,
  ADD_QUESTION_SUCCESS,
  API_SUCCEEDED,
  SIMILAR_QUES_PRESENT,
} from "../../../../../utilities/helper/constants";
import Swal from "sweetalert2";
import { TiDelete } from "react-icons/ti";

interface AddQuestionProps {
  id: string;
  options: string;
  questions: string;
  answers: string;
  status: string;
  questiontype: string;
  createdBy: string;
  quizStatus: string;
}

const AddQuestion = (props: any) => {
  const dispatch = useAppDispatch();
  const { apiAddQues, editQuizDetails, quizList } = useAppSelector(
    (store) => store.quizzesNavigation
  );
  let flag = false;
  const { id, title } = props;
  const [allValues, setAllValues] = useState<AddQuestionProps>({
    id: id,
    questions: "",
    options: "",
    answers: "",
    questiontype: "multiple",
    status: ACTIVE_FLAG,
    createdBy: "L006152",
    quizStatus: ACTIVE_FLAG,
  });
  const [optionObject, setOptionObject] = useState<Object>({});
  const [answerObject, setAnswerObject] = useState<Object>({});
  const [optionCount, setOptionCount] = useState<Array<string>>([]);
  const [lessOptionError, setLessOptionError] = useState<string>("");

  const handlePush = (e: any) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
    setLessOptionError("");
  };

  const handleAnswer = async (e: any, index: string) => {
    if (e.target.checked) {
      await setAnswerObject({
        ...answerObject,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.checked === false) {
      await setAnswerObject((current: any) => {
        const copy = { ...current };
        delete copy[index];
        return copy;
      });
    }
  };

  const handleOption = (e: any) => {
    setOptionObject({ ...optionObject, [e.target.name]: e.target.value });
  };

  const deleteOption = async (value: string) => {
    let newArr = optionCount;
    newArr.splice(newArr.indexOf(value), 1);
    setOptionCount(newArr);
    setOptionCount(newArr);
    await setOptionObject((current: any) => {
      const copy = { ...current };
      delete copy[value];
      return copy;
    });
    await setAnswerObject((current: any) => {
      const copy = { ...current };
      delete copy[value];
      return copy;
    });
  };

  const isExists = (currentQuestion: any, newQuestion: any) => {
    if (newQuestion.replace(/[^\w\s]|([?](?=[^\?]*\?))/g, "").trim().toLowerCase()===
    currentQuestion.questions.replace(/[^\w\s]/gi, "").trim().toLowerCase()) {
      flag = true;
      Swal.fire({
        title: SIMILAR_QUES_PRESENT,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(addQues(allValues));
          dispatch(
            getallqueslist({ id: editQuizDetails.id, status: ACTIVE_FLAG })
          );
          setLessOptionError("");
          setOptionObject({});
          setAnswerObject({});
          setOptionCount([]);
          setAllValues({
            ...allValues,
            questions: "",
          });
        }
      });
    }
  };

  useEffect(() => {
    setAllValues({
      ...allValues,
      options: JSON.stringify(Object.values(optionObject)),
      answers: JSON.stringify(Object.values(answerObject)),
      questiontype:
        Object.values(answerObject).length > 1 ? "multiple" : "single",
    });
  }, [optionObject, answerObject, optionCount]);

  useEffect(() => {
    if (apiAddQues === API_SUCCEEDED) {
      toast.success(ADD_QUESTION_SUCCESS);
      dispatch(
        getallqueslist({
          id: editQuizDetails.id,
          status: ACTIVE_FLAG,
        })
      );
    }
  }, [apiAddQues]);

  return (
    <div>
      <div className="text-center" style={{ margin: "20px" }}>
        <div className="d-inline-block" style={{ float: "left" }}>
          <IoMdArrowRoundBack
            className="delete-button"
            onClick={() => dispatch(setSelectedAssignNav("modifyQuiz"))}
          />
        </div>
        <h3 className="text-center d-inline-block title-style" title={title}>{title}</h3>
      </div>

      <div className="d-flex justify-content-center">
        <InputGroup className="question-input">
          <InputGroup.Text>Question</InputGroup.Text>
          <Form.Control
            as="textarea"
            value={allValues.questions}
            name="questions"
            onChange={(e) => handlePush(e)}
            required
            style={{resize:'none'}}
          />
        </InputGroup>
      </div>
      <div style={{ overflowY: "auto", maxHeight: "50vh" }}>
      {optionCount.map((option, index) => (
        <div key={`option${index}`} className="d-flex justify-content-center">
          <InputGroup className="option-input">
            <InputGroup.Checkbox
              className="checkbox"
              name={option}
              value={Object.values(optionObject)[index]}
              onClick={(e: any) => handleAnswer(e, option.toString())}
              checked={
                Object.keys(answerObject).indexOf(option.toString()) !== -1
                  ? true
                  : false
              }
            />
            <Form.Control
              name={option.toString()}
              required
              value={Object.values(optionObject)[index]}
              onChange={async (e) => {
                handleOption(e);
                await setAnswerObject((current: any) => {
                  const copy = { ...current };
                  delete copy[option.toString()];
                  return copy;
                });
                if (Object.keys(optionObject).length >= 2) {
                  setLessOptionError("");
                }
              }}
            />
          </InputGroup>
          <div className="d-flex justify-content-center align-items-center">
            <TiDelete
              className="delete-button"
              onClick={(e: any) => deleteOption(option.toString())}
            />
          </div>
        </div>
      ))}
      </div>
      {lessOptionError ? (
        <span style={{ color: "red",marginLeft:"5%" }}>{lessOptionError}</span>
      ) : null}
      <div className="text-center">
        <div className="d-inline-block button-container">
          <Button
          style={{ backgroundColor: "#582630", border:"none" }}
            onClick={() => {
              setLessOptionError("");
              setOptionCount((oldArray) => [
                ...oldArray,
                new Date().getTime().toString(),
              ]);
            }}
            disabled={
              optionCount.length > Object.values(optionObject).length ||
              Object.values(optionObject).includes("")
                ? true
                : false
            }
          >
            Add Option
          </Button>
        </div>
        <div className="d-inline-block button-container">
          <Button
          style={{ backgroundColor: "#582630", border:"none" }}
            onClick={async () => {
              if(allValues.questions.length === 0)
              {
                setLessOptionError("Question cannot be empty !");
              }
              else{
              if (
                Object.keys(optionObject).length >
                Array.from(
                  new Set(Object.values(optionObject).map((item: any) => item))
                ).length
              ) {
                setLessOptionError("Options should be unique !");
              } else {
                if (Object.keys(optionObject).length >= 2) {
                  quizList.map((question: any) =>
                    isExists(question, allValues.questions)
                  );
                  if (flag === false) {
                    dispatch(addQues(allValues));
                    setLessOptionError("");
                    setOptionObject({});
                    setAnswerObject({});
                    setOptionCount([]);
                    setAllValues({
                      ...allValues,
                      questions: "",
                    });
                  }
                } else {
                  setLessOptionError("Please add atleast 2 options.");
                }
              }
            }}}
            disabled={
              optionCount.length > Object.values(optionObject).length ||
              Object.keys(optionObject).length < 1 ||
              Object.values(optionObject).includes("") ||
              Object.keys(answerObject).length < 1
                ? true
                : false
            }
          >
            Add Question
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddQuestion;
