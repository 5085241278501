// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skeleton-element {
    width: 55vw;
    height: 50px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
    margin: 5px 0;
}

@keyframes loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/utilities/Loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yEAAyE;IACzE,0BAA0B;IAC1B,gCAAgC;IAChC,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI;QACI,4BAA4B;IAChC;IACA;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".skeleton-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.skeleton-element {\n    width: 55vw;\n    height: 50px;\n    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);\n    background-size: 200% 100%;\n    animation: loading 1.5s infinite;\n    border-radius: 4px;\n    margin: 5px 0;\n}\n\n@keyframes loading {\n    0% {\n        background-position: -200% 0;\n    }\n    100% {\n        background-position: 200% 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
