import { useState } from "react";
import QuizzesContent from "./QuizzesContent";
import QuizzesNav from "./QuizzesNav";

const Quizzes = () => {
  const [isCollasped, setIssCollasped] = useState<Boolean>(false);
    return (
      <>
        <div
        className="content-area"
      >
        <QuizzesNav isCollasped={isCollasped} setIssCollasped={setIssCollasped} />
        <QuizzesContent isCollasped={isCollasped} setIssCollasped={setIssCollasped} />
      </div>
      </>
    );
  };
  export default Quizzes;