import { ReactComponent as Logo } from './Iselect_Logo.svg';


const IselectLogo = (props :any) => (
  
  <div style={{  height: "10%",
  position: "absolute",
  top: "20px",
  left: "23px",}}>
    <Logo fill={props.color} height="70px" />
  </div>
);
export default IselectLogo;