import { useEffect } from "react";
import { useState } from "react";
import "./QuizzesNav.css";
import { Card } from "react-bootstrap";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import {
  setEditQuizDetails,
  setSelectedAssignNav,
  setSelectedQuizNav,
} from "../../../store/commonActions";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { TbChevronsLeft, TbChevronsRight } from "react-icons/tb";
import ShowEditQuizNav from "./new quizManagement/ShowEditQuizNav";

const QuizzesNav = (props: any) => {
  const dispatch = useAppDispatch();
  const { selectedQuizNav, selectedAssignPath } = useAppSelector(
    (store) => store.quizzesNavigation
  );
  const { userRole } = useAppSelector(
    (store) => store.trainingNavigation
  );
  const [selectedNav, setSelectedNav] = useState<String>("management");
  const [isSelectedMode, setSelectedMode] = useState<String>("Multiple");

  const handleClick = (a: String) => {
    dispatch(setSelectedQuizNav(a));
  };

  const onSelectNav = (a: String) => {
    setSelectedNav(a);

    handleClick(a);
  };

  const selectedMode = (a: String) => {
    setSelectedMode(a);
    dispatch(setSelectedAssignNav(a));
  };

  useEffect(()=> {
    if(userRole.includes('Quiz Owner') && !userRole.includes('System administrator'))
    {
      onSelectNav("management");
      dispatch(setSelectedAssignNav(""));
    }
    dispatch(setSelectedQuizNav("management"));
    dispatch(setSelectedAssignNav(""));
  },[])

  return (
    <div
      className={`containerq ${props.isCollasped ? "collapsedq" : ""}`}
      style={{
        display: "inline-block",
        height: "98vh",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px 0px 0px 20px ",
      }}
    >
      {props.isCollasped ? 
        <>
          <div className="content">
            <p className="usermanagestyle">LEARNER HISTORY</p>

            <TbChevronsRight
              className="content"
              style={{ fontSize: "20px", width: "30px" }}
              onClick={() => {
                props.setIssCollasped(!props.isCollasped);
              }}
            />
          </div>
        </>
       : 
        <>
        
          <h4 className="title">
            Quizzes
          </h4>
          {(selectedAssignPath !== "EditQuestions" && selectedAssignPath !== "AddQuestion")?<div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                width: "100%",
                margin: "auto",
              }}
            >
              {/* {userRole.includes('Quiz Owner') && !userRole.includes('System administrator')?null: */}
              <>
              <div
                className={
                  selectedNav === "management"
                    ? "selectedOptionQuiz1"
                    : "notSelectedQuiz1"
                }
                onClick={() => {
                  onSelectNav("management");
                  dispatch(setSelectedAssignNav(""));
                }}
              >
                Manage
              </div>
              <div
                className={
                  selectedNav === "assignment"
                    ? "selectedOptionQuiz1"
                    : "notSelectedQuiz1"
                }
                onClick={() => {
                  onSelectNav("assignment");
                  dispatch(setSelectedAssignNav("multiple"));
                  setSelectedMode("Multiple");
                  dispatch(setEditQuizDetails({}));
                }}
              >
                Assign
              </div>
              <div
                className={
                  selectedNav === "history"
                    ? "selectedOptionQuiz1"
                    : "notSelectedQuiz1"
                }
                onClick={() => {
                  onSelectNav("history");
                  dispatch(setSelectedAssignNav("Learner"));
                  setSelectedMode("Learner");
                  dispatch(setEditQuizDetails({}));
                }}
              >
                Learner's History
              </div>
              </>
              {/* } */}
              
            </div>:null}
            
            {selectedQuizNav === "assignment" ? (
              <div style={{ height: "80%" }}>
                <Card
                  className={
                    isSelectedMode === "Multiple"
                      ? "selectedMode"
                      : "notSelectedMode"
                  }
                  onClick={() => {
                    selectedMode("Multiple");
                    dispatch(setSelectedAssignNav("multiple"));
                  }}
                >
                  Multiple
                </Card>

                <Card
                  className={
                    isSelectedMode === "Team"
                      ? "selectedMode"
                      : "notSelectedMode"
                  }
                  onClick={() => {
                    selectedMode("Team");
                    dispatch(setSelectedAssignNav("team"));
                  }}
                >
                  Team
                </Card>
              </div>
            ) : (
              ""
            )}
            {selectedQuizNav === "history" ? (
              <>
              <div style={{ height: "80%" }}>
               
                <Card
                  className={
                    isSelectedMode === "Learner"
                      ? "selectedMode"
                      : "notSelectedMode"
                  }
                  onClick={() => {
                    selectedMode("Learner");
                    dispatch(setSelectedAssignNav("Learner"));
                  }}
                >
                  Learner's Report
                </Card>
                <Card
                  className={
                    isSelectedMode === "Defaulter"
                      ? "selectedMode"
                      : "notSelectedMode"
                  }
                  onClick={() => {
                    selectedMode("Defaulter");
                    dispatch(setSelectedAssignNav("Defaulter"));
                  }}
                >
                  Defaulter's Report
                </Card>

                <div>
              <TbChevronsLeft
                  style={{
                    fontSize: "30px",
                    position: "relative",

                    top: "15%",
                    left:"90%"
                  }}
                  onClick={() => {
                    props.setIssCollasped(!props.isCollasped);
                  }}
                />
              </div>
                
              </div>
              
              </>
            ) : (
              ""
            )}
            {selectedQuizNav === "management" ? (
              <>
                <ShowEditQuizNav selectedMode={selectedMode} setSelectedNav={setSelectedNav}/>
              </>
            ) : (
              ""
            )}
          
        </>
      }
    </div>
  );
};

export default QuizzesNav;
