import Api from "./Api";

export const createNewAreaSubarea = async (data: any) => {
  try {
    var response = await Api.post(`/areasubarea/createNewAreaSubarea`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateAreaSubarea = async (data: any) => {
  try {
    var response = await Api.post(`/areasubarea/updateAreaSubarea`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllAreaSubarea = async (data: any) => {
    try { 
    const response = await Api.post(
      `/areasubarea/getAllAreaSubarea`, data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getSubarea = async (data: any) => {
    try { 
    const response = await Api.post(
      `/areasubarea/getSubarea`, data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}