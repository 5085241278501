
export const USER_CREATION_SUCCESS = "User Created Successfully !";
export const USER_CREATION_ERROR = "Error Creating User !"

export const USER_EDIT_SUCCESS = "User Edited Successfully !";
export const USER_EDIT_ERROR = "Error Updating User !";

export const QUIZ_EDIT_SUCCESS = "Quiz Updated Successfully !";
export const QUIZ_EDIT_ERROR = "Error Updating Quiz !";

export const QUIZ_CREATION_SUCCESS = "Quiz Created Successfully !";
export const QUIZ_CREATION_ERROR = "Error Creating Quiz !";

export const ADD_QUESTION_SUCCESS = "Question Added Successfully !";
export const ADD_QUESTION_ERROR = "Error Adding Question in the Quiz !";
export const UPDATE_QUESTION_SUCCESS = "Question Updated Successfully !";
export const UPDATE_QUESTION_ERROR = "Error Updating the Question!";
export const MIN_QUESTION_ERROR = "Questions present in the quiz should be equal or greater than";

export const OWN_MANAGER = "A user cannot be its own manager !";

export const SIMILAR_QUES_PRESENT = "Similar question already present in the quiz. Do you still want to continue?";

export const ALL_DETAILS_REQUIRED = "Please enter all the details !";

export const NOT_FOUND = "No records found !";

export const PAGE_RELOAD="Session has expired. Reloading..."

export const EXIT_TEST_WARNING = "You were not allowed to leave the test !";

export const VALID_EMAIL_ERROR = "Please enter valid email !";

export const SEARCH_ERROR = "Please enter seach value greater than 3 !";

export const DELETE_WARNING = "Are you sure you want to delete the entry ?";

export const DELETE_SUCCESS = "Entry deleted successfully !";

export const ATTEMPTS_EXHAUSTED = "Sorry! Your attempts have been exhausted. It will be renewed after";

export const ROLE_ERROR = "Please select atleast one field."

export const TEST_QUES = "Maximum No. of Questions to be shown in the test should be less than or equal to Total Active No. of Questions present in the Quiz.";

export const QUIZ_OWNER_ERROR = "Please select the Owner of the Quiz";

export const TEAM_ASSIGN_ERROR = "Please select all the fields before assigning";

export const TEAM_ASSIGN_NO_USER = "Sorry! No users present.";

export const ALREADY_EXISTS = "Record already exists !";

export const RECORD_CREATED = "Record created successfully";

export const RECORD_UPDATED = "Record updated successfully";

export const ASSIGN_ERROR = "Please select all the fields before adding.";

export const ALREADY_ASSIGN_ERROR = "Quiz already assigned for this user in the table.";

export const SERVER_ERROR = "Server error! Refresh or try after some time.";

export const ACTIVE_FLAG = 'A';

export const INACTIVE_FLAG = 'NA';

export const PASS_FLAG = 'PASS';

export const FAIL_FLAG = 'FAIL';

export const NOT_ATTEMPTED_FLAG = 'Not Attempted';

export const SESSION_EXPIRED = 'Session Expired';

export const API_SUCCEEDED = 'succeeded';

export const REFREH_PAGE = 'Please refresh the page!'

export const API_FAILED = 'failed';

export const API_LOADING = 'loading';

export const DATE_FORMAT = 'dd-MMM-yyyy';

export const DATE_FORMAT_2 = 'yyyy-MM-dd';

export const UNAUTHORISED = 'Unauthorized';

export const MODULE_SELECTED = 'moduleSelected';

export const QUIZ_INSTRUCTIONS = [
  "Each question in the quiz is of multiple-choice format.",
  "Try to answer all questions.",
  "You must NOT use reference materials during the testing session.",
  "There is no negative marking.",
  "You may go back and review your answers any time during the test session.",
  "You need to score at least 80% to pass the test.",
  "Select the Next button to Jump onto next question and Back button to see the previous question."
]

export const QUIZ_INSTRUCTIONS_CHECK = "Click here to indicate that you have read and agree to the terms presented in the Instructions"

export const MODULE_ACCESS: any = {
    'Learner': [ 'Trainings','Learners'],
    'Quiz Owner': ['Trainings','Learners','Quizzes'],   
    // 'HRP Coordinator': ['Trainings','Learners','HRP'],
    'Area Data Steward': ['Trainings','Learners','UserManage' ],
    'System administrator' : ['Trainings','Learners','Quizzes', 'UserManage'],
  };

export const ROLES= ["System administrator", "Learner", "Quiz Owner","Area Data Steward"
// ,"HRP Coordinator"
]

export const ROUTE_ACCESS: any = {
  trainings: ["System administrator", "Learner", "Quiz Owner","Area Data Steward"
// ,"HRP Coordinator"
],
  learners: ["System administrator", "Learner"],
  quizzes: ["Quiz Owner", "System administrator"],
  // hrp: ["System administrator", "HRP Coordinator"],
  usermanage: ["System administrator", "Area Data Steward"]
}

//UserfulInfo Links
export const MY_PASSWORD_LINK = "https://mypassword.lilly.com";
export const PROTECT_LINK = "https://now.lilly.com/page/global-protectlilly";
export const CSV_HUB_LINK = "https://csvhub.lilly.com/";
export const MY_ACCESS_LINK = "https://collab.lilly.com/sites/myAccessLillyServiceSite";
export const CYBER_ARK_LINK = "https://collab.lilly.com/sites/IdM/Web%20Part%20Pages/CyberArk.aspx";
export const SNOW_LINK = "https://lilly.service-now.com/ess/main.do";
export const IT_SYSTEM_CONTROL_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/283515";
// export const FAQ_LINK = "https://collab.lilly.com/sites/LCSCommonProcessFrontDoor/CSV%20Hub%20Content/Forms/AllItems.aspx?id=%2Fsites%2FLCSCommonProcessFrontDoor%2FCSV%20Hub%20Content%2FFAQ%20for%20LQP%2D302%2D29%2Epdf&parent=%2Fsites%2FLCSCommonProcessFrontDoor%2FCSV%20Hub%20Content";
export const FAQ_LINK = "https://collab.lilly.com/sites/LCSCommonProcessFrontDoor/CSV%20Hub%20Content/Forms/AllItems.aspx?id=%2Fsites%2FLCSCommonProcessFrontDoor%2FCSV%20Hub%20Content%2FFAQ%20%2D%2D%20Red%20CCI%20SOP%2Epdf&parent=%2Fsites%2FLCSCommonProcessFrontDoor%2FCSV%20Hub%20Content";

//ITCommon Links
export const CONFIG_MANAGE_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/480785/1/0";
// export const INCIDENT_MANAGE_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/403738/4/0";
export const INCIDENT_MANAGE_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/2570168/2/0";
export const PROBLEM_MANAGE_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/1776696/1/0";
export const CHANGE_MANAGE_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/481084/1/0";
// export const MAJOR_INCIDENT_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/427760/2/0";
export const MAJOR_INCIDENT_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/1410522/2/0?state_type=steady_state__v"
export const REQUEST_FULLFILLMENT_LINK = "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/444437/4/0";

