import { useEffect, useState } from "react";
import QuizInstructionModal from "./QuizInstructionModal";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import "./LearnersContent.css";
import { convertDate } from "../../../utilities/helper/common";
import {
  ATTEMPTS_EXHAUSTED,
  FAIL_FLAG,
  INACTIVE_FLAG,
  NOT_ATTEMPTED_FLAG,
  PASS_FLAG,
} from "../../../utilities/helper/constants";
import { setModule } from "../../../store/commonActions";

const LearnersContent = (props: any) => {
  const { quizDetails, selectedQuizPath, attemptHour } = useAppSelector(
    (store) => store.learnerNavigation
  );
  const dispatch = useAppDispatch();
  useEffect(()=> {
    dispatch(setModule("Learners"))
  },[])

  const [animate, setAnimate] = useState<Boolean>(false);

  useEffect(()=> {
    const timer = setTimeout(()=> {
      setAnimate(true);
    }, 1000);
    return () => {clearTimeout(timer); setAnimate(false);}
  },[quizDetails])

  const handleShow = () => {
    props.setShow(true);
  };

  return (
    <>
      {props.show ? (
        <QuizInstructionModal
          maxQuestion={quizDetails?.maxQuestion}
          quizmasterid={quizDetails?.quizmasterid}
          assignTableId={quizDetails?.quizAssignTableId}
        />
      ) : (
        <>
          {Object.keys(quizDetails).length !== 0 ? (
            <div className= {animate? "wrap animate shift":"wrap animate pop"}>
              <div
                className="overlay"
                style={{
                  backgroundColor:
                    selectedQuizPath === "all"
                      ? quizDetails?.state === PASS_FLAG
                        ? "#74C365"
                        : quizDetails?.state === FAIL_FLAG
                        ? "#CA3433"
                        : "#c48857"
                      : selectedQuizPath === "completed"
                      ? "#74C365"
                      : selectedQuizPath === "unattempted" &&
                        quizDetails?.state === FAIL_FLAG
                      ? "#CA3433"
                      : "#c48857",
                }}
              >
                <div className="overlay-content animate slide-left delay-2">
                  <h1 className="animate slide-left pop delay-4">
                    {quizDetails?.quizName}
                  </h1>

                  <p
                    className="animate slide-left pop delay-5"
                    style={{ color: "white", marginBottom: "2.5rem" }}
                  >
                    Status: <em>{quizDetails?.state}</em>
                  </p>
                </div>

                <div className="animate slide delay-5">
                  {selectedQuizPath === "completed" ? (
                    <img
                      src="./assets/Correct icon.svg"
                      className="image-content"
                      alt="Correct Icon"
                    />
                  ) : null}
                  {selectedQuizPath === "unattempted" ? (
                    <img
                      src="./assets/Incorrect icon.svg"
                      className="image-content"
                      alt="Incorrect Icon"
                    />
                  ) : null}
                  {selectedQuizPath === "all" ? (
                    quizDetails?.state === PASS_FLAG ? (
                      <img
                        src="./assets/Correct icon.svg"
                        className="image-content"
                        alt="Correct Icon"
                      />
                    ) : (
                      <img
                        src="./assets/Incorrect icon.svg"
                        className="image-content"
                        alt="Incorrect Icon"
                      />
                    )
                  ) : null}
                </div>
              </div>

              <div className="text">
                <>
                  <div className="inner-div1">
                    <div className="inner-div2">
                      <div className="inner-div3">
                        <div className="col-div" title={quizDetails?.activity}>
                          <div className="small-text-div">Quiz Name</div>
                          <div className="big-text-div">
                            {quizDetails?.activity}
                          </div>
                        </div>
                        <div className="col-div">
                          <div className="small-text-div">Status</div>
                          <div className="big-text-div">
                            {quizDetails?.state}
                          </div>
                        </div>
                        <div className="col-div">
                          <div className="small-text-div">Attempts</div>
                          <div className="big-text-div">
                            {quizDetails?.attempts}/ {quizDetails.maxAttempt}
                          </div>
                        </div>
                      </div>

                      <div className="inner-div3">
                        <div className="col-div">
                          <div className="small-text-div">Score</div>
                          <div className="big-text-div">
                            {quizDetails.marks !== null
                              ? `${quizDetails.marks}%`
                              : INACTIVE_FLAG}
                          </div>
                        </div>
                        <div className="col-div">
                          <div className="small-text-div">Last Attempted</div>
                          <div className="big-text-div">
                            {quizDetails?.lastAttempted
                              ? convertDate(quizDetails?.lastAttempted)
                              : INACTIVE_FLAG}
                          </div>
                        </div>
                        <div className="col-div">
                          <div className="small-text-div">Completion Date</div>
                          <div className="big-text-div">
                            {quizDetails?.completionDate
                              ? convertDate(quizDetails?.completionDate)
                              : INACTIVE_FLAG}
                          </div>
                        </div>
                      </div>

                      <div className="inner-div3">
                        <div className="col-div">
                          <div className="small-text-div">Assigner</div>
                          <div className="big-text-div">
                            {quizDetails?.assignedBy}
                          </div>
                        </div>
                        <div className="col-div">
                          <div className="small-text-div">Assigned Date</div>
                          <div className="big-text-div">
                            {convertDate(quizDetails?.assignedDate)}
                          </div>
                        </div>
                        <div className="col-div">
                          <div className="small-text-div">Due Date</div>
                          <div className="big-text-div">
                            {convertDate(quizDetails?.dueDate)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {quizDetails?.state === NOT_ATTEMPTED_FLAG ||
                    (quizDetails?.state === FAIL_FLAG &&
                      quizDetails?.attempts !== quizDetails?.maxAttempt) ? (
                      <a
                        // href="javascript:void(0);"
                        role="button"
                        onClick={handleShow}
                        className="link-button"
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Launch Quiz
                      </a>
                    ) : null}
                    {quizDetails?.state === PASS_FLAG ? (
                      <strong className="completed-msg">
                        You've already completed this Quiz.
                      </strong>
                    ) : null}
                    {quizDetails?.state === FAIL_FLAG &&
                    quizDetails?.attempts === quizDetails?.maxAttempt ? (
                      <strong className="noattempts">
                        {`${ATTEMPTS_EXHAUSTED} ${attemptHour}`} hours.
                      </strong>
                    ) : null}
                  </div>
                </>
              </div>
            </div>
            
          ) : (
            <div className="noquiz">No quiz details to display</div>
          )}
        </>
      )}
    </>
  );
};

export default LearnersContent;
