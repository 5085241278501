import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import "./QuizHome.css";
import { MdDelete } from "react-icons/md";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
  setQuizesDetails,
  setQuestionDetails,
  setSelectedAssignNav,
} from "../../../../../store/slices/quizzesSlice";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BiAddToQueue } from "react-icons/bi";
import { ACTIVE_FLAG } from "../../../../../utilities/helper/constants";
import { sessionGetter } from "../../../../../utilities/helper/common";

interface QuestionProps {
  id: number;
  ques: string;
  createdBy: string|null;
  quizStatus: string;
  ans: string;
  options: string;
  updatedBy: string|null;
  status: string;
}

const QuizHome = () => {
  const {
    quizDetails,
    editableQuestion,
    selectedAssignPath,
  } = useAppSelector((store) => store.quizzesNavigation);
  const dispatch = useAppDispatch();

  const initialState = {
    id: 1,
    createdBy: sessionGetter('uid'),
    updatedBy: sessionGetter('uid'),
    status: ACTIVE_FLAG,
    quizStatus: ACTIVE_FLAG,
    ques: "",
    ans: "",
    options: "",
  };

  const [questionObjectValues, setQuestionObjectValues] =
    useState<QuestionProps>(initialState);
  const [optionCount, setOptionCount] = useState<Array<String>>([]);
  const [questionNo, setQuestionNo] = useState(1);
  const [optionObject, setOptionObject] = useState({});
  const [question, setQuestion] = useState<Array<Object>>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [deleted, setDeleted] = useState(false);
  const [save, setSave] = useState(false);
  const [questionsobj, setQuestionsobj] = useState<any>([]);
  const [title, setTitle] = useState("");
  const firstUpdate = useRef(true);

  const handleOutsideClick = async (e: any) => {
    setOptions(Object.values(optionObject));
    await setQuestionObjectValues({
      ...questionObjectValues,
      options: JSON.stringify(Object.values(optionObject)),
    });
  };
  const handleOptions = async(e: any) => {
    await setOptionObject({ ...optionObject, [e.target.name]: e.target.value });

  };

  const handlePush = async (e: any) => {
    setQuestionObjectValues({
      ...questionObjectValues,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
  }, [editableQuestion]);

  useEffect(() => {
    let newValue = question.filter((item) => item !== editableQuestion);
    setQuestion(newValue);
  }, [questionObjectValues]);

  const addQuestion = async (event: any) => {
    await setQuestionNo(questionNo + 1);
    await setQuestion((oldArray) => [...oldArray, questionObjectValues]);
    await setOptionCount([]);
    await setQuestionObjectValues(initialState);
  };

  const onDeleteOption = (removeValue: number) => {
    let arr = optionCount;
    arr.splice(removeValue, 1);
    setOptionCount(arr);
    setOptions(
      options.filter((deleteOption) => deleteOption !== options[removeValue])
    );
    setDeleted(!deleted);
    if (optionCount.length === 0) {
      setOptions([]);
    }
    setSave(!save);
    setOptionObject((current: any) => {
      const copy = { ...current };
      delete copy[`option${removeValue}`];
      return copy;
    });
  };

  useEffect(() => {
    dispatch(setQuestionDetails(question));
  }, [question]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setQuestionsobj((current: any) => [...current, questionObjectValues]);
  }, [save]);

  useEffect(() => {
    let arr = sessionGetter("QuizDetails");
    if (!arr) {
      dispatch(setQuizesDetails(quizDetails));
    } else {
      dispatch(setQuizesDetails(JSON.parse(arr)));
    }
  }, [questionsobj]);

  useEffect(() => {
    if (sessionGetter("Edit Quiz")) {
      let arr = sessionGetter("Edit Quiz");
      if (!arr) {
        setTitle("");
      } else {
        setTitle(JSON.parse(arr).title);
      }
    } else {
      let arr = sessionGetter("QuizDetails");
      if (!arr) {
        setTitle("");
      } else {
        setTitle(JSON.parse(arr).title);
      }
    }
  }, []);

  useEffect(() => {
    setQuestionObjectValues({
      ...questionObjectValues,
      options: JSON.stringify(options),
    });
  }, [deleted]);

  return (
    <>
      <div>
        <div className="text-center" style={{ margin: "20px" }}>
          <div className="d-inline-block" style={{ float: "left" }}>
            <IoMdArrowRoundBack
              className="delete-button"
              onClick={() => dispatch(setSelectedAssignNav("createQuiz"))}
            />
          </div>
          {selectedAssignPath === "showQuiz" ? (
            sessionGetter("Edit Quiz") ? (
              <h3 className="text-center d-inline-block">{title}</h3>
            ) : (
              ""
            )
          ) : sessionGetter("QuizDetails") !== null ? (
            <h3 className="text-center d-inline-block">{title}</h3>
          ) : (
            ""
          )}
          
          <div className="d-inline-block" style={{ float: "right" }}>
            <Button style={{ background: "black", border: "none" }}>
              <BiAddToQueue className="delete-button" /> Submit
            </Button>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <InputGroup className="question-input">
            <InputGroup.Text>Question {questionNo}</InputGroup.Text>
            <Form.Control
              as="textarea"
              name="ques"
              onChange={(e) => {
                handlePush(e);
              }}
              value={questionObjectValues.ques}
              required
            />
          </InputGroup>
        </div>
        {optionCount.map((option, index) => (
          <div key={index} className="d-flex justify-content-center">
            <InputGroup className="option-input">
              <InputGroup.Checkbox className="checkbox" />
              <Form.Control
                onChange={async (e) => {
                  await handleOptions(e);
                }}
                name={`option${index}`}
                onBlur={handleOutsideClick}
                required
              />
            </InputGroup>
            <div className="d-flex justify-content-center align-items-center">
              <MdDelete
                className="delete-button"
                onClick={() => onDeleteOption(index)}
              />
            </div>
          </div>
        ))}
        <div className="text-center">
          <div className="d-inline-block button-container">
            <Button
              onClick={() => {
                setOptionCount((oldArray) => [...oldArray, ""]);
              }}
            >
              Add Option
            </Button>
          </div>

          <div className="d-inline-block button-container">
            <Button onClick={addQuestion}>Add Question</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizHome;
