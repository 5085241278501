import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import { Button, Card, Form } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import "./EditUser.css";
import { useState } from "react";
import {
  createUser,
  updateUser,
  getRoles,
  setShowEditType,
  getAreaSubarea1,
  getSubarea1,
  showUserDetails,
} from "../../../../store/commonActions";
import { convertDate, sessionGetter, uniqueArea } from "../../../../utilities/helper/common";
import "react-toastify/dist/ReactToastify.css";
import {
  ACTIVE_FLAG,
  API_FAILED,
  API_SUCCEEDED,
  INACTIVE_FLAG,
  OWN_MANAGER,
  ROLE_ERROR,
  USER_CREATION_ERROR,
  USER_CREATION_SUCCESS,
  USER_EDIT_ERROR,
  USER_EDIT_SUCCESS,
  VALID_EMAIL_ERROR,
} from "../../../../utilities/helper/constants";
import { toast } from "react-toastify";

interface AddUserProps {
  createdBy: string|null,
  tcsId: string;
  lillyId: string;
  globalId: string;
  name: string;
  tcsEmailId: string;
  lillyEmailId: string;
  area: string;
  subarea: string;
  masterSubareaId: number;
  onsiteOffshore: string;
  tcsSupervisor: string;
  comments: string;
  applicationRole: Array<string>;
  projectRole: string;
  keySupplierPersonnel: string;
  lillyManager: string;
  lillyManagerLillyId: string;
  status: string;
  lillyWorkerStartDate: string;
  lillyWorkerEndDate: string;
  tcsAccountManager: string;
}

const EditUser = () => {
  const {
    showUser,
    showEdit,
    apiStatusSubarea,
    apiStatusRoles,
    roleSlice,
    subareaSlice,
    areaSubareaApiStatus1,
    areaSubareaData1,
    subareaData1,
    subareaApiStatus,
    apiCreateUser,
    apiUpdateUser,
  } = useAppSelector((store: any) => store.userNavigation);
  const dispatch = useAppDispatch();
  const inititalState = {
    createdBy: sessionGetter('uid'),
    tcsId: "",
    lillyId: "",
    globalId: "",
    name: "",
    tcsEmailId: "",
    lillyEmailId: "",
    area: "",
    subarea: "",
    masterSubareaId: 0,
    onsiteOffshore: "Onsite",
    tcsSupervisor: "",
    comments: "",
    applicationRole: ["Learner"],
    projectRole: "",
    keySupplierPersonnel: "Yes",
    lillyManager: "",
    lillyManagerLillyId: "",
    lillyWorkerStartDate: "",
    lillyWorkerEndDate: "",
    status: ACTIVE_FLAG,
    tcsAccountManager: ""
  };
  const [allValues, setAllValues] = useState<AddUserProps>(inititalState);
  const [projectRoleError, setProjectRoleError] = useState("");
  const [ApplicationRoleError, setApplicationRoleError] = useState("");
  const [validated, setValidated] = useState(false);
  const [areaList, setAreaList] = useState<Array<Object>>([]);
  const [subareaList, setSubareaList] = useState<any>([]);
  const [projectRoleList, setProjectRoleList] = useState<any>([]);
  const [applicationRoleList, setApplicationRoleList] = useState<any>([]);
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedSubarea, setSelectedSubarea] = useState<string>("");

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getAreaSubarea1({ status: ACTIVE_FLAG }));
  }, []);

  useEffect(() => {
    if (areaSubareaApiStatus1 === API_SUCCEEDED) {
      setAreaList(uniqueArea(areaSubareaData1));
    }
  }, [areaSubareaApiStatus1]);

  useEffect(() => {
    if (apiUpdateUser === API_SUCCEEDED) {
      toast.success(`${showUser.lillyId} ${USER_EDIT_SUCCESS}`);
      dispatch(setShowEditType(""));
      setAllValues(inititalState);
      dispatch(setShowEditType("addUser"));
    } else if (apiUpdateUser === API_FAILED) {
      toast.error(USER_EDIT_ERROR);
    }
  }, [apiUpdateUser]);
  useEffect(() => {
    const handleRender = async () => {
      if (apiCreateUser === API_SUCCEEDED) {
        toast.success(USER_CREATION_SUCCESS);
        dispatch(setShowEditType(""));
        await setAllValues(inititalState);
        await dispatch(setShowEditType("addUser"));
      } else if (apiCreateUser === API_FAILED) toast.error(USER_CREATION_ERROR);
    };
    handleRender();
  }, [apiCreateUser]);

  useEffect(() => {
    if (apiStatusSubarea === API_SUCCEEDED) {
      const { data } = subareaSlice;
      setSubareaList(data);
    }
  }, [apiStatusSubarea]);

  useEffect(() => {
    if (showEdit === "addUser") {
      setAllValues(inititalState);
      dispatch(showUserDetails({}));
    }
  }, [showEdit]);

  useEffect(() => {
    dispatch(getSubarea1({ area: selectedArea }));
    setSelectedSubarea("");
    setAllValues({ ...allValues, subarea: "" });
  }, [selectedArea]);

  useEffect(() => {
    if (subareaApiStatus === API_SUCCEEDED) {
      setSubareaList(subareaData1);
    }
  }, [subareaApiStatus]);

  useEffect(() => {
    if (selectedSubarea !== "") {
      const Obj = subareaList.filter((item: any) => {
        return item.subarea === selectedSubarea;
      });
      setAllValues({
        ...allValues,
        masterSubareaId: Obj[0]?.id,
        subarea: selectedSubarea,
      });
    }
    else{
      setAllValues({
        ...allValues,
        subarea: selectedSubarea,
      });
    }

  }, [selectedSubarea]);


  useEffect(() => {
    if (apiStatusRoles === API_SUCCEEDED) {
      const { data } = roleSlice;
      setProjectRoleList(
        data.filter((item: any) => {
          return item.roleType === "Project";
        })
      );
      setApplicationRoleList(
        data.filter((item: any) => {
          return item.roleType === "Application";
        })
      );
    }
  }, [apiStatusRoles]);

  useEffect(() => {
    if (showEdit === "editUser" || showEdit === "showUser") {
      setSelectedSubarea(showUser.subarea);
      setAllValues({
        ...showUser,
        projectRole: showUser.projectRole
          ? showUser.projectRole !== '[""]'
            ? JSON.parse(showUser.projectRole)
            : ""
          : "",
        applicationRole: showUser.applicationRole
          ? JSON.parse(showUser.applicationRole)
          : "",
        lillyWorkerStartDate: convertDate(showUser.lillyWorkerStartDate),
        lillyWorkerEndDate: convertDate(showUser.lillyWorkerEndDate),
      });
      if (JSON.stringify(showUser) !== "{}")
        dispatch(getSubarea1({ area: showUser.area }));
      setProjectRoleError("");
      setApplicationRoleError("");
    }
  }, [showUser]);

  const handleChange = async (e: any) => {
    const {
      target: { value },
      target: { name },
    } = e;
    var regName = /^[A-Za-z ]+$/;

    if (regName.test(value) || value === "") {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.trimStart(),
      });
    }
    if (name === "area") {
      setSelectedArea(e.target.value);
      setAllValues({ ...allValues, area: selectedArea });
    }

    if (
      name !== "name" &&
      name !== "tcsAccountManager" &&
      name !== "tcsSupervisor" &&
      name !== "lillyManager" &&
      name !== "comments"
    ) {
      if (
        name === "tcsId" ||
        name === "lillyId" ||
        name === "globalId" ||
        name === "lillyManagerLillyId"
      ) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.trim().slice(0, 8),
        });
      } else {
        setAllValues({ ...allValues, [e.target.name]: e.target.value.trim() });
      }
    }
    if (name === "comments") {
      setAllValues({ ...allValues, [e.target.name]: e.target.value });
    }
  };
  const handleSubarea = async (e: any) => {
    setSelectedSubarea(e.target.value);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(true);

    let obj = {
      ...allValues,
      projectRole: JSON.stringify(allValues.projectRole),
      applicationRole: JSON.stringify(allValues.applicationRole),
    };

    if (form.checkValidity()) {
      if (allValues.applicationRole.length && allValues.projectRole.length) {
        if (
          /@tcs\.com$/i.test(allValues.tcsEmailId) &&
          /@network\.lilly\.com$/i.test(allValues.lillyEmailId)
        ) {
          if (allValues.lillyId === allValues.lillyManagerLillyId) {
            toast.error(OWN_MANAGER);
          } else {
            if (showEdit === "addUser") {
              if (allValues.subarea === "") {
                const masterAreaId = subareaList.filter((item: any) => {
                  return item.subarea === null;
                });
                setAllValues({
                  ...allValues,
                  masterSubareaId: masterAreaId[0]?.id,
                });
                const { subarea, ...rest } = obj;
                await dispatch(createUser({...rest,subarea:null, masterSubareaId: masterAreaId[0]?.id}));
                
                
              }
              else
              await dispatch(createUser(obj));
            }
            if (showEdit === "editUser" || showEdit === "showUser") {
              if (allValues.subarea === "") {
                const masterAreaId = subareaList.filter((item: any) => {
                  return item.subarea === null;
                });
                setAllValues({
                  ...allValues,
                  masterSubareaId: masterAreaId[0]?.id,
                });
                const { subarea, ...rest } = obj;
                await dispatch(updateUser({...rest,subarea:null, masterSubareaId: masterAreaId[0]?.id}));
                await dispatch(setShowEditType(""));
                setAllValues(inititalState);
                await dispatch(setShowEditType("addUser"));
                return;
              }
              await dispatch(updateUser(obj));
              await dispatch(setShowEditType(""));
              setAllValues(inititalState);
              await dispatch(setShowEditType("addUser"));
            }
          }
        } else {
          toast.error(VALID_EMAIL_ERROR);
        }
      }
    }
  };

  const onSelectProjectRole = (selectedList: any) => {
    setAllValues({ ...allValues, projectRole: selectedList });
    setProjectRoleError("");
  };
  const onRemoveProjectRole = (selectedList: any) => {
    setAllValues({ ...allValues, projectRole: selectedList });
    if (selectedList.length === 0) {
      setProjectRoleError(ROLE_ERROR);
    }
  };
  const onSelectApplicationRole = (selectedList: any, selectedItem: any) => {
    if (selectedItem === "Learner") return;
    setAllValues({ ...allValues, applicationRole: selectedList });
    setApplicationRoleError("");
  };
  const onRemoveApplicationRole = (selectedList: any, removedItem: any) => {
    if (removedItem === "Learner") {
      selectedList.unshift(removedItem);
      return;
    }
    else if(!(selectedList.includes("Learner"))){
      selectedList.unshift("Learner");
      return;
    }
    setAllValues({ ...allValues, applicationRole: selectedList });
    if (selectedList.length === 0) {
      setApplicationRoleError(ROLE_ERROR);
    }
  };

  return (
    <>
      <Card className="userform-outercard">
        <Card.Header className="userform-header">
          {showEdit === "addUser" ? "Add User" : null}
          {showEdit === "editUser" ? `Modify Details - ${showUser.name}` : null}
          {showEdit === "showUser" ? `View Details - ${showUser.name}` : null}
        </Card.Header>
        <Form validated={validated} onSubmit={handleSubmit}>
          <div className="userform-container">
            <div className="row-style">
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>TCS ID</Form.Label>

                  <Form.Control
                    type="number"
                    min="0"
                    placeholder="TCS ID"
                    className="userform-fieldstyle2"
                    name="tcsId"
                    value={allValues.tcsId}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Lilly ID</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Lilly ID"
                    className="userform-fieldstyle2"
                    name="lillyId"
                    value={allValues.lillyId}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Global ID</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    placeholder="Global ID"
                    className="userform-fieldstyle2"
                    name="globalId"
                    value={allValues.globalId}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row-style">
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Employee Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Employee Name"
                    className="userform-fieldstyle2"
                    name="name"
                    value={allValues.name}
                    disabled={showEdit === "showUser"}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Form.Group>
              </div>
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>TCS Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="TCS Email ID"
                    className="userform-fieldstyle2"
                    name="tcsEmailId"
                    value={allValues.tcsEmailId}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Lilly Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Lilly Email ID"
                    className="userform-fieldstyle2"
                    name="lillyEmailId"
                    value={allValues.lillyEmailId}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row-style">
              <div className="userform-fieldstyle1">
                <Form.Label>User Active/Inactive</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="userform-fieldstyle2"
                  name="status"
                  value={allValues.status}
                  disabled={showEdit === "showUser"}
                  onChange={handleChange}
                  required
                >
                  <option value={ACTIVE_FLAG}>Active</option>
                  <option value={INACTIVE_FLAG}>Inactive</option>
                </Form.Select>
              </div>
              <div className="userform-fieldstyle1">
                <Form.Label>Key Supplier Personnel</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="userform-fieldstyle2"
                  name="keySupplierPersonnel"
                  value={allValues.keySupplierPersonnel}
                  disabled={showEdit === "showUser"}
                  onChange={handleChange}
                  required
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </div>

              <div className="userform-fieldstyle1">
                <Form.Label>Onsite/Offshore</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="onsiteOffshore"
                  value={allValues.onsiteOffshore}
                  disabled={showEdit === "showUser"}
                  onChange={handleChange}
                  required
                >
                  <option value="Onsite">Onsite</option>
                  <option value="Offshore">Offshore</option>
                </Form.Select>
              </div>
            </div>
            <div className="row-style">
              <div className="userform-fieldstyle1">
                <Form.Label>Project Role</Form.Label>

                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  options={
                    showEdit === "editUser" || showEdit === "addUser"
                      ? projectRoleList.map((item: any) => item.role)
                      : allValues.projectRole.length !== 0
                      ? allValues.projectRole
                      : []
                  }
                  selectedValues={allValues.projectRole}
                  disablePreSelectedValues={showEdit === "showUser"}
                  showCheckbox
                  onSelect={onSelectProjectRole}
                  onRemove={onRemoveProjectRole}
                />
                {projectRoleError ? (
                  <span className="role-errormsg">{projectRoleError}</span>
                ) : null}
              </div>
              <div className="userform-fieldstyle1">
                <Form.Label>Application Role</Form.Label>
                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck(){}}
                  onSearch={function noRefCheck() {}}
                  options={
                    showEdit === "showUser"
                      ? allValues.applicationRole.length !== 0
                        ? allValues.applicationRole
                        : []
                      : applicationRoleList.map((item: any) => item.role)
                  }
                  selectedValues={
                    showEdit === "editUser" || showEdit === "showUser"
                      ? allValues.applicationRole
                      : ["Learner"]
                  }
                  disablePreSelectedValues={showEdit === "showUser" || showEdit ===  "addUser" &&
                  allValues.applicationRole[0] === "Learner" }
                  showCheckbox
                  onSelect={onSelectApplicationRole}
                  onRemove={onRemoveApplicationRole}
                />
                {ApplicationRoleError ? (
                  <span className="role-errormsg">{ApplicationRoleError}</span>
                ) : null}
              </div>
            </div>

            <div className="row-style">
              <div className="userform-fieldstyle1">
                <Form.Label>Sub-Tower</Form.Label>

                <Form.Select
                  aria-label="Default select example"
                  name="area"
                  value={allValues.area}
                  disabled={showEdit === "showUser"}
                  onChange={handleChange}
                  required
                >
                  <option value={""}>Please Select Area</option>
                  {areaList.map((item: any, key: any) => (
                    <option value={item} key={key}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="userform-fieldstyle1">
                <Form.Label>Functional Area</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="subarea"
                  value={allValues.subarea===null?"":allValues.subarea}
                  className="userform-fieldstyle2"
                  disabled={showEdit === "showUser"}
                  onChange={handleSubarea}
                >
                  <option value={""}>Please Select Sub Area</option>
                  {subareaList.map((item: any, key: any) => {
                    if (item.subarea)
                      return (
                        <option value={item.subarea} key={key}>
                          {item.subarea}
                        </option>
                      );
                      return;
                  })}
                </Form.Select>
              </div>
            </div>
            <div className="row-style">
            <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>TCS AM</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="TCS Account Manager"
                    className="userform-fieldstyle2"
                    name="tcsAccountManager"
                    value={allValues.tcsAccountManager}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>TCS GM</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="TCS Group Manager"
                    className="userform-fieldstyle2"
                    name="tcsSupervisor"
                    value={allValues.tcsSupervisor}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Lilly Worker Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    className="userform-fieldstyle2"
                    name="lillyWorkerStartDate"
                    onKeyDown={(e) => e.preventDefault()}
                    min="1970-01-01"
                    max="2050-12-31"
                    value={
                      allValues.lillyWorkerStartDate
                        ? convertDate(allValues.lillyWorkerStartDate)
                        : ""
                    }
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>

              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Lilly Worker End Date</Form.Label>
                  <Form.Control
                    type="date"
                    onKeyDown={(e) => e.preventDefault()}
                    max="2050-12-31"
                    placeholder="End Date"
                    className="userform-fieldstyle2"
                    name="lillyWorkerEndDate"
                    value={
                      allValues.lillyWorkerEndDate
                        ? convertDate(allValues.lillyWorkerEndDate)
                        : ""
                    }
                    disabled={
                      showEdit === "showUser" ||
                      (showEdit === "addUser" &&
                        allValues.lillyWorkerStartDate === "") ||
                      (showEdit === "editUser" &&
                        allValues.lillyWorkerStartDate === "")
                    }
                    onChange={handleChange}
                    min={allValues.lillyWorkerStartDate}
                    required
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row-style">
              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Lilly Sponsor Name</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Lilly Sponsor Name"
                    className="userform-fieldstyle2"
                    name="lillyManager"
                    value={allValues.lillyManager}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>

              <div className="userform-fieldstyle1">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Lilly Sponsor System Id</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Lilly Sponsor System Id"
                    className="userform-fieldstyle2"
                    name="lillyManagerLillyId"
                    value={allValues.lillyManagerLillyId}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
            </div>
            <div className="userform-fieldstyle3">
              <div className="userform-fieldstyle2">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="comments"
                    value={allValues.comments === null ? "" : allValues.comments}
                    disabled={showEdit === "showUser"}
                    onChange={handleChange}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <Card.Footer className="userform-footer text-muted">
            {showEdit === "editUser" || showEdit === "addUser" ? (
              <div className="formButton-div">
                <Button
                  type="submit"
                  className="formButton"
                  onClick={() => {
                    setValidated(true);
                    if (
                      allValues.applicationRole.length === 0 ||
                      allValues.applicationRole === null
                    ) {
                      setApplicationRoleError(ROLE_ERROR);
                    }
                    if (
                      allValues.projectRole.length === 0 ||
                      allValues.projectRole === null
                    ) {
                      setProjectRoleError(ROLE_ERROR);
                    }
                  }}
                >
                  {showEdit === "editUser" ? "Update User" : "Add User"}
                </Button>
                <Button
                  className="formButton"
                  onClick={async () => {
                    if (showEdit === "editUser") {
                      setAllValues({
                        ...showUser,
                        projectRole: showUser.projectRole
                          ? showUser.projectRole !== '[""]'
                            ? JSON.parse(showUser.projectRole)
                            : ""
                          : "",
                        applicationRole: showUser.applicationRole
                          ? JSON.parse(showUser.applicationRole)
                          : "",
                        lillyWorkerStartDate: convertDate(
                          showUser.lillyWorkerStartDate
                        ),
                        lillyWorkerEndDate: convertDate(
                          showUser.lillyWorkerEndDate
                        ),
                      });
                      dispatch(getSubarea1({ area: showUser.area }));
                      setProjectRoleError("");
                      setApplicationRoleError("");
                    } else if (showEdit === "addUser") {
                      setAllValues(inititalState);
                      await dispatch(setShowEditType(""));
                      await dispatch(setShowEditType("addUser"));
                    }
                  }}
                >
                  Reset
                </Button>
              </div>
            ) : null}
          </Card.Footer>
        </Form>
      </Card>
    </>
  );
};

export default EditUser;
