import { useCallback } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { getSearchUserData } from "../../store/commonActions";
import { toast } from "react-toastify";
import _ from "lodash";
import { SEARCH_ERROR } from "../helper/constants";
const SelectInput = (props: any) => {
  const { searchUserData } = useAppSelector((store) => store.userNavigation);
  const dispatch = useAppDispatch();
  const { allValues, setAllValues, title, usersData, setUsersData,status } = props;
  let statusObj={}
  if(typeof status !== 'undefined')
  statusObj={status}
  const debounceEffect = (searchValue: string) => {
    if (searchValue.length <= 3) {
      toast.error(SEARCH_ERROR);
      return;
    }
    dispatch(
      getSearchUserData({
        searchText: searchValue,
        page: 1,
        pageSize: 100,
        ...statusObj
      })
    );
  };
  const debounceFn = useCallback(_.debounce(debounceEffect, 500), []);

  const handleInputChange = (searchValue: any) => {
    if (searchValue === "") {
      dispatch(getSearchUserData({ searchText: "", page: 1, pageSize: 1000,...statusObj }));
      return;
    }
    else if(searchValue.length>8)
    {
      const trimmedValue = searchValue.trim().slice(0, 20);
      return trimmedValue;
    }
    debounceFn(searchValue);
    setUsersData(searchUserData);
  };

  const changeHandler = (e: any) => {
    if (props.setSelectedAreaList) {
      props.setSelectedAreaList([]);
    }
    setAllValues({
      ...allValues,
      name: e?.name,
      lillyId: e?.value,
      areaId: "",
      subarea: "",
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      if(Object.keys(allValues).includes('startDate'))
      {
        setAllValues({
          name: "",
          lillyId: "",
          areaId: "",
          subarea: "",
          title:[],
          startDate: new Date("1970-01-01").toISOString(),
          endDate: new Date(Date.now()).toISOString(),
        });
        return;
      }
      setAllValues({
        name: "",
        lillyId: "",
        areaId: "",
        subarea: "",
        title:[],
      });
    }
  };
  return (
    <>
      <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
        <Form.Label>{title}</Form.Label>
        <Select
          styles={{
            menuList: (provided, state) => ({
              ...provided,
              maxHeight: "220px",
            }),
          }}
          isSearchable
          value={{
            value: allValues.lillyId,
            label:
              allValues.name.length !== 0 && allValues.lillyId.length !== 0
                ? `${allValues.name} (${allValues.lillyId})`
                : "",
          }}
          onInputChange={handleInputChange}
          options={usersData.map((data: any, key: any) => ({
            value: data.lillyId,
            name: data.name,
            label: `${data.name} (${data.lillyId})`,
            area: data.area,
            subarea: data.subarea,
          }))}
          onChange={changeHandler}
          onKeyDown={handleKeyDown}
        />
      </Form.Group>
    </>
  );
};

export default SelectInput;
