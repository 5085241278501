import axios from "axios";
import { apiUrl } from "./Constants";
import { toast } from "react-toastify";
import { ALREADY_EXISTS, PAGE_RELOAD, REFREH_PAGE, SERVER_ERROR, SESSION_EXPIRED, UNAUTHORISED } from "../utilities/helper/constants";
import { sessionGetter } from "../utilities/helper/common";

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = sessionGetter("token");
    const auth = token ? `Bearer ${token}` : "";
    config.headers.common["Authorization"] = auth;
    return config;
  },
  (error) => Promise.reject(error)
);
axiosInstance.interceptors.response.use((response) => response, (error) => {
  if(error.response && error.response.status===401 && error.response.data.message===SESSION_EXPIRED){
    toast.error(PAGE_RELOAD)
    window.location.href='/';
  }
  else if(error.response && error.response.status===401 && error.response.data.message===UNAUTHORISED){
    toast.error(REFREH_PAGE)
  }
  else if(error.response && error.response.status===409){
    toast.error(ALREADY_EXISTS)
  }
  else if(error.response && error.response.status===500){
    toast.error(SERVER_ERROR)
  }
  return Promise.reject(error.message);
});

export default axiosInstance;
