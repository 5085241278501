import { useState } from "react";
import "./HrpNav.css";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import HrpNav from "./Hrp/HrpNav";
import {
  setShowHrpContent
} from "../../../store/commonActions";
import ChecklistNav from "./Checklist/ChecklistNav";

const Nav = () => {
  const [selectedHrp, setSelectedHrp] = useState<String>("Hrp");
  const dispatch = useAppDispatch();
 

  
  return (
    <div
      style={{
        display: "inline-block",
        width: "30%",
        height: "100%",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px 0px 0px 20px ",
      }}
    >
      <h4 className="title">
        HRP
      </h4>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          width: "100%",
          margin: "auto",
        }}
      >
        <div
          className={selectedHrp === "Hrp" ? "selectedHrp" : "notSelectedHrp"}
          onClick={() =>{setSelectedHrp("Hrp");
          dispatch(setShowHrpContent("Hrp"));
        }}
        >
          HRP
        </div>
        <div
          className={
            selectedHrp === "Checklist" ? "selectedHrp" : "notSelectedHrp"
          }
          onClick={() => {
            setSelectedHrp("Checklist");
            dispatch(setShowHrpContent("Checklist"));

          }}
        >
          User's Checklist
        </div>
      </div>
      {selectedHrp==="Hrp"?<HrpNav/>:null}

        {selectedHrp === "Checklist"
          ? <ChecklistNav/>
          : null}
      </div>
    
  );
};

export default Nav;
