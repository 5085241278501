import { useEffect, useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import {
  createAreaSubarea,
  updateCurrentAreaSubarea,
} from "../../../store/slices/areaSubareaSlice";
import { ACTIVE_FLAG, INACTIVE_FLAG } from "../../../utilities/helper/constants";

const AreaSubareaModal = (props: any) => {
  const {
    show,
    setShow,
    edit,
    editBool,
    setEditBool,
    setEdit,
    setStatus,
  } = props;
  const dispatch = useAppDispatch();

  const [addNew, setAddNew] = useState<any>({area:"", subarea:""});
  const [status1, setStatus1] = useState<any>(ACTIVE_FLAG);
  const [statusChanged, setStatusChanged] = useState<any>(false);
  const handleClose = () => {
    setShow(false);
    setEditBool(false);
    setEdit({});
  };

  useEffect(() => {
    setAddNew(edit);
  }, [edit]);

  const handleSubmit = async (e: any) => {
    if (editBool) {
      if (statusChanged) {
        const obj = { id: addNew.id, deleteFlag: status1 };
        await dispatch(updateCurrentAreaSubarea(obj));
      } else {
        await dispatch(updateCurrentAreaSubarea(addNew));
        await setEdit({});
      }
    } else {
      // if(!addNew.subarea)
      // {
      //   // await setAddNew({...addNew, subarea: null});
      //   dispatch(createAreaSubarea({area: addNew.area, subarea: null}));
      //   return;
      // }
      dispatch(createAreaSubarea(addNew));
    }
    setStatus(ACTIVE_FLAG);
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>{editBool ?<strong> UPDATE AREA</strong> : <strong>ADD AREA</strong>}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Area</Form.Label>
              <Form.Control
                type="input"
                placeholder="Area"
                value={editBool ? addNew.area : null}
                onChange={(e) => {
                  setAddNew({ ...addNew, area: e.target.value });
                }}
                required={true}
              />
              <Form.Label>Subarea</Form.Label>
              <Form.Control
                type="input"
                placeholder="Subarea"
                value={editBool ? addNew.subarea : null}
                onChange={(e) => {
                  setAddNew({ ...addNew, subarea: e.target.value });
                }}
                // required={true}
              />
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="status"
                value={editBool ? addNew.deleteFlag : null}
                onChange={async (e) => {
                  await setAddNew({ ...addNew, deleteFlag: e.target.value });
                  await setStatus1(e.target.value);
                  await setStatusChanged(true);
                }}
                required={true}
              >
                <option value={ACTIVE_FLAG}>Active</option>
                <option value={INACTIVE_FLAG}>Inactive</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>

        <ModalFooter>
          <Button
            variant="primary"
            disabled={
              (addNew.area === "" 
              // || addNew.subarea === "" 
              || JSON.stringify(addNew) === "{}" 
              // || Object.keys(addNew).length === 1 
              )
                ? true
                : false
            }
            type="submit"
            onClick={handleSubmit}
            style={{ margin: "auto" }}
          >
            {editBool ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AreaSubareaModal;
