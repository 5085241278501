import './Loader.css'

const Loader = () => {
  return (
    <div className='skeleton-wrapper'>
        <div className='skeleton-element'></div>
        <div className='skeleton-element'></div>
        <div className='skeleton-element'></div>
        <div className='skeleton-element'></div>
        <div className='skeleton-element'></div>
    </div>
  )
}

export default Loader
