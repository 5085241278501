import { configureStore } from '@reduxjs/toolkit';
import navSlice from './slices/navSlice';
import learnerSlice from './slices/learnerSlice';
import quizzesSlice from './slices/quizzesSlice';
import userSlice from './slices/userSlice';
import hrpSlice from './slices/hrpSlice';
import trainingSlice from './slices/trainingSlice';
import areaSubareaSlice from './slices/areaSubareaSlice';

const store = configureStore(
    {
        reducer: { 
            moduleNavigation : navSlice.reducer,
            learnerNavigation : learnerSlice.reducer,
            quizzesNavigation : quizzesSlice.reducer,
            userNavigation : userSlice.reducer,
            hrpNavigation: hrpSlice.reducer,
            trainingNavigation: trainingSlice.reducer,
            areaSubareaNavigation: areaSubareaSlice.reducer,

        }
    }
);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;