import { useEffect, useState } from "react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../../hooks/redux-hooks";
import "./CreatedQuestionNav.css";
import { MdDelete } from "react-icons/md";
import {
  setDeleteQuestion,
  setDeleteQuestionIndex,
  setEditableQuestion,
  setQuestionIndex,
} from "../../../../../store/slices/quizzesSlice";
import {
  sessionGetter,
  sessionSetter,
} from "../../../../../utilities/helper/common";

const CreatedQuestionNav = () => {
  const dispatch = useAppDispatch();
  const [questionList, setQuestionList] = useState<Array<Object>>([]);
  const { questionNav, deleteQuestion, questionIndex } = useAppSelector(
    (store) => store.quizzesNavigation
  );

  useEffect(() => {
    let arr = sessionGetter("Create Quiz");
    if (!arr) {
      setQuestionList([]);
    } else {
      let newArr = JSON.parse(arr).questions;
      setQuestionList(newArr);
    }
  }, []);

  useEffect(() => {
    let arr = sessionGetter("Create Quiz");
    if (!arr) {
      setQuestionList([]);
    } else {
      let newArr = JSON.parse(arr).questions;
      setQuestionList(newArr);
    }
  }, [questionNav]);

  const editQuestion = async (index: number) => {
    var editable = await questionList[index];
    await dispatch(setEditableQuestion(editable));
    await dispatch(setQuestionIndex(index));
  };

  return (
    <div className="container" style={{ overflowY: "auto", height: "80%" }}>
      {questionList?.map((question: any, index: any) => (
        <div
          className="d-flex justify-content-center align-items-center container question-card"
          key={index}
        >
          <div className="d-flex justify-content-center align-items-center">
            Q{index + 1}.&nbsp;
          </div>
          <div
            className="card container question-container d-flex justify-content-center"
            onClick={() => editQuestion(index)}
            style={{
              background:
                index === questionIndex
                  ? "linear-gradient(#b6eabe, #1f7ea1)"
                  : "#c1bfbf45",
              color: index === questionIndex ? "#fff" : "#000",
              // backgroundColor: selected === quiz ? "#A25035" : "#fff",

              cursor: "pointer",
            }}
            title={question.ques}
          >
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              title={question.ques}
            >
              {question.ques}
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <MdDelete
              className="delete-button1"
              onClick={async () => {
                const newArr = questionList;
                await newArr.splice(index, 1);
                await setQuestionList(newArr);
                await sessionSetter(
                  "New Question List",
                  JSON.stringify(questionList)
                );
                await dispatch(setDeleteQuestion(!deleteQuestion));
                await dispatch(setDeleteQuestionIndex(index));
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CreatedQuestionNav;
