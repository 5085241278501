import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addQuestion, assigndelete, checkQuizAssignment, createIndividualAssignment, createNewQuiz,  getAllQuizzesByOwnerApi, getQuizOwner, getallactivequizz, getqueslist, quizAreaSubareaDefaulters, teamAssignment, updateQuizDetails, updateques, userStatusTableAreaSubarea, userstatustablearea } from "../../service/quizzesApi";
import { QuizzesState } from "../../models/redux-models";
import { ACTIVE_FLAG, API_FAILED, API_LOADING, API_SUCCEEDED } from "../../utilities/helper/constants";
import { sessionGetter } from "../../utilities/helper/common";



const initialState: QuizzesState = {
  selectedAssignPath: "",
  selectedQuizNav: "management",
  editQuizDetails: {},
  enableEdit: false,
  apiStatus: "",
  updateApiStatus: "",
  quizOwner: [],
  allQuizzesByOwner: [],
  quizList: [],
  createdQuestion: [],
  quizDetails: [],
  questionDetails: [],
  editableQuestion: {
    ques: "",
    ans: "",
    questionTy: "",
    options: "",
  },
  apiCreateQuizStatus: "",
  apiQuesStatus: "",
  apiUpdateQues: "",
  questionNav: false,
  quizStatus: ACTIVE_FLAG,
  questionIndex: -1,
  deleteQuestion: false,
  deleteQuestionIndex: -1,
  editQuestion: false,
  editQuestionIndex: -1,
  apiAddQues: "",
  apiQuizOwnerStatus: "",
  activeQuizzesList: [],
  apiStatusActiveQuizzesList: "",
  userStatusAreaList: [],
  userStatusSubAreaList: [],
  quizAreaSubareaDefaulterList:[],
  quizOwnerApiStatus: "",
  apiQuizAreaSubareaDefaulters:"",
  quizOwnerArray:[],
  userStatusTableAreaApi:"",
  checkQuizAssignDependencyData:[],
  checkAlreadyQuizAssignApiData:[],
  checkQuizAssignData:[],
  checkQuizAssignApiStatus:"",
  checkQuizAssignMessage:"",
  questionFlag: "A",
  createQuizNavFlag: "",
  createQuizApiStatus:"",
  teamAssignUsers: {},
  teamAssignApiStatus: ""
}

export const createQuiz = createAsyncThunk(
    "/createQuiz",
    async (data: any, thunkAPI) => {
      const response = await createNewQuiz(data);
      return response;
    }
  );
  export const getAllQuizzesByOwner = createAsyncThunk(
    "/getAllQuizzesByOwner",
    async (data: any, thunkAPI) => {
      const response = await getAllQuizzesByOwnerApi(data);
      return response;
    }
  );
  export const updateQuiz = createAsyncThunk(
    "/updateQuizDetails",
    async (data: any, thunkAPI) => {
      const response = await updateQuizDetails(data);
      return response;
    }
  );
 
  export const getAllQuizOwner = createAsyncThunk("/getAllQuizOwner", async (thunkAPI) => {
    const response = await getQuizOwner();
    return response;
  });
  export const  getallqueslist = createAsyncThunk(
    "/getallqueslist",
    async (details: any, thunkAPI) => {
      const response = await getqueslist(details);
      return response;
    }
  );
  export const  updateQuestion = createAsyncThunk(
    "/updateQuestion",
    async (data: any, thunkAPI) => {
      const response = await updateques(data);
      return response;
    }
  );

  export const  addQues = createAsyncThunk(
    "/addQues",
    async (data: any, thunkAPI) => {
      const response = await addQuestion(data);
      return response;
    }
  );
  export const getallactivequizzes = createAsyncThunk("/getallactivequizzes", async (data: any,thunkAPI) => {
    const response = await getallactivequizz(data);
    return response;
  });

  export const  createAssignment = createAsyncThunk(
    "/createAssignment",
    async (data: any, thunkAPI) => {
      const response = await createIndividualAssignment(data);
      return response;
    }
  );
  export const  teamAssign = createAsyncThunk(
    "/teamAssign",
    async (data: any, thunkAPI) => {
      const response = await teamAssignment(data);
      return response;
    }
  );
  export const  userStatusTableArea = createAsyncThunk(
    "/userStatusTableArea",
    async (data: any, thunkAPI) => {
      const loggedUser={loggedUserId:sessionGetter('uid')}
      const response = await userstatustablearea({...data,...loggedUser});
      return response;
    }
  );
  export const  userStatusTableSubarea = createAsyncThunk(
    "/userStatusTableSubarea",
    async (data: any, thunkAPI) => {
      const response = await userStatusTableAreaSubarea(data);
      return response;
    }
  );
  export const  assigndeleteId = createAsyncThunk(
    "/assigndeleteId",
    async (data: any, thunkAPI) => {
      const response = await assigndelete(data);
      return response;
    }
  );
  export const  quizAreaSubareaDefaulter = createAsyncThunk(
    "/quizAreaSubareaDefaulter",
    async (data: any, thunkAPI) => {
      const loggedUser={loggedUserId:sessionGetter('uid')}
      const response = await quizAreaSubareaDefaulters({...data,...loggedUser});
      return response;
    }
  );
  export const  checkQuizAssign = createAsyncThunk(
    "/checkQuizAssignment",
    async (data: any, thunkAPI) => {
      const response = await checkQuizAssignment(data);
      return response;
    }
  );


const quizzesSlice = createSlice({
    name: "quizzesNavigation",
    initialState,
    reducers: {
      setSelectedAssignNav: (state, action) => {
        state.selectedAssignPath = action.payload;
        state.checkQuizAssignApiStatus="";
        state.apiAddQues="";
        state.createQuizApiStatus="";
        state.apiUpdateQues="";
        state.updateApiStatus="";
        state.apiStatus="";
      },
      setQuizStatus: (state, action) => {
        state.quizStatus = action.payload
      },
      setCreatedQuestion: (state, action) => {
        state.createdQuestion = action.payload;
      },
      setQuestionDetails: (state, action) => {
        state.questionDetails = action.payload;
      },
      setQuizesDetails: (state, action) => {
        state.quizDetails = action.payload;
      },
      setQuestionNav: (state, action) => {
        state.questionNav = action.payload;
      },
      setQuestionIndex: (state, action) => {
        state.questionIndex = action.payload;
      },
      setDeleteQuestion: (state, action) => {
        state.deleteQuestion = action.payload;
      },
      setDeleteQuestionIndex: (state, action) => {
        state.deleteQuestionIndex = action.payload;
      },
      setEditQuestion: (state, action) => {
        state.deleteQuestion = action.payload;
      },
      setEditQuestionIndex: (state, action) => {
        state.editQuestionIndex = action.payload;
      },
      setSelectedQuizNav: (state, action) => {
        state.selectedQuizNav = action.payload;
      },
      setEditableQuestion: (state, action) => {
        state.editableQuestion = action.payload;
      },
      setEditQuizDetails: (state, action) => {
        state.editQuizDetails = action.payload;
      },
      setEnableEdit: (state, action) => {
        state.editQuizDetails = action.payload;
      },
      setQuestionFlag: (state, action) => {
        state.questionFlag = action.payload;
      },
      setCreateQuizNavFlag: (state, action) => {
        state.createQuizNavFlag = action.payload;
      },
      resetquizzes: () => initialState,
    },
    extraReducers(builder) {
        builder.addCase(createQuiz.pending, (state) => {
            state.createQuizApiStatus = API_LOADING;
          });
          builder.addCase(createQuiz.fulfilled, (state, { payload }) => {
            state.createQuizApiStatus = API_SUCCEEDED;
          });
          builder.addCase(createQuiz.rejected, (state, action) => {
            state.createQuizApiStatus = API_FAILED;
          });

          
          builder.addCase(getAllQuizOwner.pending, (state) => {
            state.quizOwner = [];
            state.quizOwnerApiStatus = API_LOADING;
          });
          builder.addCase(getAllQuizOwner.fulfilled, (state, { payload }) => {
            state.quizOwner = payload;
            state.quizOwnerApiStatus = API_SUCCEEDED;
          });
          builder.addCase(getAllQuizOwner.rejected, (state, action) => {
            state.quizOwner = [];
            state.quizOwnerApiStatus = API_SUCCEEDED;
          });

          builder.addCase(getAllQuizzesByOwner.pending, (state) => {
            state.apiStatus = API_LOADING;
          });
          builder.addCase(getAllQuizzesByOwner.fulfilled, (state, { payload }) => {
            state.allQuizzesByOwner = payload.data;
            state.apiStatus = API_SUCCEEDED;
          });
          builder.addCase(getAllQuizzesByOwner.rejected, (state, action) => {
            state.allQuizzesByOwner = [];
            state.apiStatus = API_FAILED;
          });

          builder.addCase(updateQuiz.pending, (state) => {
            state.updateApiStatus = API_LOADING;
          });
          builder.addCase(updateQuiz.fulfilled, (state, { payload }) => {
            state.updateApiStatus = API_SUCCEEDED;
          });
          builder.addCase(updateQuiz.rejected, (state, action) => {
            state.updateApiStatus = API_FAILED;
          });

          builder.addCase(getallqueslist.pending, (state) => {
            state.quizList = [];
            state.quizOwnerArray = [];
            state.apiQuesStatus = API_LOADING;
          });
          builder.addCase(getallqueslist.fulfilled, (state, { payload }) => {
            state.quizList = payload.data.result.questionDetails;
            state.quizOwnerArray = payload.data.quizOwnerData;
            state.apiQuesStatus = API_SUCCEEDED;
          });
          builder.addCase(getallqueslist.rejected, (state, action) => {
            state.quizList = [];
            state.quizOwnerArray = [];
            state.apiQuesStatus = API_FAILED;
          });

          builder.addCase(updateQuestion.pending, (state) => {
            state.apiUpdateQues = API_LOADING;
          });
          builder.addCase(updateQuestion.fulfilled, (state, { payload }) => {
            state.apiUpdateQues = API_SUCCEEDED;
          });
          builder.addCase(updateQuestion.rejected, (state, action) => {
            state.apiUpdateQues = API_FAILED;
          });

          builder.addCase(addQues.pending, (state) => {
            state.apiAddQues = API_LOADING;
          });
          builder.addCase(addQues.fulfilled, (state, { payload }) => {
            state.apiAddQues = API_SUCCEEDED;
          });
          builder.addCase(addQues.rejected, (state, action) => {
            state.apiAddQues = API_FAILED;
          });

          builder.addCase(createAssignment.pending, (state) => {
           
            state.apiStatus = API_LOADING;
          });
          builder.addCase(createAssignment.fulfilled, (state, { payload }) => {
           
            state.apiStatus = API_SUCCEEDED;
          });
          builder.addCase(createAssignment.rejected, (state, action) => {
           
            state.apiStatus = API_FAILED;
          });
          builder.addCase(teamAssign.pending, (state) => {
           
            state.teamAssignApiStatus = API_LOADING;
            state.teamAssignUsers = {};
          });
          builder.addCase(teamAssign.fulfilled, (state, { payload }) => {
           
            state.teamAssignApiStatus = API_SUCCEEDED;
            state.teamAssignUsers = payload.quiz;
          });
          builder.addCase(teamAssign.rejected, (state, action) => {
           
            state.teamAssignApiStatus = API_FAILED;
            state.teamAssignUsers = {};
          });
          
          

          builder.addCase(getallactivequizzes.pending, (state) => {
            state.activeQuizzesList=[]
            state.apiStatusActiveQuizzesList = API_LOADING;
          });
          builder.addCase(getallactivequizzes.fulfilled, (state, { payload }) => {
            state.activeQuizzesList=payload.activeQuizzess.data;
            state.apiStatusActiveQuizzesList = API_SUCCEEDED;
          });
          builder.addCase(getallactivequizzes.rejected, (state, action) => {
            state.activeQuizzesList=[]
            state.apiStatusActiveQuizzesList = API_FAILED;
          });
          builder.addCase(userStatusTableArea.pending, (state) => {
            state.userStatusAreaList=[]
            state.userStatusTableAreaApi = API_LOADING;
          });
          builder.addCase(userStatusTableArea.fulfilled, (state, { payload }) => {
           
            state.userStatusAreaList=payload.data;
            state.userStatusTableAreaApi = API_SUCCEEDED;
          });
          builder.addCase(userStatusTableArea.rejected, (state, action) => {
            state.userStatusAreaList=[]
            state.userStatusTableAreaApi = API_FAILED;
          });

          builder.addCase(userStatusTableSubarea.pending, (state) => {
            state.userStatusSubAreaList=[]
            state.apiStatus = API_LOADING;
          });
          builder.addCase(userStatusTableSubarea.fulfilled, (state, { payload }) => {
            state.userStatusSubAreaList=payload.data;
            state.apiStatus = API_SUCCEEDED;
          });
          builder.addCase(userStatusTableSubarea.rejected, (state, action) => {
            state.userStatusSubAreaList=[]
            state.apiStatus = API_FAILED;
          });
          
          builder.addCase(assigndeleteId.pending, (state) => {
            
            state.apiStatus = API_LOADING;
          });
          builder.addCase(assigndeleteId.fulfilled, (state, { payload }) => {
           
            state.apiStatus = API_SUCCEEDED;
          });
          builder.addCase(assigndeleteId.rejected, (state, action) => {
            
            state.apiStatus = API_FAILED;
          });

          builder.addCase(quizAreaSubareaDefaulter.pending, (state) => {
            state.quizAreaSubareaDefaulterList=[]
            state.apiQuizAreaSubareaDefaulters = API_LOADING;
          });
          builder.addCase(quizAreaSubareaDefaulter.fulfilled, (state, { payload }) => {
            state.quizAreaSubareaDefaulterList=payload.data;
            state.apiQuizAreaSubareaDefaulters = API_SUCCEEDED;
          });
          builder.addCase(quizAreaSubareaDefaulter.rejected, (state) => {
            state.quizAreaSubareaDefaulterList=[]
            state.apiQuizAreaSubareaDefaulters = API_FAILED;
          });
          
          builder.addCase(checkQuizAssign.pending, (state) => {
            state.checkQuizAssignDependencyData=[];
            state.checkAlreadyQuizAssignApiData=[];
            state.checkQuizAssignData=[]
            state.checkQuizAssignMessage="";
            state.checkQuizAssignApiStatus = API_LOADING;
          });
          builder.addCase(checkQuizAssign.fulfilled, (state, { payload }) => {
            state.checkQuizAssignDependencyData=payload.quizData.arrQuizAssignedDependency;
            state.checkAlreadyQuizAssignApiData=payload.quizData.arrNotAssignedQuizzes;
            state.checkQuizAssignData=payload.quizData.arrAssigned;
            state.checkQuizAssignMessage=payload.quizData.commonMessage;
            state.checkQuizAssignApiStatus = API_SUCCEEDED;
          });
          builder.addCase(checkQuizAssign.rejected, (state) => {
            state.checkQuizAssignDependencyData=[];
            state.checkAlreadyQuizAssignApiData=[];
            state.checkQuizAssignMessage="";
            state.checkQuizAssignApiStatus = API_FAILED;
          });

    }
})

export default quizzesSlice;
export {quizzesSlice};
export const {setEditableQuestion,
  setQuestionDetails,
  setQuizesDetails,
  setQuestionNav,
  setQuestionIndex,
  setDeleteQuestion,
  setDeleteQuestionIndex,
  setEditQuestion,
  setQuizStatus,
  setEditQuestionIndex,setSelectedAssignNav, setSelectedQuizNav,setEditQuizDetails,setEnableEdit,resetquizzes, setCreatedQuestion,setQuestionFlag,setCreateQuizNavFlag} = quizzesSlice.actions;