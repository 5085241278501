
import Api from './Api';

  export const createNewQuiz=async(data :any) =>{ 
    try {  
    var response = await Api.post(`/quiz/createQuiz`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}


  export const getQuizOwner = async () => {
    try { 
    const response = await Api.get(
      `/quiz/getAllQuizOwner`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

  export const getqueslist = async (details: any) => {
    try { 
    const response = await Api.get(
      `/quiz/getallqueslist/${details.id}/${details.status}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
  export const updateques = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/updateques`, data);    
      return  response.data;  
    } catch (error) {
      throw error;
    }
  };

  export const getAllQuizzesByOwnerApi = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/getAllQuizzesByOwner`, data);
      return  response.data;
    } catch (error) {
      throw error;
    }
  };
  export const updateQuizDetails = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/updateQuizDetails`, data);
      return  response.data;   
    } catch (error) {
      throw error;
    }
  };
  export const addQuestion = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/addques`, data);
      return  response.data;   
    } catch (error) {
      throw error;
    }
  };

  export const getallactivequizz = async (lillyId: string) => {
    try {
    const response = await Api.get(
      `/quiz/getallactivequizzes/${lillyId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
  export const createIndividualAssignment = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/createIndividualAssignment`, data);
      return  response.data;   
    } catch (error) {
      throw error;
    }
  };

  export const userstatustablearea = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/userstatustablearea`, data);
      return  response.data;   
    } catch (error) {
      throw error;
    }
  };
  export const userStatusTableAreaSubarea = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/userStatusTableAreaSubarea`, data);
      return  response.data;   
    } catch (error) {
      throw error;
    }
  };
  export const assigndelete = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/assigndelete`, data);
      return  response.data;   
    } catch (error) {
      throw error;
    }
  };
  export const quizAreaSubareaDefaulters = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/quizAreaSubareaDefaulters`, data);
      return  response.data;   
    } catch (error) {
      throw error;
    }
  };

  export const checkQuizAssignment = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/checkQuizAssignment`, data);

      return  response.data;   
    } catch (error) {
      throw error;
    }
  };

  export const teamAssignment = async (data: any) => {
    try {
      const response: any = await Api.post(`/quiz/teamAssignment`, data);

      return  response.data;   
    } catch (error) {
      throw error;
    }
  };
  
  
 

 