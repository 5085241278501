import { GiNinjaHead, GiOpenBook } from "react-icons/gi";
import { BsPersonFillGear, BsPersonLinesFill } from "react-icons/bs";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  getAllQuizzesByOwner,
  setModule,
  setTraining,
  userquizList,
} from "../../store/commonActions";
import { resetLearner } from "../../store/commonActions";
import { resetquizzes } from "../../store/commonActions";
import { resetuser } from "../../store/commonActions";
import { resetHrp } from "../../store/commonActions";
import QuizLogo from "./QuizLogo";
import { useNavigate } from "react-router-dom";
import "./ModuleNavigation.css";
import IselectLogo from "./IselectLogo";
import {
  checkModuleAccess,
  sessionGetter,
  sessionSetter,
} from "../../utilities/helper/common";
import { ACTIVE_FLAG, MODULE_SELECTED } from "../../utilities/helper/constants";

const HomePage = () => {
  const { userRole } = useAppSelector((store) => store.trainingNavigation);
  const { navPath } = useAppSelector((store) => store.moduleNavigation);
  const { quizStatus } = useAppSelector((store) => store.quizzesNavigation);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let fullName = sessionGetter("userName");
  const names = fullName?.split(" ");
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (sessionGetter(MODULE_SELECTED) !== null) {
      fullName = sessionGetter("userName");
    }
  }, []);

  const handleClick = (module: String) => {
    dispatch(setModule(module));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "97%",
          textAlign: "center",
          marginTop: "2%",
          width: "8%",
          overflow: "wrap",
        }}
      >
        <div>
          <IselectLogo />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {checkModuleAccess(JSON.parse(userRole)).map(
            (item: string, key: number) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "#Bdab98",
                  fontSize: "12px",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "13%",
                }}
              >
                {item === "Trainings" ? (
                  <div
                    style={{
                      position: "absolute",
                      left: "33px",
                    }}
                    className={
                      navPath === "Trainings"
                        ? "moduleIconSelected"
                        : "moduleIconHover"
                    }
                    onClick={() => {
                      navigate("/trainings");
                      handleClick("Trainings");
                      dispatch(setTraining("TCSPharma"));
                      sessionSetter(MODULE_SELECTED, "Trainings");
                    }}
                  >
                    <GiOpenBook
                      style={{
                        margin: "auto",
                        padding: "8%",
                        fontSize: "30px",
                      }}
                    />
                    <br />
                    Trainings
                  </div>
                ) : null}
                {item === "Learners" ? (
                  <div
                    style={{
                      position: "absolute",
                      left: "33px",
                    }}
                    className={
                      navPath === "Learners"
                        ? "moduleIconSelected"
                        : "moduleIconHover"
                    }
                    onClick={() => {
                      navigate("/learners");
                      handleClick("Learners");
                      dispatch(resetLearner());
                      dispatch(userquizList(sessionGetter("uid")));
                      sessionSetter(MODULE_SELECTED, "Learners");
                    }}
                  >
                    <GiNinjaHead
                      style={{
                        margin: "auto",
                        padding: "8%",
                        fontSize: "30px",
                      }}
                    />
                    <br />
                    Learners
                  </div>
                ) : null}
                {item === "Quizzes" ? (
                  <div
                    style={{
                      position: "absolute",
                      left: "34px",
                    }}
                    className={
                      navPath === "Quizzes"
                        ? "moduleIconSelected"
                        : "moduleIconHover"
                    }
                    onClick={async() => {
                      navigate("/quiz");
                      handleClick("Quizzes");
                      await dispatch(resetquizzes());
                      dispatch(getAllQuizzesByOwner({status: quizStatus,
                        lillyId: sessionGetter("uid"),}));
                      sessionSetter(MODULE_SELECTED, "Quizzes");
                    }}
                  >
                    <br />
                    <QuizLogo
                      color={navPath === "Quizzes" ? "white" : "#Bdab98"}
                    />
                    Quizzes
                  </div>
                ) : null}
                {item === "HRP" ? (
                  <div
                    style={{
                      position: "absolute",
                      left: "40px",
                    }}
                    className={
                      navPath === "HRP"
                        ? "moduleIconSelected"
                        : "moduleIconHover"
                    }
                    onClick={() => {
                      navigate("/hrp");
                      handleClick("HRP");
                      dispatch(resetHrp());
                      sessionSetter(MODULE_SELECTED, "HRP");
                    }}
                  >
                    <BsPersonLinesFill
                      style={{
                        margin: "auto",
                        padding: "8%",
                        fontSize: "30px",
                      }}
                    />
                    <br />
                    HRP
                  </div>
                ) : null}
                {item === "UserManage" ? (
                  <div
                    style={{
                      position: "absolute",
                      left: "33px",
                      paddingTop:"10px"
                    }}
                    className={
                      navPath === "UserManage"
                        ? "moduleIconSelected"
                        : "moduleIconHover"
                    }
                    onClick={() => {
                      navigate("/usermanage");
                      handleClick("UserManage");
                      dispatch(resetuser());
                      sessionSetter(MODULE_SELECTED, "UserManage");
                    }}
                  >
                    <BsPersonFillGear
                      style={{
                        margin: "auto",
                        padding: "8%",
                        fontSize: "30px",
                      }}
                    />
                    <br />
                    User <br />
                    Manage
                  </div>
                ) : null}
              </div>
            )
          )}

          <div
            style={{
              display: "inline-block",
              fontSize: "10px",
              color: "white",
              position: "absolute",
              bottom: "15px",
              left: "12px",
            }}
          >
            <div
              title={fullName ? fullName : ""}
              style={{
                display: "block",
                // position: "absolute",
                margin: "auto",
                marginTop: "5%",
                cursor: "pointer",
                padding: "auto",
                fontSize: "18px",
                fontWeight: "bold",
                background: "white",
                width: "30px",
                borderRadius: "50%",
                color: "#200000",
              }}
            >
              {names ? names[0].charAt(0) : ""}
              {names ? names[names.length - 3].charAt(0) : ""}
            </div>
            <br />
            Copyright © {currentYear}
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
