import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { format } from "date-fns";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import {
  getAreaSubarea1,
  getSearchUser,
  getSubarea1,
  getallactivequizzes,
  quizAreaSubareaDefaulter,
} from "../../../../store/commonActions";
import { CSVLink } from "react-csv";
import { convertDate, sessionGetter, uniqueArea } from "../../../../utilities/helper/common";
import Loader from "../../../../utilities/Loader/Loader";
import DataTable from "react-data-table-component";
import "./DefaultersReport.css";
import SelectInput from "../../../../utilities/SelectInput/SelectInput";
import {
  ACTIVE_FLAG,
  API_SUCCEEDED,
  DATE_FORMAT,
} from "../../../../utilities/helper/constants";
interface DefaulterProps {
  title: Array<Object>;
  name: string;
  lillyId: string;
  masterSubareaId: string;
  area: string;
  subarea: string;
  areaId: string;
  areaList: any;
}

const DefaultersReport = (props: any) => {
  const dispatch = useAppDispatch();
  const [usersData, setUsersData] = useState<any[]>([]);
  const [selectedAreaList, setSelectedAreaList] = useState<any>([]);
  const [areaList, setAreaList] = useState<any>([]);
  const [subareaList, setSubareaList] = useState<any>([]);
  const [userQuizDetails, setUserQuizDetails] = useState<any>({});
  const [searchResult, setSearchResult] = useState<any>([]);
  const [selectedQuizList, setSelectedQuizList] = useState<any>([]);
  const [activeQuizzes, setActiveQuizzes] = useState<any[]>([]);
  const [selectedArea, setSelectedArea] = useState<string>("");
  // const [containNull, setContainNull] = useState<boolean>(false);
  // const [searchInput, setSearchInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allValues, setAllValues] = useState<DefaulterProps>({
    title: [],
    areaList: [],
    name: "",
    lillyId: "",
    masterSubareaId: "",
    area: "",
    areaId: "",
    subarea: "",
  });

  const {
    apiStatus,
    apiStatusSubarea,
    subareaSlice,
    areaSubareaApiStatus1,
    areaSubareaData1,
    subareaApiStatus,
    subareaData1,
    searchApiStatus,
    searchUserInfo,
    searchUserData,
  } = useAppSelector((store) => store.userNavigation);
  const {
    userStatusAreaList,
    userStatusSubAreaList,
    activeQuizzesList,
    apiStatusActiveQuizzesList,
    quizAreaSubareaDefaulterList,
    apiQuizAreaSubareaDefaulters,
  } = useAppSelector((store) => store.quizzesNavigation);
  useEffect(() => {
    if (apiStatusActiveQuizzesList === API_SUCCEEDED) {
      setActiveQuizzes(activeQuizzesList);
    }
  }, [apiStatusActiveQuizzesList]);
  useEffect(() => {
    setUserQuizDetails(userStatusAreaList);
  }, [userStatusAreaList]);
  useEffect(() => {
    setUserQuizDetails(userStatusSubAreaList);
  }, [userStatusSubAreaList]);

  // useEffect(() => {
  //   dispatch(
  //     getSearchUserData({
  //       searchText: searchInput,
  //       page: 1,
  //       pageSize: 1000,
  //       status: ACTIVE_FLAG,
  //     })
  //   );
  // }, [searchInput]);

  useEffect(() => {
    if (apiQuizAreaSubareaDefaulters === API_SUCCEEDED) {
      setIsLoading(false);
      setUserQuizDetails(quizAreaSubareaDefaulterList);
    } else {
      setIsLoading(true);
    }
  }, [apiQuizAreaSubareaDefaulters]);

  useEffect(() => {
    if (searchApiStatus === API_SUCCEEDED) {
      setUsersData(searchUserData);
    }
  }, [searchApiStatus]);

  useEffect(() => {
    dispatch(getAreaSubarea1({ status: ACTIVE_FLAG }));
    dispatch(getSearchUser({ searchText: "", page: 1, pageSize: 1000 }));
    dispatch(getallactivequizzes(sessionGetter('uid')));
    dispatch(quizAreaSubareaDefaulter({ quizmasterid: [] }));
  }, []);
  useEffect(() => {
    if (apiStatus === API_SUCCEEDED) {
      setUsersData(searchUserInfo);
    }
  }, [apiStatus]);

  useEffect(() => {
    if (areaSubareaApiStatus1 === API_SUCCEEDED) {
      setAreaList(uniqueArea(areaSubareaData1));
    }
  }, [areaSubareaApiStatus1]);

  useEffect(() => {
    if (apiStatusSubarea === API_SUCCEEDED) {
      const { data } = subareaSlice;
      setSubareaList(data);
    }
  }, [apiStatusSubarea]);

  useEffect(() => {
    dispatch(getSubarea1({ area: selectedArea }));
  }, [selectedArea]);

  useEffect(() => {
    if (subareaApiStatus === "succeeded") setSubareaList(subareaData1);
  }, [subareaApiStatus]);

  // useEffect(() => {
  //   if (subareaData1.filter((item: any) => item.subarea === null).length !== 0)
  //     setContainNull(true);
  //   else setContainNull(false);
  // }, [subareaList]);

  const columnsHeader = [
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
      wrap: true,
      style: {
        textAlign: 'left' as 'left'
      }
    },
    {
      name: "Assignee ID",
      selector: (row: any) => row.lillyId,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: "Quiz Name",
      selector: (row: any) => row.activity,
      cell: (row: any) => (
        <div style={{
          fontSize: "14px",
          margin: "4px",
          width: "150px",               
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
        title={row.activity}>{row.activity}</div>
      ),
      sortable: true,
      wrap: true,
      grow: 2,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Area",
      selector: (row: any) => row.area,
      sortable: true,
      wrap: true,
      grow: 2,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Subarea",
      selector: (row: any) => row.subarea,
      sortable: true,
      wrap: true,
      grow: 2,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "State",
      selector: (row: any) => row.state,
      sortable: true,
      wrap: true,
      grow: 1.5,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Assigned By",
      selector: (row: any) => row.assignedBy,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: "Due Date",
      selector: (row: any) => row.dueDate,
      cell: (row: any) => convertDate(row.dueDate),
      sortable: true,
      wrap: true,
    },
    {
      name: "Assigned Date",
      selector: (row: any) => row.assignedDate,
      cell: (row: any) => convertDate(row.assignedDate),
      sortable: true,
      wrap: true,
      grow: 2,
    },
  ];

  const changeHandler1 = async (e: any) => {
    setAllValues({
      ...allValues,
      [e.target.name]: e.target.value,
      areaId: allValues.areaId,
    });
  };

  const changeHandler2 = (e: any) => {
    setSelectedAreaList(e);
    if (e.length === 1) {
      setSelectedArea(e[0].value);
    } else {
      setSubareaList([]);
      setSelectedArea("");
    }

    let arr: any = [];
    e.map((data: any, key: any) => {
      return arr.push(data.value);
    });

    setAllValues({ ...allValues, subarea: JSON.stringify(arr) });

    if (arr.length === 1 && allValues.subarea !== "") {
      setAllValues({
        ...allValues,
        areaId: arr[0],
        lillyId: "",
        name: "",
      });
    } else {
      setAllValues({
        ...allValues,
        areaId: arr,
        lillyId: "",
        name: "",
        subarea: "",
      });
    }
  };
  const changeHandlerMuliti = (e: any) => {
    setSelectedQuizList(e);
    let arr: any = [];
    e.map((data: any, key: any) => {
      return arr.push(data.value);
    });
    setAllValues({ ...allValues, title: arr });
  };
  const search = () => {
    props.setIssCollasped(true);
    let obj;
    if (allValues.lillyId !== "") {
      obj = {
        lillyId: allValues.lillyId,

        quizmasterid: allValues.title,
      };
      dispatch(quizAreaSubareaDefaulter(obj));
    } else if (
      allValues.subarea === "" &&
      allValues.lillyId === "" &&
      allValues.areaId !== ""
    ) {
      let obj = {
        area: allValues.areaId,
        quizmasterid: allValues.title,
      };
      dispatch(quizAreaSubareaDefaulter(obj));
      setIsLoading(true);
    } else if (allValues.subarea !== "" && allValues.areaId !== "") {
      let obj = {
        subarea: allValues.subarea,
        quizmasterid: allValues.title,
      };
      dispatch(quizAreaSubareaDefaulter(obj));
      setIsLoading(true);
    } else if (
      allValues.subarea === "" &&
      allValues.lillyId === "" &&
      allValues.areaId === "" &&
      allValues.title.length !== 0
    ) {
      dispatch(quizAreaSubareaDefaulter({ quizmasterid: allValues.title }));
    }
  };

  const reset = () => {
    setAllValues({
      ...allValues,
      areaId: "",
      lillyId: "",
      name: "",
      subarea: "",
      title: [],
    });
    setSelectedAreaList([]);
    setSelectedQuizList([]);
    setSearchResult([]);
    dispatch(quizAreaSubareaDefaulter({ quizmasterid: [] }));
  };

  useEffect(() => {
    if (Object.keys(userQuizDetails).length !== 0) {
      set();
    } else {
      setSearchResult([]);
    }
  }, [userQuizDetails]);

  const set = () => {
    let mainobject: any = [];
    userQuizDetails.map((item: any, index: any) => {
      let obj = {
        id: item.id,
        name: item.userManageDetails?.name,
        lillyId: item.userManageDetails?.lillyId,
        activity: item.quizmaster?.title,
        state:
          item?.remarks === "Reassigned"
            ? "Reassigned as Not Completed"
            : "Not Completed",
        area: item?.area,
        subarea: item?.subarea,
        dueDate: item.dueDate
          ? format(new Date(item?.dueDate), DATE_FORMAT)
          : "",
        assignedDate: item.createdAt
          ? format(new Date(item?.createdAt), DATE_FORMAT)
          : "",
        assignedBy: item?.createdBy,
      };
      return mainobject.push(obj);
    });

    setSearchResult(mainobject);
  };
  const headers = [
    { label: "Name", key: "name" },
    { label: "Lilly ID", key: "lillyId" },
    { label: "Area", key: "area" },
    { label: "Subarea", key: "subarea" },
    { label: "Quiz Name", key: "activity" },
    { label: "Assigned By", key: "assignedBy" },
    { label: "Assigned Date", key: "assignedDate" },
    { label: "Due Date", key: "dueDate" },
    { label: "State", key: "state" },
  ];

  return (
    <>
      <div style={{ height: "93vh" }}>
        <h4 className="title2">Defaulter's Report</h4>
        <div
          style={{
            display: "inline-block",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <Button
            variant="secondary"
            style={{
              backgroundColor: "#582630",
              border: "none",
              marginLeft: "10px",
              height: "34px",
              lineHeight: 1,
            }}
            disabled={
              allValues.subarea === "" &&
              allValues.areaId.length === 0 &&
              allValues.lillyId === "" &&
              allValues.title.length === 0 &&
              allValues.title.length === 0
            }
            onClick={search}
          >
            Search
          </Button>
          <Button
            variant="secondary"
            onClick={reset}
            style={{
              backgroundColor: "#582630",
              border: "none",
              marginLeft: "10px",
              height: "34px",
              lineHeight: 1,
            }}
            disabled={
              allValues.subarea === "" &&
              allValues.areaId.length === 0 &&
              allValues.lillyId === "" &&
              searchResult.length === 0 &&
              allValues.title.length === 0
            }
          >
            Reset
          </Button>

          <CSVLink
            data={searchResult}
            headers={headers}
            filename={"Defaulter's-Report.csv"}
          >
            <Button
              variant="secondary"
              disabled={searchResult.length === 0 ? true : false}
              style={{
                backgroundColor: "#582630",
                border: "none",
                marginLeft: "10px",
                height: "34px",
                lineHeight: 1,
              }}
            >
              Export
            </Button>
          </CSVLink>
        </div>

        <Form style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              paddingBottom: "0",
              marginBottom: "0",
            }}
          >
            <div
              style={{
                display: "inline-block",
                margin: "0 1%",
                width: "250px",
                zIndex: 100,
              }}
            >
              <SelectInput
                allValues={allValues}
                title={"Assignee"}
                usersData={usersData}
                setAllValues={setAllValues}
                setSelectedAreaList={setSelectedAreaList}
                setUsersData={setUsersData}
                status={ACTIVE_FLAG}
              />
            </div>

            <strong style={{ display: "inline-block", marginTop: "15px" }}>
              OR
            </strong>

            <div
              style={{
                display: "inline-block",
                margin: "0 1%",
                width: "18%",
              }}
            >
              <Form.Group
                className="mb-1 quiz"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Area</Form.Label>

                <Select
                  className="select-quiz"
                  placeholder="Select "
                  closeMenuOnSelect={false}
                  isMulti
                  isSearchable
                  value={selectedAreaList}
                  options={areaList.map((area: any, key: any) => ({
                    value: area,
                    label: area,
                  }))}
                  onChange={changeHandler2}
                />
              </Form.Group>
            </div>
            <div
              style={{
                display: "inline-block",
                margin: "0 1%",
                width: "18%",
              }}
            >
              <Form.Group
                className="mb-1"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Sub-Area</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="subarea"
                  value={
                    allValues.subarea === null ? "no-subarea" : allValues.subarea
                  }
                  onChange={changeHandler1}
                  style={{
                    color:
                      selectedAreaList.length === 0 ||
                      selectedAreaList.length > 1
                        ? "grey"
                        : "black",
                  }}
                >
                  <option value={""}>Select Sub Area</option>
                  {subareaList.map((item: any, key: any) => {
                    return (
                      <option value={item.id} key={key}>
                        {item.subarea === null ? "No Subarea" : item.subarea}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </div>

            <div
              style={{
                display: "inline-block",
                margin: "0 1%",
                width: "18%",
              }}
            >
              <Form.Group
                className="mb-1 quiz"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Quiz Categories</Form.Label>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      maxHeight: "35px",
                    }),
                  }}
                  isMulti
                  className="select-quiz"
                  isSearchable
                  closeMenuOnSelect={false}
                  placeholder="Select"
                  value={selectedQuizList}
                  options={activeQuizzes.map((data: any, key: any) => ({
                    value: data.id,
                    label: data.title,
                  }))}
                  onChange={changeHandlerMuliti}
                />
              </Form.Group>
            </div>
          </div>
        </Form>
        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              paddingTop: "5%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div
            style={{
              height: "78vh",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              marginLeft: "2%",
              margin: "auto",
              marginTop: "3%",
              width: props.isCollasped ? "85vw" : "60vw",
            }}
          >
            <DataTable
              columns={columnsHeader}
              data={searchResult}
              fixedHeader
              fixedHeaderScrollHeight="370px"
              highlightOnHover
              pagination
              striped
              subHeaderWrap
              style={{ zIndex: 0 }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DefaultersReport;
