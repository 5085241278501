import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import {
  getallactivequizzes,
  getUserInfo,
  getAreaSubarea1,
  getSubarea1,
} from "../../../../store/commonActions";
import Select from "react-select";
import { sessionGetter, uniqueArea } from "../../../../utilities/helper/common";
import { teamAssign } from "../../../../store/slices/quizzesSlice";
import {
  ACTIVE_FLAG,
  API_LOADING,
  API_SUCCEEDED,
  TEAM_ASSIGN_ERROR,
  TEAM_ASSIGN_NO_USER,
} from "../../../../utilities/helper/constants";
import { CSVLink } from "react-csv";
import '../../../../utilities/Loader/CircularLoader.css';
import { toast } from "react-toastify";

interface AssignProps {
  title: Array<Object>;
  area: Array<string>;
  subarea: string;
  createdBy: string | null;
}

const TeamAssign = () => {
  const dispatch = useAppDispatch();
  const [allValues, setAllValues] = useState<AssignProps>({
    title: [],
    area: [],
    subarea: "",
    createdBy: sessionGetter("uid"),
  });

  // const [show, setShow] = useState(false);
  // const [usersData, setUsersData] = useState<any[]>([]);
  const [activeQuizzes, setActiveQuizzes] = useState<any[]>([]);
  const [areaList, setAreaList] = useState<any>([]);
  const [subareaList, setSubareaList] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedAreaList, setSelectedAreaList] = useState<any>([]);
  const [teamUserResult, setTeamUserResult] = useState<Array<Object>>([]);
  const currentTimestamp = new Date().toLocaleTimeString();

  // const [containNull, setContainNull] = useState<boolean>(false);
  const {
    areaSubareaApiStatus1,
    areaSubareaData1,
    subareaApiStatus,
    subareaData1,
  } = useAppSelector((store) => store.userNavigation);
  const {
    activeQuizzesList,
    apiStatusActiveQuizzesList,
    teamAssignUsers,
    teamAssignApiStatus,
  } = useAppSelector((store) => store.quizzesNavigation);
  const headers = [
    { label: "Lilly ID", key: "id" },
    { label: "Assigned Quizzes", key: "title" },
    { label: "Message", key: "message" },
  ];

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getallactivequizzes(sessionGetter('uid')));
    dispatch(getAreaSubarea1({ status: ACTIVE_FLAG }));
  }, []);

  // useEffect(() => {
  //   if (apiStatus === API_SUCCEEDED) {
  //     setUsersData(userInfo.data);
  //   }
  // }, [apiStatus]);
  useEffect(() => {
    if (apiStatusActiveQuizzesList === API_SUCCEEDED) {
      setActiveQuizzes(activeQuizzesList);
    }
  }, [apiStatusActiveQuizzesList]);

  useEffect(() => {
    if (areaSubareaApiStatus1 === API_SUCCEEDED) {
      setAreaList(uniqueArea(areaSubareaData1));
    }
  }, [areaSubareaApiStatus1]);

  useEffect(() => {
    dispatch(getSubarea1({ area: selectedArea }));
  }, [selectedArea]);

  // useEffect(() => {
  //   if (subareaData1.filter((item: any) => item.subarea === null).length !== 0)
  //     setContainNull(true);
  //   else setContainNull(false);
  // }, [subareaList]);

  useEffect(() => {
    if (subareaApiStatus === API_SUCCEEDED) {
      setSubareaList(subareaData1);
    }
  }, [subareaApiStatus]);

  useEffect(() => {
    if (teamAssignApiStatus === API_SUCCEEDED) {
      if (Object.keys(teamAssignUsers).length !== 0) {
        let combinedData: any = [];
        if (teamAssignUsers.arrAssigned.length !== 0)
          combinedData = teamAssignUsers.arrAssigned.map(
            (row: any, key: any) => ({
              ...row,
              message: "Assigned",
            })
          );
        if (teamAssignUsers.arrNotAssignedQuizzes.length !== 0) {
          let newArray = [];
          newArray = teamAssignUsers.arrNotAssignedQuizzes.map(
            (row: any, key: any) => ({
              ...row,
              message: "Already assigned and not completed",
            })
          );
          combinedData.push(...newArray);
        }
        if (teamAssignUsers.arrQuizAssignedDependency.length !== 0) {
          let newArray = [];
          newArray = teamAssignUsers.arrQuizAssignedDependency.map(
            (row: any, key: any) => ({
              ...row,
            })
          );
          combinedData.push(...newArray);
        }
        if (teamAssignUsers.arrAssigned.length === 0 && teamAssignUsers.arrNotAssignedQuizzes.length === 0 &&teamAssignUsers.arrQuizAssignedDependency.length === 0) {
          toast.error(TEAM_ASSIGN_NO_USER)
        }
        setTeamUserResult(combinedData);
      }
    }
    setAllValues({
      title: [],
      area: [],
      subarea: "",
      createdBy: sessionGetter("uid"),
    });
    setSelectedAreaList([]);
  }, [teamAssignApiStatus]);

  useEffect(() => {
    if(teamUserResult.length !== 0){
    const csvElement = document.getElementById("csv-link") as HTMLAnchorElement;
    csvElement.click();
    }
    setTeamUserResult([]);
  }, [teamUserResult]);

  const changeHandler = (e: any) => {
    setAllValues({
      ...allValues,
      [e.target.name]: e.target.value,
    });
  };

  const changeHandler2 = async (e: any) => {
    setSelectedAreaList(e);
    if (e.length === 1) {
      setSelectedArea(e[0].value);
    } else {
      setSubareaList([]);
      setSelectedArea("");
    }

    let arr: any = [];
    e.map((data: any) => {
      return arr.push(data.value);
    });

    await setAllValues({ ...allValues, area: arr });

    if (e === 1 && allValues.subarea !== "") {
      setAllValues({
        ...allValues,
        area: e[0].value,
      });
    } else {
      setAllValues({
        ...allValues,
        area: arr,
        subarea: "",
      });
    }
  };

  const changeHandlerMuliti = (e: any) => {
    setAllValues({ ...allValues, title: e });
  };

  const handleSubmit = (e: any) => {
    if (allValues.title.length === 0) {
      setErrorMessage(TEAM_ASSIGN_ERROR);
    } else if (selectedAreaList.length !== 0 && allValues.title.length !== 0) {
      setErrorMessage("");
      dispatch(teamAssign(allValues));
      // setShow(true);
    }
  };

  return (
    <>
      <h4 className="title">Team Assign</h4>

      <div
        style={{
          width: "40%",
          margin: "auto",
        }}
      >
        <div className="quiz" style={{ margin: "10px", width: "100%" }}>
          <Form.Label>Quiz Categories</Form.Label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            isSearchable
            value={allValues.title}
            placeholder="Select Quiz Categories"
            options={activeQuizzes.map((data: any) => ({
              value: data.id,
              label: data.title,
            }))}
            onChange={changeHandlerMuliti}
          />
        </div>
        <div style={{ margin: "10px", width: "100%" }}>
          <Form.Group
            className="mb-3 quiz"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Area</Form.Label>
            <Select
              placeholder="Select "
              closeMenuOnSelect={false}
              isMulti
              isSearchable
              value={selectedAreaList}
              options={areaList.map((area: any) => ({
                value: area,
                label: area,
              }))}
              onChange={changeHandler2}
            />
          </Form.Group>
        </div>
        <div style={{ margin: "10px", width: "100%" }}>
          <Form.Label>Sub-Area</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="subarea"
            value={allValues.subarea === null ? "no-subarea" : allValues.subarea}
            onChange={changeHandler}
            style={{
              color:
                allValues.area.length === 0 || allValues.area.length > 1
                  ? "grey"
                  : "black",
            }}
          >
            <option value={""}>Select Sub Area</option>
            {subareaList.map((item: any, key: any) => {
              return (
                <option value={item.id} key={key}>
                  {item.subarea === null ? "No Subarea" : item.subarea}
                </option>
              );
            })}
          </Form.Select>
        </div>
        {errorMessage ? (
          <p style={{ color: "red", marginLeft: "30px" }}>{errorMessage}</p>
        ) : null}
      </div>

      <div
        style={{
          display: "block",
          margin: "auto",
          width: "100px",
        }}
      >
        <Button
          variant="secondary"
          style={{ backgroundColor: "#582630", margin: "auto" }}
          onClick={handleSubmit}
        >
          Assign
        </Button>
        <CSVLink
          data={teamUserResult}
          headers={headers}
          style={{ display: "none" }}
          filename={`Team-Assign ${currentTimestamp}.csv`}
          id="csv-link"
        >
          Export
        </CSVLink>
        {teamAssignApiStatus === API_LOADING && <div className="loader-overlay"><div className="loader-circle"></div></div>}</div>
    </>
  );
};

export default TeamAssign;
