// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-style {
    margin: 1%;
    margin-left: 10%;
    width:25%;
}

.details-style {
    width:35%;
    margin:1%;
    float: right;
}

.container-div {
    display: flex;
    flex-direction: row;
}

.icons {
    color:black;
    font-size: 30px;
}

.icons-button{
    border:none;
    background:none;
}

.icons-button:hover {
    border:none;
    background:none;
    transform: scale(1.2);
}

.icons-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.icons-tooltip {
    margin-left: 33%;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/quizzes/new quizManagement/ShowQuiz.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,SAAS;IACT,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".label-style {\n    margin: 1%;\n    margin-left: 10%;\n    width:25%;\n}\n\n.details-style {\n    width:35%;\n    margin:1%;\n    float: right;\n}\n\n.container-div {\n    display: flex;\n    flex-direction: row;\n}\n\n.icons {\n    color:black;\n    font-size: 30px;\n}\n\n.icons-button{\n    border:none;\n    background:none;\n}\n\n.icons-button:hover {\n    border:none;\n    background:none;\n    transform: scale(1.2);\n}\n\n.icons-div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: row;\n}\n\n.icons-tooltip {\n    margin-left: 33%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
