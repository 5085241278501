import Form from "react-bootstrap/Form";
import { Card } from "react-bootstrap";
import { checkList } from "../../../../dummyData/userManageCheckList";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import DataTable from "react-data-table-component";

const Checklist = () => {
  const { checkListDetail} = checkList;
  const { showChecklistUser, showChecklistUserType } = useAppSelector(
    (store) => store.hrpNavigation
  );
  const columnsHeader = [
    {
      name: "Action",
      selector: (row: any) => row.action,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: "Where",
      selector: (row: any) => row.whereData,
      sortable: true,
      wrap: true,
    },
    {
      name: "Responsibility",
      selector: (row: any) => row.responsibility,
      sortable: true,
      wrap: true,
    },
    {
      name: "Timeline",
      selector: (row: any) => row.timeline,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      grow: 1.5,
      cell: (row: any) => (
        <Form.Select
          aria-label="Default select example"
          style={{ width: "100%" }}
          name="project role"
          value={row.status}
        >
          <option value="active">Not Started</option>
          <option value="active">In Progress</option>
          <option value="complete">Complete</option>
          <option value="active">Not Applicable</option>
        </Form.Select>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div>
      <h4 className="title">
          Checklist Details
        </h4>

        <Card
          style={{
            height: "80%",
            overflow: "auto",
            margin: "auto",
            marginTop: "4%",
            width: "97%",
          }}
        >
           <Card.Header
          style={{
            textAlign: "center",
            backgroundColor: "#A25035",
            fontFamily: "Merriweather Sans",
            fontWeight: "500",
            color: "white",
          }}
        >
          {showChecklistUserType === "editChecklist"
            ? `Edit ${showChecklistUser.lillyId}`
            : null}
          {showChecklistUserType === "showChecklist"
            ? `${showChecklistUser.lillyId}`
            : null}
          {showChecklistUserType === "createChecklist"
            ? "Checklist"
            : null}
        </Card.Header>
          <Form>
            <div style={{ margin: "auto" }}>
              <DataTable
                columns={columnsHeader}
                data={checkListDetail}
                fixedHeader
                fixedHeaderScrollHeight="390px"
                pagination
                highlightOnHover
                striped
              />
            </div>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Checklist;
