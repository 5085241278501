import { Button, Card, Form } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { checklist } from "../../../../dummyData/checklist";
import DataTable from "react-data-table-component";


const ShowUserChecklist = () => {
  const { showChecklistUser, showChecklistUserType } = useAppSelector(
    (store) => store.hrpNavigation
  );


  const { checklistFields} = checklist;


  const columnsHeader = [
    {
      name: "Action",
      selector: (row: any) => row.checklistName,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: "Where",
      selector: (row: any) => row.where,
      sortable: true,
      wrap: true,
    },
    {
      name: "Responsibility",
      selector: (row: any) => row.responsibility,
      sortable: true,
      wrap: true,
    },
    {
      name: "Timeline",
      selector: (row: any) => row.timeline,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      grow: 1.5,
      cell: (row: any) => (
        <Form.Select
          aria-label="Default select example"
          style={{ width: "100%" }}
          name="project role"
          value={row.status}
        >
          <option value="active">Not Started</option>
          <option value="active">In Progress</option>
          <option value="complete">Complete</option>
          <option value="active">Not Applicable</option>
        </Form.Select>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <>
      <Card
        style={{
          height: "80%",
          overflow: "auto",
          margin: "auto",
          marginTop: "4%",
          width: "97%",
        }}
      >
        <Card.Header
          style={{
            textAlign: "center",
            backgroundColor: "#A25035",
            fontFamily: "Merriweather Sans",
            fontWeight: "500",
            color: "white",
          }}
        >
          {showChecklistUserType === "editChecklist"
            ? `Edit ${showChecklistUser.lillyId}`
            : null}
          {showChecklistUserType === "showChecklist"
            ? `${showChecklistUser.lillyId}`
            : null}
          {showChecklistUserType === "createChecklist"
            ? "Create Checklist"
            : null}
        </Card.Header>
        <Form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Control
              size="sm"
              type="input"
              placeholder="Employee ID"
              style={{ width: "25.5%", display: "inline-block", margin: "1%" }}
              name="employeeId"
              value={
                showChecklistUserType === "createChecklist" ||
                showChecklistUserType === "editChecklist"
                  ? showChecklistUser.lillyId
                  : null
              }
            />
            <Form.Control
              size="sm"
              type="input"
              placeholder="Employee Name"
              style={{ width: "25.5%", display: "inline-block", margin: "1%" }}
              name="employeeName"
            />
            <Form.Control
              size="sm"
              type="input"
              placeholder="Area"
              style={{ width: "25.5%", display: "inline-block", margin: "1%" }}
              name="area"
            />
          </div>

          <div style={{ margin: "auto" }}>
            <DataTable
              columns={columnsHeader}
              data={checklistFields}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="390px"
              highlightOnHover
              striped
            />
          </div>
        </Form>
      </Card>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showChecklistUserType === "createChecklist" ||
        showChecklistUserType === "editChecklist" ? (
          <Button
            style={{
              backgroundColor: "#A25035",
              color: "white",
              border: "none",
              margin: "2%",
            }}
          >
            Submit
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default ShowUserChecklist;
