// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectedOptionQuiz1 {
  color: black;
  transform: scale(1.05);
  margin: 1% 5%;

  cursor: pointer;
  font-weight: 500;
}

.notSelectedQuiz1 {
  margin: 1% 5%;
  color: grey;
  cursor: pointer;
}

.selectedMode {
  background-color: #a25035;
  color: #fff;
  border: none;
  align-items: center;
  justify-content: center;
  margin: 5%;
  height: 15%;
  transform: scale(1.02);
  font-weight: 500;
  font-family: "Merriweather Sans";
  cursor: pointer;
}

.notSelectedMode {
  border: none;
  align-items: center;
  justify-content: center;
  margin: 5% 8%;
  height: 15%;
  background-color: #f8ecd1;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-weight: 500;
  font-family: Merriweather Sans;
  cursor: pointer;
}

.containerq {
  background-color: red;
  overflow: hidden;
  transition: width 0.3s ease;
  width: 30%;
}
.collapsedq {
  width: 5%;
}

.content {
  height: 100%;
  background-color: #f5f5f5;

  width: 100%;
  text-align: center;
  font-weight: 700;
  display: flex;
  flex-direction: row;
}

.usermanagestyle {
  writing-mode: vertical-lr;
  text-orientation: upright;
}
`, "",{"version":3,"sources":["webpack://./src/components/modules/quizzes/QuizzesNav.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;EACtB,aAAa;;EAEb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,WAAW;EACX,yBAAyB;EACzB,4EAA4E;EAC5E,gBAAgB;EAChB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,2BAA2B;EAC3B,UAAU;AACZ;AACA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,yBAAyB;;EAEzB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".selectedOptionQuiz1 {\n  color: black;\n  transform: scale(1.05);\n  margin: 1% 5%;\n\n  cursor: pointer;\n  font-weight: 500;\n}\n\n.notSelectedQuiz1 {\n  margin: 1% 5%;\n  color: grey;\n  cursor: pointer;\n}\n\n.selectedMode {\n  background-color: #a25035;\n  color: #fff;\n  border: none;\n  align-items: center;\n  justify-content: center;\n  margin: 5%;\n  height: 15%;\n  transform: scale(1.02);\n  font-weight: 500;\n  font-family: \"Merriweather Sans\";\n  cursor: pointer;\n}\n\n.notSelectedMode {\n  border: none;\n  align-items: center;\n  justify-content: center;\n  margin: 5% 8%;\n  height: 15%;\n  background-color: #f8ecd1;\n  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n  font-weight: 500;\n  font-family: Merriweather Sans;\n  cursor: pointer;\n}\n\n.containerq {\n  background-color: red;\n  overflow: hidden;\n  transition: width 0.3s ease;\n  width: 30%;\n}\n.collapsedq {\n  width: 5%;\n}\n\n.content {\n  height: 100%;\n  background-color: #f5f5f5;\n\n  width: 100%;\n  text-align: center;\n  font-weight: 700;\n  display: flex;\n  flex-direction: row;\n}\n\n.usermanagestyle {\n  writing-mode: vertical-lr;\n  text-orientation: upright;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
