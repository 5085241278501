import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { TrainingState } from "../../models/redux-models";
import { getUserRole } from "../../service/learnersApi";
import { API_FAILED, API_LOADING, API_SUCCEEDED } from "../../utilities/helper/constants";



const initialState: TrainingState = {
  
    trainingPath:"TCSPharma",
    userRole: "[]",
    apiStatus: ""
}
export const  getRole = createAsyncThunk(
    "/usermanage/userrole",
      async (data: any, thunkAPI) => {
        const response = await getUserRole(data);
        return response;
      }
    );

const trainingSlice = createSlice({
    name: "trainingNavigation",
    initialState,
    reducers: {
         setTraining: (state, action) => {
         state.trainingPath= action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(getRole.pending, (state) => {
            state.apiStatus = API_LOADING;
            state.userRole="[]";
          });
          builder.addCase(getRole.fulfilled, (state, { payload }) => {         
            state.userRole=payload.userRole;
            state.apiStatus = API_SUCCEEDED;
          });
          builder.addCase(getRole.rejected, (state) => {
            state.apiStatus = API_FAILED;
            state.userRole="[]";
          });
    }
})

export default trainingSlice;
export {trainingSlice};
export const { setTraining} = trainingSlice.actions;