// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.takequizOuterdiv{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    border-radius: 20px 0px 0px 20px;
}
.takequizContentdiv{
    display: inline-block;
    width: 70%;
    height: 100%;
    background-color: #fff;
}
.dark-teme-swal {
    background-color: #222;
    color: #fff;
}

.swal2-timer-progress-bar-background {
    background-color: #4ADEDE;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/learners/quizPage/TakeQuiz.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,gCAAgC;AACpC;AACA;IACI,qBAAqB;IACrB,UAAU;IACV,YAAY;IACZ,sBAAsB;AAC1B;AACA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".takequizOuterdiv{\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    height: 100vh;\n    border-radius: 20px 0px 0px 20px;\n}\n.takequizContentdiv{\n    display: inline-block;\n    width: 70%;\n    height: 100%;\n    background-color: #fff;\n}\n.dark-teme-swal {\n    background-color: #222;\n    color: #fff;\n}\n\n.swal2-timer-progress-bar-background {\n    background-color: #4ADEDE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
