import { useEffect } from "react";
import ShowUserChecklist from "./Checklist/ShowUserChecklist";
import HrpForm from "./Hrp/HrpForm";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import RequestHistory from "./Hrp/RequestHistory";
import { setModule } from "../../../store/commonActions";

const HrpContent = () => {
  const { showHrpContent } = useAppSelector((store) => store.hrpNavigation);
  const dispatch = useAppDispatch();
  useEffect(()=> {
    dispatch(setModule("HRP"))
  },[])
  return (
    <>
      <div
        style={{
          display: "inline-block",
          width: "70%",
          height: "100%",
          borderRadius: "20px 0px 0px 20px "
        }}
      >
      
      
       {showHrpContent==="Hrp"?<HrpForm/>:null}
       {showHrpContent==="editHrpUser"?<HrpForm/>:null}
       {showHrpContent==="requestHistory"?<RequestHistory/>:null}
       {showHrpContent==="Checklist"?<ShowUserChecklist/>:null}
      
      </div>
    </>
  );
};

export default HrpContent;
