import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { FAIL_FLAG, PASS_FLAG } from "../../../../utilities/helper/constants";

const HrpForm = () => {
  const { showHrpContent, showChecklistUser } = useAppSelector(
    (store) => store.hrpNavigation
  );
  const [show, setShow] = useState<Boolean>(false);
  const [clicked, setClicked] = useState<any>();
  const handleShow = (e: any) => {
    setShow(true);
    setClicked(e);
  };
  return (
    <>
      <Card
        style={{
          height: "80%",
          overflow: "auto",
          margin: "auto",
          marginTop: "4%",
          width: "97%",
        }}
      >
        <Card.Header
          style={{
            textAlign: "center",
            backgroundColor: "#A25035",
            fontFamily: "Merriweather Sans",
            fontWeight: "500",
            color: "white",
          }}
        >
          {showHrpContent === "editHrpUser"
            ? `Edit ${showChecklistUser.firstName}`
            : null}
          {showHrpContent === "Hrp" ? "Create New User" : null}
        </Card.Header>
        <Form>
          <h6
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "8%",
              marginTop: "2%",
              fontFamily: "Merriweather Sans",
            }}
          >
            Employee Info
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Legal First Name</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Legal First Name"
                  style={{ width: "100%" }}
                  name="firstName"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.firstName
                      : ""
                  }
                  disabled={showHrpContent === "editHrpUser" ? true : false}
                />
              </Form.Group>
            </div>
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Legal Middle Name </Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Legal Middle Name "
                  style={{ width: "100%" }}
                  name="middleName"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.middleName
                      : ""
                  }
                  disabled={showHrpContent === "editHrpUser" ? true : false}
                />
              </Form.Group>
            </div>
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Legal Last Name</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Legal Last Name"
                  style={{ width: "100%" }}
                  name="lastname"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.lastName
                      : ""
                  }
                  disabled={showHrpContent === "editHrpUser" ? true : false}
                />
              </Form.Group>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Employee ID</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Employee ID"
                  style={{ width: "100%" }}
                  name="employeeId"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.employeeId
                      : ""
                  }
                  disabled={showHrpContent === "editHrpUser" ? true : false}
                />
              </Form.Group>
            </div>
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  min="1970-01-01"
                  max="2050-12-31"
                  placeholder="Date Of Birth"
                  style={{ width: "100%" }}
                  name="dateOfBirth"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.dateOfBirth
                      : ""
                  }
                  disabled={showHrpContent === "editHrpUser" ? true : false}
                />
              </Form.Group>
            </div>

            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Label>Onsite/Offshore</Form.Label>
              <br />
              <Form.Check
                inline
                label="Onsite"
                name="location"
                type="radio"
                id={`inline-radio-1`}
                checked={
                  showHrpContent === "editHrpUser" &&
                  showChecklistUser.location === "Onsite"
                    ? true
                    : false
                }
                disabled={showHrpContent === "editHrpUser" ? true : false}
                onClick={() => handleShow("Onsite")}
              />
              <Form.Check
                inline
                label="Offshore"
                name="location"
                type="radio"
                id={`inline-radio-2`}
                checked={
                  showHrpContent === "editHrpUser" &&
                  showChecklistUser.location === "Offshore"
                    ? true
                    : false
                }
                disabled={showHrpContent === "editHrpUser" ? true : false}
                onClick={() => handleShow("Offshore")}
              />
            </div>
          </div>
          {showHrpContent === "editHrpUser" &&
          showChecklistUser.location === "Offshore" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "83%",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>PAN ( Enter last 4 digits )</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="PAN"
                    style={{ width: "31%" }}
                    name="pan"
                    value={
                      showHrpContent === "editHrpUser"
                        ? showChecklistUser.PAN
                        : ""
                    }
                    disabled={showHrpContent === "editHrpUser" ? true : false}
                  />
                </Form.Group>
              </div>
            </div>
          ) : null}
          {showHrpContent === "editHrpUser" &&
          showChecklistUser.location === "Onsite" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "83%",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>SSN ( Enter last 4 digits )</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="SSN"
                    style={{ width: "31%" }}
                    name="ssn"
                    value={
                      showHrpContent === "editHrpUser"
                        ? showChecklistUser.SSN
                        : ""
                    }
                    disabled={showHrpContent === "editHrpUser" ? true : false}
                  />
                </Form.Group>
              </div>
            </div>
          ) : null}
          {clicked === "Offshore" && show ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "83%",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>PAN ( Enter last 4 digits )</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="PAN"
                    style={{ width: "31%" }}
                    name="pan"
                  />
                </Form.Group>
              </div>
            </div>
          ) : null}
          {clicked === "Onsite" && show ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "83%",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>SSN ( Enter last 4 digits )</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="SSN"
                    style={{ width: "31%" }}
                    name="ssn"
                  />
                </Form.Group>
              </div>
            </div>
          ) : null}
          <h6
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "8%",
              marginTop: "1%",
              fontFamily: "Merriweather Sans",
            }}
          >
            Project Info
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label> Project Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Project Name"
                  style={{ width: "100%" }}
                  name="projectName"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.projectName
                      : ""
                  }
                />
              </Form.Group>
            </div>

            <div
              style={{
                display: "inline-block",
                margin: "10px",
                width: "26%",
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <Form.Label>Value Cycle</Form.Label>
              <Form.Select
                aria-label="Default select example"
                style={{ width: "100%" }}
                name="valueCycle"
                value={
                  showHrpContent === "editHrpUser"
                    ? showChecklistUser.valueCycle
                    : ""
                }
              >
                <option value="">Please Select</option>
                <option value="Research IT">Research IT</option>
                <option value="MD IT/Research IT">MD IT/Research IT</option>
                <option value="MD IT">MD IT</option>
                <option value="iSecurity">iSecurity</option>
              </Form.Select>
            </div>
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Date of joining Account</Form.Label>
                <Form.Control
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  min="1970-01-01"
                  max="2050-12-31"
                  placeholder="Date of joining Account"
                  style={{ width: "100%" }}
                  name="dateOfJoiningAccount"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.dateOfJoiningAccount
                      : ""
                  }
                  disabled={showHrpContent === "editHrpUser" ? true : false}
                  
                />
              </Form.Group>
            </div>
          </div>
          <h6
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "8%",
              marginTop: "1%",
              fontFamily: "Merriweather Sans",
            }}
          >
            HRP
          </h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Project Sponsor Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Project Sponsor Name"
                  style={{ width: "100%" }}
                  name="projectSponsorName"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.projectSponsorName
                      : ""
                  }
                />
              </Form.Group>
            </div>

            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Project Sponsor Lilly Id</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Project Sponsor Lilly Id"
                  style={{ width: "100%" }}
                  name="projectSponsorLillyId"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.projectSponsorLillyId
                      : ""
                  }
                />
              </Form.Group>
            </div>

            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>BGC Completion Date</Form.Label>
                <Form.Control
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  min="1970-01-01"
                  max="2050-12-31"
                  placeholder="BGC Completion Date"
                  style={{ width: "100%" }}
                  name="bgcCompletionDate"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.bgcCompletionDate
                      : ""
                  }
                  disabled={showHrpContent === "editHrpUser" ? true : false}
                  
                />
              </Form.Group>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Drug Test Date</Form.Label>
                <Form.Control
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  min="1970-01-01"
                  max="2050-12-31"
                  placeholder="Drug Test Date"
                  style={{ width: "100%" }}
                  name="drugTestDate"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.drugTestDate
                      : ""
                  }
                  disabled={showHrpContent === "editHrpUser" ? true : false}
                  
                />
              </Form.Group>
            </div>

            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Label>Drug Test Result</Form.Label>
              <br />
              <Form.Check
                inline
                label="Pass"
                name="drugTest"
                type="radio"
                id={`inline-radio-1`}
                checked={
                  showHrpContent === "editHrpUser" &&
                  showChecklistUser.drugTestCleared === PASS_FLAG
                    ? true
                    : false
                }
                disabled={showHrpContent === "editHrpUser" ? true : false}
              />
              <Form.Check
                inline
                label="Fail"
                name="drugTest"
                type="radio"
                id={`inline-radio-2`}
                checked={
                  showHrpContent === "editHrpUser" &&
                  showChecklistUser.drugTestCleared === FAIL_FLAG
                    ? true
                    : false
                }
                disabled={showHrpContent === "editHrpUser" ? true : false}
              />
            </div>

            <div
              style={{ display: "inline-block", margin: "10px", width: "26%" }}
            >
              <Form.Label>Worked before</Form.Label>
              <br />
              <Form.Check
                inline
                label="Yes"
                name="workedBefore"
                type="radio"
                id={`inline-radio-1`}
                checked={
                  showHrpContent === "editHrpUser" &&
                  showChecklistUser.workedBefore === "Yes"
                    ? true
                    : false
                }
                disabled={showHrpContent === "editHrpUser" ? true : false}
                onClick={() => {
                  setClicked(showChecklistUser.workedBefore);
                  setShow(true);
                }}
              />
              <Form.Check
                inline
                label="No"
                name="workedBefore"
                type="radio"
                id={`inline-radio-2`}
                checked={
                  showHrpContent === "editHrpUser" &&
                  showChecklistUser.workedBefore === "No"
                    ? true
                    : false
                }
                disabled={showHrpContent === "editHrpUser" ? true : false}
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>
          </div>
          {showHrpContent === "editHrpUser" &&
          showChecklistUser.workedBefore === "Yes" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "40.5%",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Last Lilly ID</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder={showChecklistUser.lillyId}
                    style={{ width: "100%" }}
                    name="lastLillyId"
                    value={
                      showHrpContent === "editHrpUser"
                        ? showChecklistUser.lillyId
                        : ""
                    }
                    disabled={showHrpContent === "editHrpUser" ? true : false}
                  />
                </Form.Group>
              </div>
              <div
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "40.5%",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Last Global ID</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Last Global ID"
                    style={{ width: "100%" }}
                    name="lastGlobalId"
                    value={
                      showHrpContent === "editHrpUser"
                        ? showChecklistUser.globalId
                        : ""
                    }
                    disabled={showHrpContent === "editHrpUser" ? true : false}
                  />
                </Form.Group>
              </div>
            </div>
          ) : null}
          {clicked === showChecklistUser.workedBefore && show ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "40.5%",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Last Lilly ID</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Last Lilly ID"
                    style={{ width: "100%" }}
                    name="lastLillyId"
                    value={
                      showHrpContent === "editHrpUser"
                        ? showChecklistUser.lillyId
                        : ""
                    }
                    
                  />
                </Form.Group>
              </div>
              <div
                style={{
                  display: "inline-block",
                  margin: "10px",
                  width: "40.5%",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Last Global ID</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Last Global ID"
                    style={{ width: "100%" }}
                    name="lastGlobalId"
                    value={
                      showHrpContent === "editHrpUser"
                        ? showChecklistUser.globalId
                        : ""
                    }
                  />
                </Form.Group>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "inline-block", margin: "10px", width: "83%" }}
            >
              <Form.Label>NDA/ADF forms signed and submitted</Form.Label>
              <br />
              <Form.Check
                inline
                label="Yes"
                name="ndaForm"
                type="radio"
                id={`inline-radio-1`}
                checked={
                  showHrpContent === "editHrpUser" &&
                  showChecklistUser.ndaAdfSigned === "Yes"
                    ? true
                    : false
                }
                disabled={showHrpContent === "editHrpUser" ? true : false}
              />
              <Form.Check
                inline
                label="No"
                name="ndaForm"
                type="radio"
                id={`inline-radio-2`}
                checked={
                  showHrpContent === "editHrpUser" &&
                  showChecklistUser.ndaAdfSigned === "No"
                    ? true
                    : false
                }
                disabled={showHrpContent === "editHrpUser" ? true : false}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "inline-block", margin: "10px", width: "85%" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
                style={{ margin: "10px" }}
              >
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="comments"
                  value={
                    showHrpContent === "editHrpUser"
                      ? showChecklistUser.comments
                      : ""
                  }
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Card>
      {showHrpContent === "editHrpUser" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "#A25035",
              color: "white",
              border: "none",
              margin: "2%",
            }}
          >
            Edit
          </Button>
          <Button
            style={{
              backgroundColor: "#A25035",
              color: "white",
              border: "none",
              margin: "2%",
            }}
          >
            Reset
          </Button>
          <Button
            style={{
              backgroundColor: "#A25035",
              color: "white",
              border: "none",
              margin: "2%",
            }}
          >
            Delete
          </Button>
        </div>
      ) : null}
      {showHrpContent === "Hrp" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "#A25035",
              color: "white",
              border: "none",
              margin: "2%",
            }}
          >
            Submit
          </Button>
          <Button
            style={{
              backgroundColor: "#A25035",
              color: "white",
              border: "none",
              margin: "2%",
            }}
          >
            Reset
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default HrpForm;
