import { format } from "date-fns";
import { hrp } from "../../../../dummyData/userMangehrp";
import { DATE_FORMAT } from "../../../../utilities/helper/constants";

const Hrp = () => {
  const { hrpDetail } = hrp;

  return (
    <>
      <div>
      <h4 className="title">
          HRP History
        </h4>

        <div style={{ overflowY: "auto", textAlign: "center", height: "82vh" }}>
          {hrpDetail.map((item: any, key: any) => (
            <div
              style={{
                display: "inline-block",
                width: "350px",
                height: "150px",
                borderRadius: "20px 20px 20px 20px ",
                margin: "2%",
                backgroundColor: "#4bb7e6",
                color: "#363031",
              }}
              key={key}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  borderRadius: "20px 20px 20px 20px ",
                  boxShadow:
                    "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19",
                  padding: "2%",
                }}
              >
                {/* Left div */}

                {/* right div  */}
                <div
                  style={{
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    margin: "2%",
                    textAlign: "left",
                  }}
                >
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: "700",
                      marginBottom: "0",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <b>Action:</b> {item.action}
                  </p>

                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    <b>Value Cycle:</b> {item.valueCycle}
                    <br />
                    <b>Status:</b> {item.status}
                    &nbsp;&nbsp;
                    <b>Comments:</b> {item.comments}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br />
                    <b>Created By:</b> {item.createdBy}
                    &nbsp;&nbsp;
                    <b>Completed By:</b> {item.completedBy}
                    <br />
                    <b>Created At:</b>{" "}
                    {format(new Date(item.createdAt), DATE_FORMAT)}
                    <br />
                    <b>Completed At:</b>{" "}
                    {format(new Date(item.completedAt), DATE_FORMAT)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Hrp;
