import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { setQuizDetails } from "../../../../store/commonActions";
import "./quizStatus.css";
import { Form } from "react-bootstrap";
import { Search, convertDate } from "../../../../utilities/helper/common";
import {
  API_LOADING,
  FAIL_FLAG,
  NOT_ATTEMPTED_FLAG,
  PASS_FLAG,
} from "../../../../utilities/helper/constants";
import "../../../../utilities/Loader/NavLoader.css";

const All = (props: any) => {
  const { quizzes, selectedQuizPath, setShow, apiStatus } = props;
  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState<any>(quizzes[0]);
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    setData(quizzes);
  }, [quizzes]);

  useEffect(() => {
    if (quizzes.length !== 0) {
      setSelected(quizzes[0]);
      dispatch(setQuizDetails(quizzes[0]));
    }
  }, [selectedQuizPath, quizzes]);

  const [searchInput, setSearchInput] = useState<any>("");
  const searchParam: any = {
    all: ["attempts","dueDate","state","completionDate","marks", "activity"],
    unattempted: ["attempts", "dueDate", "state", "marks", "activity"],
    completed: ["state", "completionDate", "marks", "activity"],
  };

  const handleClick = (quiz: Object) => {
    dispatch(setQuizDetails(quiz));
    if (selectedQuizPath !== "completed") setShow(false);
  };
  const handleSelectQuiz = (quiz: object) => {
    setSelected(quiz);
  };
  return (
    <>
      <div className="search-div">
        <Form.Control
          type="search"
          placeholder="🕵️‍♂️ Search"
          className="search"
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>

      {apiStatus == API_LOADING ? (
         <div className="loader-div">
        <div className="loader-circle"></div></div>
      ) : (
        <div className="quiz-status">
          {Search(data, searchInput, searchParam[selectedQuizPath]).map(
            (quiz: any, key: any) => (
              <div
                className="outer-div"
                onClick={() => handleClick(quiz)}
                key={key}
              >
                <div
                  className={
                    selected === quiz ? "quizSelected" : "quizNotSelected"
                  }
                  onClick={() => handleSelectQuiz(quiz)}
                  style={{
                    backgroundColor:
                      selected === quiz
                        ? quiz.state === PASS_FLAG &&
                          selectedQuizPath !== "unattempted"
                          ? "#74C365"
                          : quiz.state === FAIL_FLAG &&
                              selectedQuizPath !== "completed"
                            ? "#CA3433"
                            : "#c48857"
                        : "white",
                  }}
                >
                  {/* left div   */}
                  <div className="left-div">
                    {selectedQuizPath === "completed" ? (
                      selected === quiz ? (
                        <img
                          src="./assets/Correct icon.svg"
                          className="quiz-image"
                          alt="Correct"
                        />
                      ) : (
                        <img
                          src="./assets/Correct_Black icon.svg"
                          className="quiz-image"
                          alt="Correct Black"
                        />
                      )
                    ) : null}
                    {selectedQuizPath === "unattempted" ? (
                      selected === quiz ? (
                        <img
                          src="./assets/Incorrect icon.svg"
                          className="quiz-image"
                          alt="Incorrect"
                        />
                      ) : (
                        <img
                          src="./assets/Incorrect_Black icon.svg"
                          className="quiz-image"
                          alt="Incorrect Black"
                        />
                      )
                    ) : null}
                    {selectedQuizPath === "all" ? (
                      selected === quiz ? (
                        quiz.state === PASS_FLAG ? (
                          <img
                            src="./assets/Correct icon.svg"
                            className="quiz-image"
                            alt="Correct"
                          />
                        ) : (
                          <img
                            src="./assets/Incorrect icon.svg"
                            className="quiz-image"
                            alt="Incorrect"
                          />
                        )
                      ) : quiz.state === PASS_FLAG ? (
                        <img
                          src="./assets/Correct_Black icon.svg"
                          className="quiz-image"
                          alt="Correct Black"
                        />
                      ) : (
                        <img
                          src="./assets/Incorrect_Black icon.svg"
                          className="quiz-image"
                          alt="Incorrect Black"
                        />
                      )
                    ) : null}
                  </div>
                  {/* right div  */}
                  <div className="right-div">
                    <div className="quiz-title" title={`${quiz.activity}`}>
                      <strong>{quiz.activity}</strong>
                    </div>
                    {selectedQuizPath === "unattempted" ||
                    selectedQuizPath === "all" ? (
                      quiz.state === FAIL_FLAG ||
                      quiz.state === NOT_ATTEMPTED_FLAG ? (
                        <>
                          <p
                            className={
                              selected === quiz ? "turn-white" : "turn-grey"
                            }
                          >
                            State: {quiz.state}
                            <br />
                            Due Date: {convertDate(quiz.dueDate)}
                            &nbsp;&nbsp; | &nbsp;&nbsp; Attempts:&nbsp;&nbsp;
                            {quiz.attempts}/{quiz.maxAttempt}
                          </p>
                        </>
                      ) : null
                    ) : null}
                    {selectedQuizPath === "completed" ||
                    selectedQuizPath === "all" ? (
                      quiz.state === PASS_FLAG ? (
                        <>
                          <p
                            className={
                              selected === quiz ? "turn-white" : "turn-grey"
                            }
                          >
                            Score: {`${quiz.marks}%`} State: {quiz.state}
                            <br />
                            Completed Date: {convertDate(quiz.completionDate)}
                          </p>
                        </>
                      ) : null
                    ) : null}
                  </div>
                </div>
              </div>
            )
          )}
          <div
            style={{
              position: "absolute",
              top: "96%",
              marginLeft: "25%",
              width: "70%",
              display: "block",
              fontWeight: "500",
              fontSize: "12px",
              color: "grey",
            }}
          >
            {Search(data, searchInput, searchParam[selectedQuizPath]).length}{" "}
            Results
          </div>
        </div>
      )}
    </>
  );
};

export default All;
