import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import PharmaTrainings from "./content/PharmaTrainings";
import ITCommon from "./content/ITCommon";
import UsefulInfo from "./content/UsefulInfo";
import Other from "./content/Other";
import "./content/Content.css"
import { setModule } from "../../../store/commonActions";

const TrainingsContent = () => {
  const { trainingPath } = useAppSelector((store) => store.trainingNavigation);
  const dispatch = useAppDispatch();
  useEffect(()=> {
    dispatch(setModule("Trainings"));
  },[])
  return (
    <>
      <div className="content-outerdiv">
        
          {trainingPath === "TCSPharma" ? <PharmaTrainings /> : null}
          {trainingPath === "ITProcedures" ? <ITCommon /> : null}
         {trainingPath === "UsefulInfo" ? <UsefulInfo /> : null}
          {trainingPath === "Other" ? <Other /> : null}
       
      </div>
    </>
  );
};

export default TrainingsContent;
