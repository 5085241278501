// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-container {
  width: 100%;
  height: 100%;
  margin: 1%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.index-container {
    width:40px;
}

.question-card {
  width: 95%;
  height: 10%;
  margin: 2%;
}

.delete-button1 {
  font-size: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modules/quizzes/new quizManagement/create quiz/CreatedQuestionNav.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA;EACE,UAAU;EACV,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".question-container {\n  width: 100%;\n  height: 100%;\n  margin: 1%;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  cursor: pointer;\n}\n\n.index-container {\n    width:40px;\n}\n\n.question-card {\n  width: 95%;\n  height: 10%;\n  margin: 2%;\n}\n\n.delete-button1 {\n  font-size: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
