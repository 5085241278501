import TrainingsNav from "./TrainingsNav";
import TrainingsContent from "./TrainingsContent";

const Trainings = () => {
  return (
    <>
      <div
        className="content-area"
      >
        <TrainingsNav />
        <TrainingsContent />
      </div>
    </>
  );
};
export default Trainings;
