import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { setEditQuestionIndex, setSelectedAssignNav } from "../../../../../store/slices/quizzesSlice";

const ModifyQuestionNav = () => {
  const dispatch = useAppDispatch();
  const { quizList, editQuestionIndex ,apiQuesStatus} = useAppSelector((store) => store.quizzesNavigation);
  const [questionList, setQuestionList] = useState<Array<Object>>([]);
 

  useEffect(() => {
    setQuestionList(quizList);
  }, [apiQuesStatus]);

  return (
    <div className="container" style={{ overflowY: "auto", height: "80%" }}>
      {questionList?.map((question: any, index: any) => (
        <div
          className="d-flex justify-content-center align-items-center container question-card"
          key={index}
          onClick={() => {
            dispatch(setEditQuestionIndex(index));
            dispatch(setSelectedAssignNav("EditQuestions"))
          }}
        >
          <div className="d-flex justify-content-center index-container">
            Q{index + 1}.&nbsp;
          </div>
          <div
            style={{
              background:index === editQuestionIndex? "linear-gradient(#b6eabe, #1f7ea1)": "#c1bfbf45",
              color: index === editQuestionIndex ? "#fff" : "#000",
              // backgroundColor: index === editQuestionIndex ? "#a6b9ba" : "white",
            }}
            className="card container question-container d-flex justify-content-center"
            title={question.questions}
          >
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              title={question.questions}
            >
              {question.questions}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ModifyQuestionNav;
