import Confetti from "react-confetti";
import "./Result.css";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useAppSelector } from "../../../hooks/redux-hooks";
import {
  MODULE_SELECTED,
  PASS_FLAG,
} from "../../../utilities/helper/constants";
import { sessionSetter } from "../../../utilities/helper/common";
import Snowfall from "react-snowfall";

const Result = () => {
  const navigate = useNavigate();
  const { result, percentage } = useAppSelector(
    (store) => store.learnerNavigation
  );
  const resultParam: any = {
    PASS: {
      title: "Congratulations!",
      message1: "You have successfully cleared the quiz.",
      score: `Score: ${percentage.toFixed(2)}%`,
      message2: "Keep it up!",
    },
    FAIL: {
      title: "Sorry!",
      message1: "You did not clear the quiz.",
      score: `Score: ${percentage.toFixed(2)}%`,
      message2: "Better luck next time!",
    },
  };

  const showResult = (data: any) => {
    return (
      <>
        <h1
          className={
            result === PASS_FLAG ? "pass result-margin" : "fail result-margin"
          }
        >
          {data.title}
          {result === PASS_FLAG ? (
            <h1 className="emoji">&#x1F604; </h1>
          ) : (
            <h1 className="emoji"> &#x1F613;</h1>
          )}
        </h1>
        <h3 className={result === PASS_FLAG ? "pass" : "fail"}>
          {data.message1}
        </h3>
        <h3 className={result === PASS_FLAG ? "pass" : "fail"}>{data.score}</h3>
        <h4 className={result === PASS_FLAG ? "pass" : "fail"}>
          {data.message2}
        </h4>
        <Button
          className="ok-button"
          onClick={() => {
            sessionSetter(MODULE_SELECTED, "Trainings");
            navigate("/");
            navigate(0);
          }}
        >
          Ok!
        </Button>
      </>
    );
  };

  return (
    <>
      {result === PASS_FLAG ? (
        <>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
          {showResult(resultParam[result])}
        </>
      ) : (
        <div  className="failresult-background" >
          <Snowfall snowflakeCount={150} />
          {showResult(resultParam[result])}
        </div>
      )}
    </>
  );
};

export default Result;
