import { Button, Form, InputGroup } from "react-bootstrap";
import { useAppSelector } from "../../../../../hooks/redux-hooks";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../ShowEditQuizNav.css";
import { useAppDispatch } from "../../../../../hooks/redux-hooks";
import {
  getAllQuizzesByOwner,
  setSelectedAssignNav,
  updateQuestion,
  setEditQuestionIndex,
  getallqueslist,
} from "../../../../../store/slices/quizzesSlice";
import { useEffect, useState } from "react";
import "./Modify.css";
import { toast } from "react-toastify";
import {
  ACTIVE_FLAG,
  API_FAILED,
  API_SUCCEEDED,
  INACTIVE_FLAG,
  MIN_QUESTION_ERROR,
  SIMILAR_QUES_PRESENT,
  UPDATE_QUESTION_ERROR,
  UPDATE_QUESTION_SUCCESS,
} from "../../../../../utilities/helper/constants";
import { sessionGetter } from "../../../../../utilities/helper/common";
import Swal from "sweetalert2";
import { TiDelete } from "react-icons/ti";

interface QuestionProps {
  questions: string;
  answers: string;
  questionTy: string;
  options: string;
  id: number;
  updatedBy: string | null;
  status: string;
}

const ModifyQuestion = (props: any) => {
  const {
    editQuestionIndex,
    editQuizDetails,
    quizList,
    apiUpdateQues,
    questionFlag
  } = useAppSelector((store) => store.quizzesNavigation);
  const { allValues, questionObjectValues, setQuestionObjectValues, setShow } =
    props;
  const [optionCount, setOptionCount] = useState<Array<any>>([]);
  const [optionObject, setOptionObject] = useState<Object>({});
  const [answerObject, setAnswerObject] = useState<Object>({});
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [finalObject, setFinalObject] = useState<QuestionProps>({
    questions: "",
    answers: "",
    questionTy: "",
    options: "",
    id: questionObjectValues.id,
    updatedBy: sessionGetter("uid"),
    status: questionObjectValues.status,
  });
  let flag = false;
  const [lessOptionError, setLessOptionError] = useState<string>("");
  const dispatch = useAppDispatch();

  const handlePush = (e: any) => {
    if (
      e.target.name === "status" &&
      e.target.value === INACTIVE_FLAG &&
      editQuizDetails.maxQuestion === quizList.length
    ) {
      toast.error(`${MIN_QUESTION_ERROR} ${editQuizDetails.maxQuestion}`);
      return;
    }
    setFinalObject({
      ...finalObject,
      [e.target.name]: e.target.value,
    });
  };

  const handleOption = (e: any) => {
    setOptionObject({ ...optionObject, [e.target.name]: e.target.value });
  };

  const deleteOption = async (value: string) => {
    let newArr = optionCount;
    newArr.splice(newArr.indexOf(value), 1);
    setOptionCount(newArr);
    await setOptionObject((current: any) => {
      const copy = { ...current };
      delete copy[value];
      return copy;
    });
    await setAnswerObject((current: any) => {
      const copy = { ...current };
      delete copy[value];
      return copy;
    });
  };

  const isExists = (currentQuestion: any, newQuestion: any) => {
    if(currentQuestion !== quizList[editQuestionIndex]){
    if (newQuestion.replace(/[^\w\s]/gi, "").trim().toLowerCase() === 
    currentQuestion.questions.replace(/[^\w\s]/gi, "").trim().toLowerCase()) {
      flag = true;
      Swal.fire({
        title: SIMILAR_QUES_PRESENT,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(updateQuestion(finalObject));
          setFinalObject({
            questions: "",
            answers: "",
            questionTy: "",
            options: "",
            id: -1,
            updatedBy: sessionGetter("uid"),
            status: "",
          });
          setAnswerObject({});
          setOptionObject({});
          setOptionCount([]);
          setQuestionObjectValues({});
          dispatch(
            getallqueslist({ id: editQuizDetails.id, status: questionFlag })
          );
          dispatch(setEditQuestionIndex(-1));
        }
      });
    }
  }
  };

  useEffect(() => {
    if (apiUpdateQues === API_SUCCEEDED) {
      toast.success(UPDATE_QUESTION_SUCCESS);
      dispatch(
        getallqueslist({
          id: editQuizDetails.id,
          status: questionFlag,
        })
      );
    } else if (apiUpdateQues === API_FAILED) {
      toast.error(UPDATE_QUESTION_ERROR);
    }
  }, [apiUpdateQues]);

  useEffect(() => {
    if (editQuestionIndex === -1) setIsDisable(true);
    else setIsDisable(false);
  }, [editQuestionIndex]);

  useEffect(() => {
    setFinalObject({
      ...finalObject,
      options: JSON.stringify(Object.values(optionObject)),
      answers: JSON.stringify(Object.values(answerObject)),
      questionTy:
        Object.values(answerObject).length > 1 ? "multiple" : "single",
      id: questionObjectValues.id,
    });
  }, [optionObject, answerObject, optionCount]);

  const handleAnswer = async (e: any, index: string) => {
    if (e.target.checked) {
      await setAnswerObject({
        ...answerObject,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.checked === false) {
      await setAnswerObject((current: any) => {
        const copy = { ...current };
        delete copy[index];
        return copy;
      });
    }
  };

  useEffect(() => {
    let arr = questionObjectValues.options;
    setFinalObject({
      ...finalObject,
      questions: questionObjectValues.ques,
      status: questionObjectValues.status,
    });
    setOptionCount([]);
    setOptionObject({});
    setAnswerObject({});
    if (arr) {
      let newArr = JSON.parse(arr);
      newArr.map((option: string, index: any) => {
        let generateKey: string = Math.random().toString();
        setOptionCount((oldArray) => [...oldArray, generateKey]);
        setOptionObject((currentState) => {
          return { ...currentState, [generateKey]: option };
        });
        if (
          JSON.parse(questionObjectValues.ans.replace(/[\n\t]/g, '')).filter(
            (ans: any) => ans.replace(/[\n\t]/g, '') === option.replace(/[\n\t]/g, '')
          ).length !== 0
        ) {
          setAnswerObject((currentState) => {
            return { ...currentState, [generateKey]: option };
          });
        }
        return;
      });
    }
  }, [questionObjectValues]);

  useEffect(() => {
    setQuestionObjectValues({});
  }, [editQuizDetails]);

  return (
    <div>
      <div className="text-center" style={{ margin: "20px" }}>
        <div className="d-inline-block" style={{ float: "left" }}>
          <IoMdArrowRoundBack
            className="delete-button"
            onClick={async () => {
              setShow("FrontPage");
              dispatch(setSelectedAssignNav("showQuiz"));
              let selectedStatus = sessionGetter("QuizStatus");
              const obj = {
                status: selectedStatus,
                lillyId: sessionGetter("uid"),
              };
              await dispatch(getAllQuizzesByOwner(obj));
              await setFinalObject({
                questions: "",
                answers: "",
                questionTy: "",
                options: "",
                id: -1,
                updatedBy: sessionGetter("uid"),
                status: "",
              });
              await dispatch(setEditQuestionIndex(-1));
            }}
          />
        </div>
        <h3 className="text-center d-inline-block title-style" title={allValues.title}>{allValues.title}</h3>

        <div className="d-inline-block" style={{ float: "right" }}>
          <Form.Select
            className="status-style-modify"
            aria-label="Default select example"
            name="status"
            disabled={isDisable}
            value={finalObject.status}
            onChange={(e) => handlePush(e)}
          >
            <option value={ACTIVE_FLAG}>Active</option>
            <option value={INACTIVE_FLAG}>Inactive</option>
          </Form.Select>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <InputGroup className="question-input">
          <InputGroup.Text>Question</InputGroup.Text>
          <Form.Control
            as="textarea"
            disabled={isDisable}
            value={finalObject.questions}
            name="questions"
            onChange={(e) => handlePush(e)}
            style={{resize:'none'}}
            required
          />
        </InputGroup>
      </div>
      <div style={{ overflowY: "auto", maxHeight: "50vh" }}>
        {optionCount.map((option, index) => (
          <div key={`option${index}`} className="d-flex justify-content-center">
            <InputGroup className="option-input">
              <InputGroup.Checkbox
                className="checkbox"
                name={option}
                disabled={isDisable}
                value={Object.values(optionObject)[index]}
                onClick={(e: any) => handleAnswer(e, option.toString())}
                checked={
                  Object.keys(answerObject).indexOf(option.toString()) !== -1
                    ? true
                    : false
                }
              />
              <Form.Control
                name={option.toString()}
                required
                disabled={isDisable}
                value={Object.values(optionObject)[index]}
                onChange={async (e) => {
                  handleOption(e);
                  await setAnswerObject((current: any) => {
                    const copy = { ...current };
                    delete copy[option.toString()];
                    return copy;
                  });
                  if (Object.keys(optionObject).length >= 2) {
                    setLessOptionError("");
                  }
                }}
              />
            </InputGroup>
            <div className="d-flex justify-content-center align-items-center">
              <TiDelete
                className="delete-button"
                onClick={(e: any) => deleteOption(option.toString())}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="error">
        {lessOptionError ? (
          <span style={{ color: "red", marginLeft:"5%" }}>
            {lessOptionError}
          </span>
        ) : null}
      </div>
      <div className="text-center">
        <div className="d-inline-block button-container" >
          <Button
            style={{ backgroundColor: "#582630", border:"none" }}
            onClick={() => {
              setLessOptionError("");
              setOptionCount((oldArray) => [
                ...oldArray,
                new Date().getTime().toString(),
              ]);
            }}
            disabled={
              optionCount.length > Object.values(optionObject).length ||
              Object.values(optionObject).includes("") ||
              isDisable
                ? true
                : false
            }
          >
            Add Option
          </Button>
        </div>

        <div className="d-inline-block button-container">
          <Button
           style={{ backgroundColor: "#582630", border:"none" }}
            onClick={async () => {
              if(finalObject.questions.length === 0)
              {
                setLessOptionError("Question cannot be empty !");
              }
              else
              if (
                Object.keys(optionObject).length >
                Array.from(
                  new Set(Object.values(optionObject).map((item: any) => item))
                ).length
              ) {
                setLessOptionError("Options should be unique !");
              } else {
                if (Object.keys(optionObject).length >= 2) {
                  setLessOptionError("");
                  quizList.map((question: any) =>
                    isExists(question, finalObject.questions)
                  );
                  if (flag === false) {
                    dispatch(updateQuestion(finalObject));
                    setFinalObject({
                      questions: "",
                      answers: "",
                      questionTy: "",
                      options: "",
                      id: -1,
                      updatedBy: sessionGetter("uid"),
                      status: "",
                    });
                    setAnswerObject({});
                    setOptionObject({});
                    setOptionCount([]);
                    setQuestionObjectValues({});
                    dispatch(setEditQuestionIndex(-1));
                  }
                } else {
                  setLessOptionError("Please add atleast 2 options.");
                }
              }
            }}
            disabled={
              optionCount.length > Object.values(optionObject).length ||
              Object.keys(optionObject).length < 1 ||
              Object.values(optionObject).includes("") ||
              Object.keys(answerObject).length < 1
                ? true
                : false
            }
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModifyQuestion;
