import { useEffect, useState } from "react";
import "./UserManagementNav.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import {
  setShowEditType,
  setUserReport,

} from "../../../store/commonActions";
import ResourceManagementNav from "./resourceManagement/ResourceManagementNav";
import UserReportNav from "./userReport/UserReportNav";
import { TbChevronsRight } from "react-icons/tb";


const UserManagementNav = (props: any) => {
  const dispatch = useAppDispatch();


  const [selectedNav, setSelectedNav] = useState<String>("resourceManagement");
  const {showEdit, userReport} = useAppSelector((store)=>store.userNavigation);
  useEffect(()=> {
    if(showEdit === "addUser")
    {
      setSelectedNav("resourceManagement")
    }
  },[showEdit])
  return (

    <div
    className={`container-user ${props.isCollasped ? 'collapsed-user' : ""}`}
      
    >
      {props.isCollasped ?
        <>
        
          <div className="content"><p className="usermanagestyle">USER REPORT</p>
          
           <TbChevronsRight className="content arrow-right "  onClick={()=>{
            props.setIssCollasped(!props.isCollasped)
           }}/>

            </div>
            
        </>


        :
        <>

        <div className="outerdiv-usernav">
         
          <h4
            className="title"
          >
            User Management
          </h4>
        
          <div
            className="innerdiv-usernav"
          >
            <div
              className={
                selectedNav === "resourceManagement" ? "selectedOption1" : "notSelected1"
              }
              onClick={() => {
                setSelectedNav("resourceManagement");
                dispatch(setShowEditType("addUser"));

              }}
            >
              Resource Management
            </div>
            <div
              className={
                selectedNav === "userReport" ? "selectedOption1" : "notSelected1"
              }
              onClick={async() => {
                setSelectedNav("userReport");
                await dispatch(setShowEditType("resourceReport"));
                await dispatch(setUserReport(""));
              }}
            >
              Report
            </div>
          </div>
          {selectedNav === "resourceManagement" ? (
            <ResourceManagementNav />
          ) : (
            ""
          )}
          {selectedNav === "userReport" ? (
            <UserReportNav isCollasped={props.isCollasped} setIssCollasped={props.setIssCollasped}/>

          ) : (
            ""
          )}

</div>
        </>}

    </div>
  );
};

export default UserManagementNav;
