import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FcSearch } from "react-icons/fc";
import { FiDownload } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import "./UserReportNav.css";
import {
  setShowEditType,
  userStatusTableArea,
} from "../../../../store/commonActions";
import {
  setUserReport,
  getSearchUserData,
  resetuser,
} from "../../../../store/slices/userSlice";
import SelectInput from "../../../../utilities/SelectInput/SelectInput";
import {
  API_SUCCEEDED,
  SEARCH_ERROR,
} from "../../../../utilities/helper/constants";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { convertDate } from "../../../../utilities/helper/common";

interface UserProps {
  lillyId: string;
  name: string;
}

const UserReportNav = (props: any) => {
  const dispatch = useAppDispatch();

  const [isSelectedMode, setSelectedMode] = useState<String>("");
  const [usersData, setUsersData] = useState<any>([]);
  const { showEdit, searchApiStatus, searchUserData } = useAppSelector(
    (store) => store.userNavigation
  );
  const columnsHeader = [
    {
      name: "S.No.",
      selector: (row: any, index: any) => index + 1,
      sortable: false,
      cell: (row: any) => <div>{row}</div>,
      wrap: true,
      grow: 2,
    },
    {
      name: "ID",
      selector: "lillyId",
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      wrap: true,
    },
    {
      name: "Quiz Name",
      selector: "activity",
      sortable: true,
      wrap: true,
    },
    {
      name: "State",
      selector: "state",
      sortable: true,
      wrap: true,
    },
    {
      name: "Attempts",
      selector: "attempts",
      sortable: true,
      wrap: true,
    },
    {
      name: "Assigned By",
      selector: "assignedBy",
      sortable: true,
      wrap: true,
    },
    {
      name: "Completion Date",
      selector: "completionDate",
      sortable: true,
      wrap: true,
    },
    {
      name: "Due Date",
      selector: "dueDate",
      sortable: true,
      wrap: true,
    },
    {
      name: "Assigned Date",
      selector: "assignedDate",
      sortable: true,
      wrap: true,
    },
  ];
  const { userStatusAreaList } = useAppSelector(
    (store) => store.quizzesNavigation
  );
  const monthList = [
    "0",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [show, setShow] = useState<Boolean>(false);
  const [userValue, setUserValue] = useState<UserProps>({
    lillyId: "",
    name: "",
  });

  const [selectedReport, setSelectedReport] =
    useState<String>("resourceReport");
  const [userQuizHistory, setUserQuizHistory] = useState<Array<Object>>([{}]);

  useEffect(() => {
    dispatch(getSearchUserData({ searchText: "", page: 1, pageSize: 1000 }));
  }, []);

  useEffect(() => {
    if (showEdit === "resourceReport") {
      setSelectedReport("resourceReport");
    }
  }, [showEdit]);

  useEffect(() => {
    const formattedData = userStatusAreaList.map((item: any) => {
      const formattedItem = { ...item };
      const datekeys = ["completionDate", "assignedDate", "dueDate"];
      datekeys.forEach((key: any) => {
        if (item.hasOwnProperty(key) && typeof item[key] === "string") {
          const localDate = convertDate(item[key]);
          formattedItem[key] = localDate;
        }
      });
      return formattedItem;
    });
    setUserQuizHistory(formattedData);
  }, [userStatusAreaList]);

  useEffect(() => {
    if (searchApiStatus === API_SUCCEEDED) {
      setUsersData(searchUserData);
    }
  }, [searchApiStatus]);

  function getPdf() {
    const doc = new jsPDF("landscape");

    var str =
      "Name : " +
      userValue.name +
      "\nLilly ID : " +
      userValue.lillyId +
      "\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tReport Date : " +
      new Date().getDate() +
      "-" +
      monthList[new Date().getMonth() + 1] +
      "-" +
      new Date().getFullYear() +
      "  " +
      new Date().toLocaleTimeString();
    doc.setDrawColor(40, 127, 186);
    doc.setFillColor(40, 127, 186);
    doc.rect(0, 15, 300, 15, "FD");
    doc.setFont("bold");
    doc.setTextColor(255, 255, 255);
    doc.text("iSelect   User   Report", 120, 24);
    doc.setFontSize(14);
    doc.setFont("Arial");
    doc.setTextColor(40, 127, 186);
    doc.text("QUIZ HISTORY", 15, 70);
    autoTable(doc.text(str, 10, 50), {
      head: [columnsHeader.map((column) => column.name)],
      margin: { top: 75 },
      body: userQuizHistory.map((row: any, index: any) =>
        columnsHeader.map((column: any) => {
          return column.name !== "S.No." ? row[column.selector] : index + 1;
        })
      ),
      headStyles: { halign: "justify" },
      columnStyles: {
        1: {
          cellWidth: 30,
        },
        2: {
          cellWidth: 40,
        },
        3: {
          cellWidth: 50,
        },
      },
    });

    doc.save("User_Report.pdf");
  }

  return (
    <>
      <div className="reportNav-outerdiv">
        <div className="reports-div" style={{ height: "40%" }}>
          <Card
            className={
              selectedReport === "resourceReport"
                ? "selectedModeReport"
                : "notSelectedModeReport"
            }
            onClick={async () => {
              // dispatch(resetuser());
              await setSelectedReport("resourceReport");
              await setUserValue({ lillyId: "", name: "" });
              await dispatch(setUserReport(""));
              setSelectedMode("");
              setShow(false);
              await dispatch(setShowEditType("resourceReport"));
            }}
          >
            Resource Report
          </Card>
          <Card
            className={
              selectedReport === "userReport"
                ? "selectedModeReport"
                : "notSelectedModeReport"
            }
            onClick={async() => {
              setSelectedReport("userReport");
              dispatch(setShowEditType("userReport"));
            }}
          >
            User Report
          </Card>
        </div>
        {showEdit === "userReport" ? (
          <div className="reportNav-innerdiv">
            <div
              style={{
                display: "inline-block",
                margin: "0 1%",
                width: "250px",
              }}
            >
              <SelectInput
                allValues={userValue}
                setAllValues={setUserValue}
                usersData={usersData}
                title={"Name"}
                setUsersData={setUsersData}
              />
            </div>

            <div className="reportNav-innerdiv1">
              <Button
                className="report-user"
                onClick={async () => {
                  if (
                    userValue.lillyId === "" ||
                    userValue.name === "" ||
                    (userValue.lillyId === "" && userValue.name === "")
                  ) {
                    await setShow(false);
                    toast.error(SEARCH_ERROR);
                  } else {
                    await setShow(true);
                    setSelectedMode("hrp");
                    await dispatch(setUserReport("hrp"));
                    await dispatch(
                      userStatusTableArea({ lillyId: userValue.lillyId ,userManageFlag: true })
                    );
                  }
                }}
              >
                <FcSearch className="reportNav-icon" />
                Search
              </Button>
              <Button
                className="report-user"
                onClick={async () => {
                  await setShow(false);
                  await setUserValue({ lillyId: "", name: "" });
                  await dispatch(setUserReport(""));
                }}
              >
                <MdClose className="reportNav-icon" />
                Reset
              </Button>
            </div>
          </div>
        ) : null}

        {show && showEdit === "userReport" ? (
          <>
            <div className="reportNav-cardDiv">
              <div className="reportNav-cardDiv1">
                <Card
                  className={
                    isSelectedMode === "hrp"
                      ? "selectedMode1"
                      : "notSelectedMode1"
                  }
                  onClick={() => {
                    setSelectedMode("hrp");
                    dispatch(setUserReport("hrp"));
                  }}
                >
                  HRP
                </Card>

                <Card
                  className={
                    isSelectedMode === "checklist"
                      ? "selectedMode1"
                      : "notSelectedMode1"
                  }
                  onClick={() => {
                    setSelectedMode("checklist");
                    dispatch(setUserReport("checklist"));
                  }}
                >
                  Checklist
                </Card>

                <Card
                  className={
                    isSelectedMode === "quizHistory"
                      ? "selectedMode1"
                      : "notSelectedMode1"
                  }
                  onClick={() => {
                    setSelectedMode("quizHistory");
                    dispatch(setUserReport("quizHistory"));
                  }}
                >
                  Quiz
                </Card>
              </div>
            </div>
            <div className="report-downloadDiv">
              <Button
                className="report-user"
                style={{ marginLeft: "30%" }}
                onClick={() => getPdf()}
              >
                <FiDownload className="report-downloadIcon" />
                Download PDF
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
export default UserReportNav;
