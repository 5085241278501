import React from "react";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { convertDate } from "../../../../utilities/helper/common";
import DataTable from "react-data-table-component";

const QuizHistory = () => {
  const {userStatusAreaList} = useAppSelector((store) => store.quizzesNavigation)
  const [userQuizHistory, setUserQuizHistory] = useState<any>([]);


  const columnsHeader = [
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
      wrap: true,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Assignee ID",
      selector: (row: any) => row.lillyId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Quiz Name",
      selector: (row: any) => row.activity,
      sortable: true,
      wrap: true,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "State",
      selector: (row: any) => row.state,
      sortable: true,
      wrap: true,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Attempts",
      selector: (row: any) => `${row.attempts}/${row.maxAttempt}`,
      sortable: true,
      wrap: true,
    },
    {
      name: "Assigned By",
      selector: (row: any) => row.assignedBy,
      sortable: true,
      wrap: true,
    },
    {
      name: "Due Date",
      selector: (row: any) => row.dueDate,
      cell: (row: any) => convertDate(row.dueDate),
      sortable: true,
      wrap: true,
    },
  ];
  
  useEffect(()=> {
    setUserQuizHistory(userStatusAreaList)
  },[])

  return (
    <>
    <h4
        style={{
          fontFamily: "Merriweather Sans",
          fontWeight: "bold",
          margin: "20px",
        }}
      >
     Quiz History 
      </h4>
    <div
          style={{
            height: "80vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginLeft: "2%",
            marginRight:"2%"
          }}
        >

          <DataTable
                columns={columnsHeader}
                data={userQuizHistory}
                fixedHeader
                fixedHeaderScrollHeight="390px"
                highlightOnHover
                pagination
                striped
              />

        </div>
    
    </>
  );
};

export default QuizHistory;
