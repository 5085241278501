import { useEffect, useState } from "react";
import "./Quiz.css";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import { scoreAdd, setInitialScore } from "../../../../store/commonActions";
import { API_SUCCEEDED } from "../../../../utilities/helper/constants";
import { sessionGetter } from "../../../../utilities/helper/common";

//Timer Logic
const padTime = (time: any) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};
const format = (time: any) => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60);
  // Get the seconds left after converting minutes
  const seconds = time % 60;
  //Return combined values as string in format mm:ss
  return `${minutes}:${padTime(seconds)}`;
};

const Quiz = (props: any) => {
  const dispatch = useAppDispatch();
  const { count, setCount, displayQuizNo, setDisplayQuizNo, submitQuiz } =
    props;
  const {
    questionDetailsList,
    assignedQuizzesDetails,
    scoreApiStatus,
    updateId,
  } = useAppSelector((store) => store.learnerNavigation);
  const [counter, setCounter] = useState(90*questionDetailsList.questionDetails.length);
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    if (submitQuiz) {
      navigate("/Result");
    }
  }, [submitQuiz]);
  
  useEffect(() => {
    if (questionList.length > 0) {
      let obj = {
        addscore: {
          lillyId: sessionGetter("uid"),
          title: assignedQuizzesDetails.title,
          area: assignedQuizzesDetails.area,
          subarea: assignedQuizzesDetails.subarea,
          quizMasterId: assignedQuizzesDetails.quizmasterid,
          masterSubareaId: assignedQuizzesDetails.masterSubareaId,
          quizAssignTableId: assignedQuizzesDetails.id,
          attemptDate: assignedQuizzesDetails.attemptDate,
        },
        testData: {
          lillyId: sessionGetter("uid"),
          quizmasterid: assignedQuizzesDetails.quizmasterid,
          totalQuestion: questionList.length,
          quizdetails: [],
        },
      };
      dispatch(setInitialScore(obj));
    }
  }, [questionList]);

  //Timer Logic
  useEffect(() => {
    let timer: any;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    } else {
      onClickNext();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);
  
  useEffect(() => {
    setQuestionList(questionDetailsList.questionDetails);
  }, []);

  const navigate = useNavigate();
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<string | null>(
    null
  );
  // const [answerNav, setAnswerNav] = useState<string>("");
  const [answeredData, setAnsweredData] = useState<any>({});

  const { questions, options, question_id, questiontype } =
    questionDetailsList.questionDetails[activeQuestion];

  const addLeadingZero = (number: any) => (number > 9 ? number : `0${number}`);
  useEffect(() => {
    setActiveQuestion(displayQuizNo);
  }, [displayQuizNo]);
  useEffect(() => {
    setDisplayQuizNo(activeQuestion);
    // if (question_id in answeredData) {
      // setAnswerNav(answeredData[question_id]);
    // }
  }, [activeQuestion]);
  useEffect(() => {
    if (scoreApiStatus === API_SUCCEEDED) {
      navigate("/Result");
    }
  }, [scoreApiStatus]);

  const onClickPrevious = () => {
    setSelectedAnswerIndex(null);
    if (activeQuestion === 0) {
      setActiveQuestion(activeQuestion);
    } else setActiveQuestion((prev) => prev - 1);
  };

  const onClickNext = () => {
    setSelectedAnswerIndex(null);
    if (count <= activeQuestion) {
      setCount(count + 1);
    }

    if (activeQuestion !== questionList.length - 1 && counter !== 0) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      let quizdetails1 = [];
      let obj1 = {};
      for (const [key, value] of Object.entries(answeredData)) {
        obj1 = {
          answers: value,
          id: key,
        };
        quizdetails1.push(obj1);
      }

      let obj = {
        addscore: {
          lillyId: sessionGetter("uid"),
          title: assignedQuizzesDetails.title,
          area: assignedQuizzesDetails.area,
          subarea: assignedQuizzesDetails.subarea,
          quizMasterId: assignedQuizzesDetails.quizmasterid,
          masterSubareaId: assignedQuizzesDetails.masterSubareaId,
          quizAssignTableId: assignedQuizzesDetails.id,
          attemptDate: assignedQuizzesDetails.attemptDate,
          updateId: updateId,
        },
        testData: {
          lillyId: sessionGetter("uid"),
          quizmasterid: assignedQuizzesDetails.quizmasterid,
          totalQuestion: questionList.length,
          quizdetails: quizdetails1,
        },
      };
      dispatch(scoreAdd(obj));
    }
  };

  const onAnswerSelected = (answer: any, index: any, e: any) => {
    setAnsweredData({ ...answeredData, [e.target.name]: [e.target.value] });

    setSelectedAnswerIndex(answer);
    // setAnswerNav("");
  };
  const onAnswerSelectedMultiple = (answer: any, index: any, e: any) => {
    let arr: any = [];
    if (e.target.checked) {
      if (answeredData[e.target.name]) {
        arr = answeredData[e.target.name];
        arr.push(answer);
      } else {
        arr.push(answer);
      }
    } else if (e.target.checked === false) {
      arr = answeredData[e.target.name];
      arr = arr.filter((val: any) => val !== answer);
    }

    setAnsweredData({ ...answeredData, [e.target.name]: arr });

    setSelectedAnswerIndex(answer);
    // setAnswerNav("");
  };

  const handleContextMenu = (e: any) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="quiz-container" onContextMenu={handleContextMenu}>
        <div>
          <div>
            <span className="active-question-no">
              {addLeadingZero(activeQuestion + 1)}
            </span>
            <span className="total-question">
              /{addLeadingZero(questionList.length)}
            </span>
            <span className="timer">
              {counter === 0 ? (
                `Time Over`
              ) : (
                <span>Time Left: {format(counter)}</span>
              )}
            </span>
          </div>
          <div className="test-question-divstyle">
          <strong>{questions}</strong>
          </div>
          <div className="test-option-divstyle">
          {questiontype === "single" ? (
            <ul>
              {JSON.parse(options).map((answer: any, index: any) => (
                <label htmlFor={answer} className="options" key={answer}>
                  <li
                     className={
                      (
                        answeredData[question_id]
                          ? answeredData[question_id].includes(answer) &&
                            answeredData[question_id].length !== 0
                          : false
                      )
                        ? "selected-answer"
                        : ""
                    }
                  >
                    <Form.Check
                      name={question_id}
                      value={answer}
                      type={"radio"}
                      label={answer}
                      id={answer}
                      onChange={(e) => onAnswerSelected(answer, index, e)}
                      checked={
                        answeredData[question_id]
                          ? answeredData[question_id].includes(answer) &&
                            answeredData[question_id].length !== 0
                          : false
                      }
                    />
                  </li>
                </label>
              ))}
            </ul>
          ) : (
            <ul>
              {JSON.parse(options).map((answer: any, index: any) => (
                <label htmlFor={answer} className="options" key={answer}>
                  <li
                    className={
                      (
                        answeredData[question_id]
                          ? answeredData[question_id].includes(answer) &&
                            answeredData[question_id].length !== 0
                          : false
                      )
                        ? "selected-answer"
                        : ""
                    }
                  >
                    <Form.Check
                      name={question_id}
                      value={answer}
                      type={"checkbox"}
                      label={answer}
                      id={answer}
                      onChange={(e) =>
                        onAnswerSelectedMultiple(answer, index, e)
                      }
                      checked={
                        answeredData[question_id]
                          ? answeredData[question_id].includes(answer) &&
                            answeredData[question_id].length !== 0
                          : false
                      }
                    />
                  </li>
                </label>
              ))}
            </ul>
          )}
          </div>

          <div>
            <button
              className="previous-button"
              disabled={activeQuestion === 0}
              onClick={onClickPrevious}
            >
              Previous
            </button>
            <button
              className="next-button"
              onClick={onClickNext}
              disabled={
                
                selectedAnswerIndex === null && !(activeQuestion < count) 
              }
            >
              {activeQuestion === questionList.length - 1 ? "Finish" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
