import { useEffect, useState } from "react";
import LearnersContent from "./LearnersContent";
import LearnersNav from "./LearnersNav";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { userquizList } from "../../../store/commonActions";
import { sessionGetter } from "../../../utilities/helper/common";

const Learners = () => {
  const [show, setShow] = useState<Boolean>(false);
  const dispatch = useAppDispatch();
  const { selectedQuizPath } = useAppSelector(
    (store) => store.learnerNavigation
  );

  useEffect(()=> {
    dispatch(userquizList(sessionGetter("uid")));
  },[])
  useEffect(() => {
    setShow(false);
  }, [selectedQuizPath]);
  return (
    <>
      <div className="content-area">
        <LearnersNav show={show} setShow={setShow} />
        <LearnersContent show={show} setShow={setShow} />
      </div>
    </>
  );
};
export default Learners;
