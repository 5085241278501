import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { IoMdAddCircle, IoMdArrowRoundBack } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import AreaSubareaModal from "./AreaSubareaModal";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { getAreaSubarea, resetAreaSubarea, resetStatus } from "../../../store/slices/areaSubareaSlice";
import {
  ACTIVE_FLAG,
  API_LOADING,
  API_SUCCEEDED,
  INACTIVE_FLAG,
  RECORD_CREATED,
  RECORD_UPDATED,
} from "../../../utilities/helper/constants";
import { Search } from "../../../utilities/helper/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { resetuser, setModule } from "../../../store/commonActions";
import Loader from "../../../utilities/Loader/Loader";
import DataTable from "react-data-table-component";

const AreaSubarea = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { apiGetStatus, apiCreateStatus, apiUpdateStatus, areaSubareaData } =
    useAppSelector((store) => store.areaSubareaNavigation);
  const [show, setShow] = useState<Boolean>(false);
  const [data, setData] = useState<Array<any>>([]);
  const [filterData, setFilterData] = useState<Array<any>>([]);
  const [edit, setEdit] = useState<Object>({});
  const [editBool, setEditBool] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>(ACTIVE_FLAG);
  const [searchInput, setSearchInput] = useState<any>("");
  const[toastFlag,setToastFlag]= useState<Boolean>(false);
  const [searchParam] = useState(["area"]);

  useEffect(() => {
    resetAreaSubarea();
    dispatch(getAreaSubarea({ status: ACTIVE_FLAG }));
    dispatch(setModule("UserManage"));
  }, []);
  useEffect(() => {
    if (searchInput.length <= 1) {
      setFilterData(data);
    } else setFilterData(Search(data, searchInput, searchParam));
  }, [searchInput]);
  useEffect(() => {
    if (apiGetStatus === API_SUCCEEDED) {
      setData(areaSubareaData);
    }
  }, [apiGetStatus]);
  useEffect(() => {
    dispatch(getAreaSubarea({ status: ACTIVE_FLAG }));
    if (apiCreateStatus === API_SUCCEEDED) {
      setData(areaSubareaData);
      setToastFlag(true)
      toast.success(RECORD_CREATED);
    }
    dispatch(resetStatus());
  }, [apiCreateStatus]);
  
  useEffect(() => {
    dispatch(getAreaSubarea({ status: ACTIVE_FLAG }));
    if (apiUpdateStatus === API_SUCCEEDED) {
      setData(areaSubareaData);
      toast.success(RECORD_UPDATED);
    }
    dispatch(resetStatus());
  }, [apiUpdateStatus]);
  console.log("apiUpdateStatus",apiUpdateStatus)
  console.log("apiCreateStatus",apiCreateStatus)
  useEffect(()=> {
    setFilterData(data);
  },[data])
  useEffect(() => {
    dispatch(getAreaSubarea({ status: status }));
    if (apiUpdateStatus === API_SUCCEEDED) {
      setData(areaSubareaData);
    }
    setSearchInput("");
  }, [status]);

  const columnsHeader = [
    {
      name: "Action",
      cell: (row: any) => (
        <div style={{ fontSize: "12px", margin: "4px", width: "30px" }}>
          <Button style={{ background: "none", border: "none" }}>
            <MdEdit
              style={{
                fontSize: "20px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => {
                setShow(true);
                setEdit(row);
                setEditBool(true);
              }}
            />
          </Button>
        </div>
      ),

      sortable: true,
      wrap: true,
    },
    {
      name: "Sub Tower",
      selector: (row: any) => row.area,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Functional Area",
      selector: (row: any) => row.subarea,
      sortable: true,
      wrap: true,
      grow: 2,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Status",
      selector: (row: any) => row.deleteFlag,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IoMdArrowRoundBack
          className="delete-button"
          onClick={() => {
            navigate("/usermanage");
            dispatch(setModule("UserManage"));
            dispatch(resetuser());
          }}
        />
        <h4
          style={{
            fontFamily: "Merriweather Sans",
            fontWeight: "bold",
            margin: "20px",
            width: "77%",
          }}
        >
          <div className="d-inline-block" style={{ float: "left" }}>
            Manage Area/Subarea
          </div>
        </h4>
      </div>
      {show ? (
        <AreaSubareaModal
          show={show}
          setShow={setShow}
          setEdit={setEdit}
          edit={edit}
          editBool={editBool}
          setEditBool={setEditBool}
          status={status}
          setStatus={setStatus}
        />
      ) : null}
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          height: "80vh",
          width: "70vw",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "77%",
          }}
        >
          <div style={{ width: "30%", margin: "2%" }}>
            <Form.Control
              type="search"
              placeholder="Search"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div style={{ width: "30%", margin: "2%" }}>
            <Form.Select
              aria-label="Default select example"
              name="status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value={ACTIVE_FLAG}>Active</option>
              <option value={INACTIVE_FLAG}>Inactive</option>
            </Form.Select>
          </div>
          <div style={{ margin: "0.5%", float: "right" }}>
            <Button
              variant="secondary"
              style={{
                margin: "10px",
                backgroundColor: "#582630",
                height: "40px",
                float: "right",
              }}
              onClick={() => {
                setShow(true);
              }}
            >
              <IoMdAddCircle
                style={{
                  fontSize: "25px",
                  marginLeft: "5px",
                  color: "white",
                  cursor: "pointer",
                  marginRight: "5px",
                }}
              />
              Add
            </Button>
          </div>
        </div>
        {apiGetStatus === API_LOADING?<><Loader/><Loader/></>:<div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            overflow: "auto",
            height: "80vh",
            width: "70vw",
            margin: "auto",
          }}
        >
          {Search(data, searchInput, searchParam).map((item: any, key: any) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px",
                    height: "80px",
                    width: "80%",
                  }}
                  key={key}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#582630",
                      margin: "10px",
                      height: "50vh",
                      width: "100%",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "7px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          margin: "4px",
                          textAlign: "center",
                        }}
                      >
                        Action
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          margin: "4px",
                          width: "150px",
                          textAlign: "center",
                        }}
                      >
                        <MdEdit
                          style={{
                            fontSize: "20px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShow(true);
                            setEdit(item);
                            setEditBool(true);
                          }}
                        />
                      </div>
                    </div>
    
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "7px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          margin: "4px",
                          textAlign: "center",
                        }}
                      >
                        Area
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          margin: "4px",
                          width: "150px",
                          textAlign: "center",
                        }}
                      >
                        {item.area}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "7px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          margin: "4px",
                          textAlign: "center",
                          width: "250px",
                        }}
                      >
                        Subarea
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          margin: "4px",
                          width: "250px",
                          textAlign: "center",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        title={item.subarea}
                      >
                        {item.subarea}
                      </div>
                    </div>
    
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "7px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          textAlign: "center",
                          margin: "4px",
                        }}
                      >
                        Status
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          margin: "4px",
                          width: "150px",
                          textAlign: "center",
                        }}
                      >
                        {item.deleteFlag}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          
        </div>}
        
      </div> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          height: "15vh",
          width: "70vw",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "77%",
          }}
        >
          <div style={{ width: "30%", margin: "2%" }}>
            <Form.Control
              type="search"
              placeholder="Search"
              value = {searchInput}
              onChange={async (e) => {
                await setSearchInput(e.target.value);
              }}
            />
          </div>
          <div style={{ width: "30%", margin: "2%" }}>
            <Form.Select
              aria-label="Default select example"
              name="status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value={ACTIVE_FLAG}>Active</option>
              <option value={INACTIVE_FLAG}>Inactive</option>
            </Form.Select>
          </div>
          <div style={{ margin: "0.5%", float: "right" }}>
            <Button
              variant="secondary"
              style={{
                margin: "10px",
                backgroundColor: "#582630",
                height: "40px",
                float: "right",
              }}
              onClick={() => {
                setShow(true);
              }}
            >
              <IoMdAddCircle
                style={{
                  fontSize: "25px",
                  marginLeft: "5px",
                  color: "white",
                  cursor: "pointer",
                  marginRight: "5px",
                }}
              />
              Add
            </Button>
          </div>
        </div>
      </div>
      {apiGetStatus === API_LOADING?<><Loader/></>:<div
        style={{
          height: "62vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          margin: "auto",
          marginTop: "1%",
          width: "60vw",
        }}
      >
        <DataTable
          columns={columnsHeader}
          data={filterData}
          fixedHeader
          fixedHeaderScrollHeight="390px"
          highlightOnHover
          pagination
          striped
          subHeaderWrap
        />
      </div>}
      
    </div>
  );
};

export default AreaSubarea;
