import { useEffect, useState } from 'react'

const Timer = () => {
    const [seconds, setSeconds] = useState<number>(10);
    
    useEffect(()=> {
        const timer = setInterval(()=> {
            if(seconds>0)
            {
                setSeconds(seconds-1);
            }
            else
            {
                clearInterval(timer);
            }
        },1000);

        return () => {
            clearInterval(timer);
        };
    }, [seconds]);
  return (
    <div>
      <h5>{seconds} seconds remaining</h5>
      <h6>Your test will be automatically submitted in 10 Seconds if you do not enter full screen</h6>
    </div>
  )
}

export default Timer;
