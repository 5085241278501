import { useEffect, useState } from "react";
import "./LearnersNav.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import {
  setSelectedQuizPath,
  setQuizDetails,
} from "../../../store/commonActions";
import QuizDataDetails from "./quizStatus/QuizDataDetails";
import { API_SUCCEEDED, FAIL_FLAG, NOT_ATTEMPTED_FLAG, PASS_FLAG } from "../../../utilities/helper/constants";

const LearnersNav = (props: any) => {
  const { show, setShow } = props;
  const dispatch = useAppDispatch();
  const handleClick = (quizSelected: String) => {
    dispatch(setSelectedQuizPath(quizSelected));
  };
  const [userQuizDetails, setUserQuizDetails] = useState<any>({});
  const [quizzesComp, setQuizzesComp] = useState<any>([]);
  const [quizzesInComp, setQuizzesInComp] = useState<any>([]);
  const [quizzesAll, setQuizzesAll] = useState<any>([]);

  const { selectedQuizPath, userQuizzesDetails, apiStatus } = useAppSelector(
    (store) => store.learnerNavigation
  );

  useEffect(() => {
    if (apiStatus === API_SUCCEEDED) {
      setUserQuizDetails(userQuizzesDetails);
    }
  }, [apiStatus]);

  useEffect(() => {
    if (Object.keys(userQuizDetails).length !== 0) {
      let comp = userQuizDetails.filter((val: any) => val.state === PASS_FLAG);
      let inComp = userQuizDetails.filter(
        (val: any) => val.state === FAIL_FLAG || val.state === NOT_ATTEMPTED_FLAG
      );
      setQuizzesComp(comp);
      setQuizzesInComp(inComp);
      setQuizzesAll(userQuizDetails);      
    }
  }, [userQuizDetails]);

  return (
    <>
      <div className="nav-outerdiv">
        <h4 className="title">Learners</h4>
        <div className="learnerNav-innerdiv">
          <div
            className={
              selectedQuizPath === "all" ? "selectedOption" : "notSelected"
            }
            onClick={async() => {
              handleClick("all");
              if (quizzesAll.length !== 0) {
                await dispatch(setQuizDetails({}));
                dispatch(setQuizDetails(quizzesAll[0]));
              }
            }}
          >
            All
          </div>
          <div
            className={
              selectedQuizPath === "unattempted"
                ? "selectedOption"
                : "notSelected"
            }
            onClick={async() => {
              handleClick("unattempted");
              if (quizzesInComp.length !== 0) {
                await dispatch(setQuizDetails({}));
                dispatch(setQuizDetails(quizzesInComp[0]));
              }
            }}
          >
            Incomplete
          </div>
          <div
            className={
              selectedQuizPath === "completed"
                ? "selectedOption"
                : "notSelected"
            }
            onClick={async() => {
              handleClick("completed");
              if (quizzesComp.length !== 0) {
                await dispatch(setQuizDetails({}));
                dispatch(setQuizDetails(quizzesComp[0]));
              }
            }}
          >
            Completed
          </div>
        </div>
        <div className="nav-height">
          <QuizDataDetails
            quizzes={
              selectedQuizPath === "all"
                ? quizzesAll
                : selectedQuizPath === "unattempted"
                ? quizzesInComp
                : selectedQuizPath === "completed"
                ? quizzesComp
                : []
            }
            show={show}
            setShow={setShow}
            selectedQuizPath={selectedQuizPath}
            apiStatus={apiStatus}
          />
        </div>       
      </div>
    </>
  );
};

export default LearnersNav;
