import { useAppSelector } from "../../../../hooks/redux-hooks";
import "./TakeQuizNav.css";

const TakeQuizNav = (props: any) => {
  const { questionDetailsList } = useAppSelector(
    (store) => store.learnerNavigation
  );
  
  const noOfQues = questionDetailsList.questionDetails.length;
  const render: any = [];
  const renderGreen: any = [];
  for (let i = 1; i <= props.count; i++) {
    renderGreen.push(i);
  }

  for (let i = props.count + 1; i <= noOfQues; i++) {
    render.push(i);
  }
  return (
    <div className="test-outerdiv">
      <h4 className="test-title" title={questionDetailsList.title}>{questionDetailsList.title}</h4>
      <div>
        <div className="test-innerdiv1">
          <div className="test-innerdiv2">
            <div className="green-icon"></div>
            <div>Attempted</div>
          </div>
          <div className="test-innerdiv2">
            <div className="grey-icon"></div>
            <div>Unattempted</div>
          </div>
          <div className="test-innerdiv2">
            <div className="blue-icon"></div>
            <div>Current Question</div>
          </div>
        </div>

        <div
          className="test-innerdiv3"
        >
          {renderGreen.map((answer: any, index: any) => (
            <div
              className={
                index === props.displayQuizNo ? "blue-render" : "green-render"
              }
              onClick={() => {
                props.setDisplayQuizNo(index);
              }}
              key={index}
            >
              {index + 1}
            </div>
          ))}
          {render.map((value: any, index: any) => (
            <div
              className={
                value === props.displayQuizNo + 1
                  ? "blue-render"
                  : "grey-render"
              }
              onClick={() => {
                if (index === 0) {
                  props.setDisplayQuizNo(value - 1);
                }
              }}
              key={index}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TakeQuizNav;
