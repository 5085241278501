export const checklist = 
  {
    checklistUsers:[
    {
      id: 42,
      lillyId: "C394859",
      status: "Not Started",
      createdAt: "2020-05-08T10:12:40.566Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
    {
      id: 41,
      lillyId: "C293836",
      status: "Not Started",
      createdAt: "2020-05-04T12:32:18.198Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
    {
      id: 8,
      lillyId: "C987456",
      status: "Complete",
      createdAt: "2020-05-01T11:18:38.606Z",
      updatedAt: "2020-05-01T11:42:50.547Z",
      createdBy: "SYSTEM",
      updatedBy: "V0X5302",
    },
    {
      id: 7,
      lillyId: "C280912",
      status: "Not Started",
      createdAt: "2020-05-01T10:55:57.210Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
    {
      id: 6,
      lillyId: "C223456",
      status: "Not Started",
      createdAt: "2020-05-01T09:56:09.945Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
    {
      id: 5,
      lillyId: "C123456",
      status: "Not Started",
      createdAt: "2020-05-01T09:53:49.356Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
    {
      id: 4,
      lillyId: "V9X6789",
      status: "Not Started",
      createdAt: "2020-04-23T14:06:50.969Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
    {
      id: 3,
      lillyId: "C2938475",
      status: "Not Started",
      createdAt: "2020-04-23T13:41:26.284Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
    {
      id: 2,
      lillyId: "C234657",
      status: "Not Started",
      createdAt: "2020-04-23T08:31:29.770Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
    {
      id: 1,
      lillyId: "C283747",
      status: "Not Started",
      createdAt: "2020-04-21T07:05:24.639Z",
      updatedAt: null,
      createdBy: "SYSTEM",
      updatedBy: null,
    },
  ],
    checklistFields: [
  {
    id: 1,
    checklistName: "VDI Client Installation",
    where:"GHD->TT",
    responsibility:"Associate/Area Leads",
    timeline:"ASAP after machine allocation",
    status:"complete"
  },
  {
    id: 2,
    checklistName: "Role Tagging in iCALMS and Competency Assignment",
    where:"iCALMS",
    responsibility:"Area Leads",
    timeline:"Once WON allocation is done"

  },
  {
    id: 3,
    checklistName:
      "LRL Onboarding Request, VDI Assignment, Success Factor Account Creation",
      where:"Service Now->TT",
    responsibility:"Area Leads/Fipnet Team",
    timeline:"Same day when Lilly ID arrives"
  },
  {
    id: 4,
    checklistName: "ITP Assignment",
    where:"Area Lead's Machine",
    responsibility:"Area Leads/ITP Coordinato",
    timeline:"Same day when Lilly ID arrives"
  },
  {
    id: 5,
    checklistName: "My Password Registration",
    where:"Q&A update for resetting password",
    responsibility:"Associate/Area Leads",
    timeline:"Same day when Lilly ID arrives"
  },
  {
    id: 6,
    checklistName: "Service Now Trainings",
    where:"Training Site/SOP ITP",
    responsibility:"Associate/Area Leads",
    timeline:"Same day when Lilly ID arrives"
  },
  {
    id: 7,
    checklistName: "LRL Collab Site Access Request",
    where:"LRL Collab Site",
    responsibility:"Associate/Area Leads",
    timeline:"Within 2 days when Lilly ID arrives"
  },
  {
    id: 8,
    checklistName: "Request for Inclusion in Lilly & LRL Email Groups",
    where:"Client E-mail",
    responsibility:"Area Leads/Group Owners",
    timeline:"Within 2 days when Lilly ID arrives"
  },
  {
    id: 9,
    checklistName: "Lilly Profile Update",
    where:"LillyNow",
    responsibility:"Associate/Area Leads",
    timeline:"Within 2 days when Lilly ID arrives"
  },
  {
    id: 10,
    checklistName: "Lilly Mandatory Trainings (ITP) Completed",
    where:"LillyNow",
    responsibility:"Associate/Area Leads",
    timeline:"ASAP after VDI access arrives"
  },
  {
    id: 11,
    checklistName: "ServiceNow Queue Access Granted",
    where:"ServiceNow",
    responsibility:"Associate/Area Leads",
    timeline:"After completing the Lilly trainings"
  },
  {
    id: 12,
    checklistName: "Application Access Request and TT",
    where:"ServiceNow->TT",
    responsibility:"Associate/Area Leads",
    timeline:"After completing the Lilly trainings"
  },
  {
    id: 13,
    checklistName: "Regulus Access",
    where:"From Quality",
    responsibility:"Associate/Area Leads",
    timeline:"After completing the Lilly trainings"
  },
  {
    id: 14,
    checklistName: "Trainings by ITQS",
    where:"LillyODC",
    responsibility:"Leads",
    timeline:"Monthly Activity"
  },
  {
    id: 15,
    checklistName: "Trainings by LRL Folks - QC, TFS, SNOW, RIO, ITIL",
    where:"LillyODC",
    responsibility:"Tulika Pandey",
    timeline:"Monthly Activity"
  },
    ]
  };
