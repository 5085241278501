import * as FileSaver from 'file-saver';
import Api from './Api';

export const getAllUser = async () => {
  try {
  const response = await Api.get(
    `/usermanage/getAllUser`
  );
  
  return response.data;
} catch (error: any) {
  throw error;
}
};


export const getAllAreaSubarea = async (data: any) => {
  try {
  const response = await Api.post(
    `/areasubarea/getAllAreaSubarea`, data
  );
  return response.data;
} catch (error: any) {
   throw error;
}
};
export const getAllSubarea = async (data: any) => {
  try {
  const response = await Api.post(
    `/areasubarea/getSubarea`, data
  );
  return response.data;
} catch (error: any) {
   throw error;
}
};

export const getAllRoles = async () => {
  try {
  const response = await Api.get(
    `/role/getAllRoles`
  );
  
  return response.data;
} catch (error: any) {
   throw error;
}
};

export const createNewUser = async (data: any) => {
  try {
    const response: any = await Api.post(
      `/usermanage/createUser`,
      data
    );

    return response.data;
  } catch (error: any) {
     throw error;
  }
};

export const updateCurrentUser = async (data: any) => {
  try {
    const response: any = await Api.post(
      `/usermanage/updateUser`,
      data
    );

    return response.data;
  } catch (error: any) {
     throw error;
  }
};

export const getAllUserAudit = async () => {
  try {
  const response = await Api.post(
    `/useraudit/alluseraudit`
  );
  
  return response.data;
} catch (error: any) {
  throw error;
}
};

export const getAllUserAuditDetails = async (data: any) => {
  try {
  const response = await Api.post(
    `/useraudit/alluseraudit`, data
  );
  
  return response.data;
} catch (error: any) {
  throw error;
}
};

export const getUsers = async (data: any) => {
  try {
  const response = await Api.post(
    `/usermanage/searchUser`, data
  );
  
  return response.data;
} catch (error: any) {
  throw error;
}
};

export const excelApiSample = async (data: any) => {
  try {
  const response = await Api.post(
    `/usermanage/excelApiSample`, data,{
      responseType: "arraybuffer"
    }
  );
  let lob = new Blob([response.data]);
  FileSaver.saveAs(lob, 'SAMPLE_FILE.xlsx');
  return response.data;
} catch (error: any) {
  throw error;
}
};

export const excelAllUserData = async (data: any) => {
  try {
  const response = await Api.post(
    `/usermanage/excelAllUserData`, data,{
      responseType: "arraybuffer"
    }
  );
  let lob = new Blob([response.data]);
  FileSaver.saveAs(lob, 'ALL_USERS_DATA_'+((new Date()).toString())+'.xlsx');
  return response.data;
} catch (error: any) {
  throw error;
}
};

export const excelUserCreateorUpdate = async (data: any) => {
  try {
  const response = await Api.post(
    `/usermanage/excelusercreateorupdate`, data,
    {
      responseType: "arraybuffer"
    }
  );
  let lob = new Blob([response.data]);
  FileSaver.saveAs(lob, 'UPDATED_USERS_'+((new Date()).toString())+'.xlsx');
  window.location.reload();
  return response.data;
} catch (error: any) {
  throw error;
}
};

