import { PASS_FLAG } from "../utilities/helper/constants";

export const hrp = {
  hrpDetail: [
    {
      id: 51,
      hrprequestid: 10,
      action: "Modify",
      projectName: "Test Project12",
      projectSponsorLillyId: "C276456",
      bgcCompletionDate: null,
      ndaAdfSigned: null,
      drugTestCleared: null,
      drugTestDate: null,
      valueCycle: "MD IT/ Research IT",
      status: "Complete",
      projectSponsorName: "Test Sponsor",
      workedBefore: null,
      comments: "modfy12",
      createdAt: "2023-05-31T14:01:35.914Z"
      ,
      createdBy: "L006152",
      completedBy: "L006152",
      completedAt: "2023-05-17T13:31:38.530Z",
      lillyId: "C987456",
      globalId: "1987456",
    },
    {
      id: 50,
      hrprequestid: 10,
      action: "Modify",
      projectName: "Test Project1",
      projectSponsorLillyId: "C276456",
      bgcCompletionDate: null,
      ndaAdfSigned: null,
      drugTestCleared: null,
      drugTestDate: null,
      valueCycle: "MD IT/ Research IT",
      status: "Complete",
      projectSponsorName: "Test Sponsor",
      workedBefore: null,
      comments: "helloooooo",
      createdAt: "2023-05-17T13:26:45.082Z",
      createdBy: "L040915",
      completedBy: "L040915",
      completedAt: "2023-05-17T13:27:00.546Z",
      lillyId: "C987456",
      globalId: "1987456",
    },
    {
      id: 49,
      hrprequestid: 10,
      action: "Modify",
      projectName: "Test Project1",
      projectSponsorLillyId: "C276456",
      bgcCompletionDate: null,
      ndaAdfSigned: null,
      drugTestCleared: null,
      drugTestDate: null,
      valueCycle: "MD IT/ Research IT",
      status: "Complete",
      projectSponsorName: "Test Sponsor",
      workedBefore: null,
      comments: "modify1",
      createdAt: "2023-05-17T11:15:29.816Z",
      createdBy: "L006152",
      completedBy: "L006152",
      completedAt: "2023-05-17T11:15:59.996Z",
      lillyId: "C987456",
      globalId: "1987456",
    },
    {
      id: 48,
      hrprequestid: 10,
      action: "Modify",
      projectName: "Test Project",
      projectSponsorLillyId: "C276456",
      bgcCompletionDate: null,
      ndaAdfSigned: null,
      drugTestCleared: null,
      drugTestDate: null,
      valueCycle: "MD IT/ Research IT",
      status: "Complete",
      projectSponsorName: "Test Sponsor",
      workedBefore: null,
      comments: "jdvdffhlaskhfl",
      createdAt: "2023-05-17T11:10:30.659Z",
      createdBy: "L006152",
      completedBy: "L006152",
      completedAt: "2023-05-17T11:10:55.276Z",
      lillyId: "C987456",
      globalId: "1987456",
    },
    {
      id: 47,
      hrprequestid: 10,
      action: "Modify",
      projectName: "Test Project",
      projectSponsorLillyId: "C276456",
      bgcCompletionDate: null,
      ndaAdfSigned: null,
      drugTestCleared: null,
      drugTestDate: null,
      valueCycle: "MD IT/ Research IT",
      status: "Complete",
      projectSponsorName: "Test Sponsor",
      workedBefore: null,
      comments: "We tried to modify it",
      createdAt: "2023-05-17T11:09:02.276Z",
      createdBy: "L006152",
      completedBy: "L006152",
      completedAt: "2023-05-17T11:09:29.062Z",
      lillyId: "C987456",
      globalId: "1987456",
    },
    {
      id: 10,
      hrprequestid: 10,
      action: "Create",
      projectName: "Test Project",
      projectSponsorLillyId: "C276456",
      bgcCompletionDate: "2020-04-17",
      ndaAdfSigned: "Yes",
      drugTestCleared: PASS_FLAG,
      drugTestDate: "2020-04-21",
      valueCycle: "MD IT/ Research IT",
      status: "Complete",
      projectSponsorName: "Test Sponsor",
      workedBefore: "No",
      comments: "New HRP creation",
      createdAt: "2020-05-01T11:16:41.022Z",
      createdBy: "V0X5302",
      completedBy: "V0X5302",
      completedAt: "2020-05-01T11:18:37.555Z",
      lillyId: "C987456",
      globalId: "1987456",
    },
  ],
};
