import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import {
  getAllUserAuditDetail,
  getUserInfo,
  getSearchUserData,
} from "../../../../store/slices/userSlice";
import { Button, Form } from "react-bootstrap";
import { FcSearch } from "react-icons/fc";
import { MdClose, MdDelete } from "react-icons/md";
import { SlMagnifierAdd } from "react-icons/sl";
import "./ResourceManagement.css";
import { IoAddCircleSharp } from "react-icons/io5";
import DataTable from "react-data-table-component";
import { convertDate } from "../../../../utilities/helper/common";
import Loader from "../../../../utilities/Loader/Loader";
import SelectInput from "../../../../utilities/SelectInput/SelectInput";
import { toast } from "react-toastify";
import {
  ACTIVE_FLAG,
  ALL_DETAILS_REQUIRED,
  API_LOADING,
  API_SUCCEEDED,
  INACTIVE_FLAG,
  NOT_FOUND,
} from "../../../../utilities/helper/constants";

interface FilterArrProps {
  fieldVal: string;
  oldVal: string;
  newVal: string;
  op: string;
}

interface UserAuditProps {
  lillyId: string;
  name: string;
  startDate: string;
  endDate: string;
  filterArr: Array<FilterArrProps>;
  page: number;
  pageSize: number;
  sortFields: any;
}

const ResourceManagement = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<Number>(1);
  // const [userAuditArray, setUserAuditArray] = useState<Array<any>>([]);
  // const [filterCount, setFilterCount] = useState<Array<String>>([
  //   new Date().getTime().toString(),
  // ]);
  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const [userAuditLillyIdValue, setUserAuditLillyIdValue] = useState<
    Array<Object>
  >([]);
  let fieldName: Array<string> | undefined = [];
  let firstVal: string = "";
  const filterFields = [
    ["Status", "status"],
    ["TCS ID", "tcsId"],
    ["Lilly ID", "lillyId"],
    ["Global ID", "globalId"],
    ["Name", "name"],
    ["TCS Email ID", "tcsEmailId"],
    ["Lilly Email ID", "lillyEmailId"],
    ["Area", "area"],
    ["Subarea", "subarea"],
    ["Onsite/Offshore", "onsiteOffshore"],
    ["TCS Group Manager", "tcsSupervisor"],
    ["TCS Account Manager", "tcsAccountManager"],
    ["Lilly Manager", "lillyManager"],
    ["Lilly Manager Lilly ID", "lillyManagerLillyId"],
    ["Key Supplier Personnel", "keySupplierPersonnel"],
    ["Comments", "comments"],
    ["Created By", "createdBy"],
    ["Updated By", "updatedBy"],
    ["Updated At", "updatedAt"],
    ["Lilly Worker Start Date", "lillyWorkerStartDate"],
    ["Lilly Worker End Date", "lillyWorkerEndDate"],
  ];
  const [auditShow, setAuditShow] = useState<Boolean>(false);
  const {
    userAuditApiStatus,
    userAuditLillyId,
    searchApiStatus,
    searchUserData,
    dataTableCount,
  } = useAppSelector((store) => store.userNavigation);
  const [check, setCheck] = useState<Boolean>(false);
  const [usersData, setUsersData] = useState<any>([]);
  // const [show, setShow] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const initialState = {
    lillyId: "",
    name: "",
    startDate: new Date("1970-01-01").toISOString(),
    endDate: new Date(Date.now()).toISOString(),
    filterArr: [],
    page: 1,
    pageSize: 10,
    sortFields: { columnName: "name", sortType: "asc" },
  };
  const [userAuditValue, setUserAuditValue] =
    useState<UserAuditProps>(initialState);

  const [filterArray, setFilterArray] = useState<Array<FilterArrProps>>([]);
  const [filterString, setfilterString] = useState<Array<string>>([]);

  const [filterObj, setFilterObj] = useState<FilterArrProps>({
    fieldVal: "",
    oldVal: "",
    newVal: "",
    op: "or",
  });

  const columnsHeader = [
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "TCS ID",
      selector: (row: any) => row.tcsId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Lilly ID",
      selector: (row: any) => row.lillyId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Global ID",
      selector: (row: any) => row.globalId,
      sortable: true,
      wrap: true,
    },
    {
      name: "TCS Email ID",
      selector: (row: any) => row.tcsEmailId,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Lilly Email ID",
      selector: (row: any) => row.lillyEmailId,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Area",
      selector: (row: any) => row.area,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Subarea",
      selector: (row: any) => row.subarea,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Onsite/Offshore",
      selector: (row: any) => row.onsiteOffshore,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Application Role",

      selector: (row: any) => row.applicationRole,
      sortable: false,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Project Role",
      selector: (row: any) => row.projectRole,
      sortable: false,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "TCS Group Manager",
      selector: (row: any) => row.tcsSupervisor,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "TCS Account Manager",
      selector: (row: any) => row.tcsAccountManager,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Lilly Manager",
      selector: (row: any) => row.lillyManager,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Lilly Manager Lilly ID",
      selector: (row: any) => row.lillyManagerLillyId,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Key Supplier Personnel",
      selector: (row: any) => row.keySupplierPersonnel,
      sortable: true,
      wrap: true,
    },
    {
      name: "Lilly Worker Start Date",
      selector: (row: any) => row.lillyWorkerStartDate,
      cell: (row: any) => convertDate(row.lillyWorkerStartDate),

      sortable: true,
      wrap: true,
    },
    {
      name: "Lilly Worker End Date",
      selector: (row: any) => convertDate(row.lillyWorkerEndDate),
      sortable: true,
      wrap: true,
    },
    {
      name: "Comments",
      selector: (row: any) => row.comments,
      sortable: true,
      wrap: true,
      style: {
        textAlign: "left" as "left",
      },
    },
    {
      name: "Created By",
      selector: (row: any) => row.createdBy,
      sortable: true,
      wrap: true,
    },
    {
      name: "Updated By",
      selector: (row: any) => row.updatedBy,
      sortable: true,
      wrap: true,
    },
    {
      name: "Updated At",
      selector: (row: any) => row.updatedAt,
      cell: (row: any) => convertDate(row.updatedAt),

      sortable: true,
      wrap: true,
    },
  ];

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getSearchUserData({ searchText: "", page: 1, pageSize: 1000 }));
    dispatch(
      getAllUserAuditDetail({
        page: 1,
        pageSize: 10,
        sortFields: { columnName: "name", sortType: "asc" },
      })
    );
  }, []);

  useEffect(() => {
    if (searchApiStatus === API_SUCCEEDED) {
      setUsersData(searchUserData);
    }
  }, [searchApiStatus]);

  useEffect(() => {
    if (userAuditApiStatus === API_SUCCEEDED) {
      // setUserAuditArray(userAuditData);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [userAuditApiStatus]);

  useEffect(() => {
    if (userAuditLillyId.length === 0 && userAuditApiStatus === API_SUCCEEDED) {
      toast.error(NOT_FOUND);
      // setUserAuditValue(initialState);
      // dispatch(
      //   getAllUserAuditDetail({
      //     startDate: userAuditValue.startDate,
      //     endDate: userAuditValue.endDate,
      //     page: 1,
      //     pageSize: 10,
      //     sortFields: {columnName: "name", sortType: "asc"}
      //   })
      // );
    }
    setUserAuditLillyIdValue(userAuditLillyId);
  }, [userAuditLillyId]);

  useEffect(() => {
    handleSearch();
    // setResetFlag(!resetFlag);
  }, [pageSize, currentPage, userAuditValue.sortFields]);

  useEffect(() => {
    if (!auditShow) {
      setFilterArray([]);
      setUserAuditValue({ ...userAuditValue, filterArr: [] });
      setFilterObj({
        fieldVal: "",
        oldVal: "",
        newVal: "",
        op: "or",
      });;
    }
  }, [
    userAuditValue.lillyId,
    userAuditValue.endDate,
    userAuditValue.startDate,
  ]);

  const handleDelete = (data: object) => {
    setFilterArray(filterArray.filter((value) => data !== value));
  };

  const changeHandler = (e: any) => {
    if (e.target.name === "startDate" || e.target.name === "endDate") {
      setUserAuditValue({
        ...userAuditValue,
        [e.target.name]: new Date(e.target.value).toISOString(),
      });
    }
  };

  const handleSort = async (column: any, sortDirection: any) => {
    const fieldName1 = filterFields.find((row: any) =>
      row.includes(column.name)
    );
    await setUserAuditValue({
      ...userAuditValue,
      sortFields: {
        columnName: fieldName1 ? fieldName1[1] : "",
        sortType: sortDirection,
      },
    });
    // if (currentPage === 1) {
    //   setResetFlag(!resetFlag);
    // }
    // if (userAuditValue.filterArr.length > 0) {
    //   dispatch(
    //     getAllUserAuditDetail({
    //       lillyId: userAuditValue.lillyId,
    //       startDate: userAuditValue.startDate,
    //       endDate: userAuditValue.endDate,
    //       filterArr: userAuditValue.filterArr,
    //       sortFields: {
    //         columnName: fieldName1 ? fieldName1[1] : "",
    //         sortType: sortDirection,
    //       },
    //       page: currentPage,
    //       pageSize: pageSize
    //     })
    //   );
    // } else if (userAuditValue.lillyId.length === 0) {
    //   dispatch(
    //     getAllUserAuditDetail({
    //       startDate: userAuditValue.startDate,
    //       endDate: userAuditValue.endDate,
    //       sortFields: {
    //         columnName: fieldName1 ? fieldName1[1] : "",
    //         sortType: sortDirection,
    //       },
    //       page: currentPage,
    //       pageSize: pageSize
    //     })
    //   );
    // }else if (userAuditValue.lillyId.length !== 0) {
    //   dispatch(
    //     getAllUserAuditDetail({
    //       startDate: userAuditValue.startDate,
    //       lillyId: userAuditValue.lillyId,
    //       endDate: userAuditValue.endDate,
    //       sortFields: {
    //         columnName: fieldName1 ? fieldName1[1] : "",
    //         sortType: sortDirection,
    //       },
    //       page: currentPage,
    //       pageSize: pageSize
    //     })
    //   );
    // } else if(userAuditValue.startDate !=="") {
    //   dispatch(
    //     getAllUserAuditDetail({
    //       lillyId: userAuditValue.lillyId,
    //       startDate: userAuditValue.startDate,
    //       endDate: userAuditValue.endDate,
    //       sortFields: {
    //         columnName: fieldName1 ? fieldName1[1] : "",
    //         sortType: sortDirection,
    //       },
    //       page: currentPage,
    //       pageSize: pageSize
    //     })
    //   );
    // }
  };

  useEffect(() => {
    setUserAuditValue((prevValue) => ({
      ...prevValue,
      filterArr: filterArray,
    }));
  }, [filterArray]);
  const handlePerRowsChange = async (newPageSize: any, page: any) => {
    await setPageSize(newPageSize);
    // if(page===1){
    //   handlPageChange(1);
    // }
    // if (userAuditValue.filterArr.length > 0) {
    //   dispatch(
    //     getAllUserAuditDetail({
    //       lillyId: userAuditValue.lillyId,
    //       startDate: userAuditValue.startDate,
    //       endDate: userAuditValue.endDate,
    //       filterArr: userAuditValue.filterArr,
    //       page: page,
    //       pageSize: newPerPage,
    //     })
    //   );
    // } else if (userAuditValue.lillyId.length === 0) {
    //   dispatch(
    //     getAllUserAuditDetail({
    //       startDate: userAuditValue.startDate,
    //       endDate: userAuditValue.endDate,
    //       page: page,
    //       pageSize: newPerPage,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     getAllUserAuditDetail({
    //       lillyId: userAuditValue.lillyId,
    //       startDate: userAuditValue.startDate,
    //       endDate: userAuditValue.endDate,
    //       page: page,
    //       pageSize: newPerPage,
    //     })
    //   );
    // }
  };

  const handlPageChange = async (page: any) => {
    setCurrentPage(page);
  };
  const handleSearch = async () => {
    let obj = {
      startDate: userAuditValue.startDate,
      endDate: userAuditValue.endDate,
      page: currentPage,
      pageSize: pageSize,
    };
    if (
      userAuditValue.filterArr?.length > 0 &&
      userAuditValue?.lillyId?.length === 0
    ) {
      dispatch(
        getAllUserAuditDetail({
          // lillyId: userAuditValue.lillyId,
          // sortFields: userAuditValue.sortFields,
          filterArr: userAuditValue.filterArr,
          ...obj,
        })
      );
    } else if (
      userAuditValue?.filterArr?.length > 0 &&
      userAuditValue?.lillyId?.length !== 0
    ) {
      dispatch(
        getAllUserAuditDetail({
          lillyId: userAuditValue.lillyId,
          filterArr: userAuditValue.filterArr,
          ...obj,
        })
      );
    } else if (userAuditValue?.lillyId?.length === 0) {
      dispatch(
        getAllUserAuditDetail({
          sortFields: userAuditValue.sortFields,
          ...obj,
        })
      );
    } else {
      dispatch(
        getAllUserAuditDetail({
          sortFields: userAuditValue.sortFields,
          lillyId: userAuditValue.lillyId,
          ...obj,
        })
      );
    }
  };

  // useEffect(()=> {
  //   if (
  //     !filterArray.includes(filterObj) &&
  //     filterObj.fieldVal !== "" &&
  //     filterObj.oldVal !== "" &&
  //     filterObj.newVal !== ""
  //   ) {
  //     setFilterArray((oldArray) => [...oldArray, filterObj]);
  //   }
  // },[filterObj])
  const searchResult = async () => {
    setAuditShow(false);
    // setFilterArray([]);
    // setFilterObj({
    //   fieldVal: "",
    //   oldVal: "",
    //   newVal: "",
    //   op: "or",
    // });
    if (!filterArray.includes(filterObj)) {
      if (
        filterObj.fieldVal !== "" &&
        filterObj.oldVal !== "" &&
        filterObj.newVal !== ""
      ) {
        setFilterArray((oldArray) => [...oldArray, filterObj]);
      }
    }
    if (
      userAuditValue.lillyId === "" &&
      userAuditValue.filterArr.length !== 0
    ) {
      // await userAuditValue.filterArr.pop();
      const ArrayLength = userAuditValue.filterArr.length;
      const lastValue = userAuditValue.filterArr[ArrayLength - 1];
      let newObj = {
        ...lastValue,
        op: "",
      };
      await userAuditValue.filterArr.pop();
      await userAuditValue.filterArr.push(newObj);
      let obj = {
        startDate: userAuditValue.startDate,
        endDate: userAuditValue.endDate,
        filterArr: userAuditValue.filterArr,
        page: 1,
        pageSize: 10,
      };

      await dispatch(getAllUserAuditDetail(obj));
    } else if (
      userAuditValue.lillyId !== "" &&
      userAuditValue.name !== "" &&
      filterArray.length === 0
    ) {
      let obj = {
        lillyId: userAuditValue.lillyId,
        startDate: userAuditValue.startDate,
        endDate: userAuditValue.endDate,
        page: 1,
        pageSize: 10,
      };
      await dispatch(getAllUserAuditDetail(obj));
    } else if (
      userAuditValue.startDate !== "" &&
      userAuditValue.endDate !== "" &&
      filterArray.length === 0
    ) {
      let obj = {
        startDate: userAuditValue.startDate,
        endDate: userAuditValue.endDate,
        page: 1,
        pageSize: 10,
      };
      await dispatch(getAllUserAuditDetail(obj));
    } else if (
      userAuditValue.lillyId !== "" &&
      userAuditValue.name !== "" &&
      userAuditValue.filterArr.length !== 0
    ) {
      await userAuditValue.filterArr.pop();
      let newObj = {
        ...filterObj,
        op: "",
      };
      await userAuditValue.filterArr.push(newObj);
      let obj = {
        lillyId: userAuditValue.lillyId,
        startDate: userAuditValue.startDate,
        endDate: userAuditValue.endDate,
        filterArr: userAuditValue.filterArr,
        page: 1,
        pageSize: 10,
      };
      await dispatch(getAllUserAuditDetail(obj));
    }
    // setShow(true);
  };
  const filterChangeHandler = (e: any) => {
    if (e.target.name === "op") {
      setCheck(!check);
      if (check) {
        setFilterObj({ ...filterObj, [e.target.name]: "or" });
      } else {
        setFilterObj({ ...filterObj, [e.target.name]: "and" });
      }
    } else if (e.target.value.toLowerCase() === "inactive")
      setFilterObj({ ...filterObj, [e.target.name]: INACTIVE_FLAG });
    else if (e.target.value.toLowerCase() === "active")
      setFilterObj({ ...filterObj, [e.target.name]: ACTIVE_FLAG });
    else setFilterObj({ ...filterObj, [e.target.name]: e.target.value });
  };
  return (
    <>
      <h4
        style={{
          fontFamily: "Merriweather Sans",
          fontWeight: "bold",
          margin: "20px",
        }}
      >
        Resource Report
      </h4>

      
        <div style={{ margin: "2%", height: "93vh" }}>
          <div className="search-fields">
            <div
              style={{
                display: "inline-block",
                margin: "0 1%",
                marginTop: "1.5%",
                width: "250px",
                zIndex: 100,
              }}
            >
              <SelectInput
                allValues={userAuditValue}
                setAllValues={setUserAuditValue}
                usersData={usersData}
                title={"Lilly ID"}
                setUsersData={setUsersData}
              />
            </div>

            <Form.Group
              className="mb-3 resource-inputs"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="label-resource">From</Form.Label>
              <Form.Control
                className="resource-search"
                type="date"
                name="startDate"
                onKeyDown={(e) => e.preventDefault()}
                min="1970-01-01"
                max="2050-12-31"
                defaultValue={usersData.startDate}
                onChange={changeHandler}
                value={
                  userAuditValue.endDate
                    ? convertDate(userAuditValue.startDate)
                    : ""
                }
              />
            </Form.Group>
            <Form.Group
              className="mb-3 resource-inputs "
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="label-resource">To</Form.Label>
              <Form.Control
                className="resource-search"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                max="2050-12-31"
                name="endDate"
                // defaultValue={new Date().toISOString().split("T")[0]}
                defaultValue={userAuditValue.endDate}
                onChange={changeHandler}
                min={convertDate(userAuditValue.startDate)}
                value={
                  initialState.endDate
                    ? convertDate(userAuditValue.endDate)
                    : ""
                }
              />
            </Form.Group>
          </div>
          {auditShow ? (
            <div className="search-fields1">
              <div className="mb-3 add-button-div"></div>

              <Form.Group
                className="mb-3 resource-accordion-inputs "
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-resource">
                  Filter Fields
                </Form.Label>
                <Form.Select
                  className="resource-search"
                  name="fieldVal"
                  onChange={filterChangeHandler}
                >
                  <option value={""}>Filter Field</option>
                  {filterFields.map((field: any, key: any) => (
                    <option value={field[1]} key={key}>
                      {field[0]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3 resource-accordion-inputs "
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-resource">Old Value</Form.Label>
                <Form.Control
                  className="resource-search"
                  type="text"
                  name="oldVal"
                  onChange={filterChangeHandler}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 resource-accordion-inputs"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-resource">New Value</Form.Label>
                <Form.Control
                  className="resource-search"
                  type="text"
                  name="newVal"
                  onChange={filterChangeHandler}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3 switch-group">
                <Form.Label className="switch-label">OR</Form.Label>
                <Form.Check
                  type="switch"
                  name="op"
                  onChange={filterChangeHandler}
                  disabled={false}
                />
                <Form.Label className="switch-label">AND</Form.Label>
                <IoAddCircleSharp
                  className="add-button"
                  onClick={async (event) => {
                    // setFilterCount((oldArray) => [
                    //   ...oldArray,
                    //   new Date().getTime().toString(),
                    // ]);
                    event.stopPropagation();
                    if (!filterArray.includes(filterObj)) {
                      if (
                        filterObj.fieldVal === "" ||
                        filterObj.newVal === "" ||
                        filterObj.oldVal === ""
                      ) {
                        toast.error(ALL_DETAILS_REQUIRED);
                        return;
                      }
                      await setFilterArray((oldArray) => [
                        ...oldArray,
                        filterObj,
                      ]);
                      fieldName = filterFields.find((row: any) =>
                        row.includes(filterObj.fieldVal)
                      );
                      firstVal = fieldName ? fieldName[0] : "";
                      setfilterString((oldVal) => [...oldVal, firstVal]);
                    }
                  }}
                />
              </Form.Group>
            </div>
          ) : null}

          <div className="buttons-div">
            <Button
              className="button-name"
              onClick={async () => {
                // setFilterArray([]);
                setFilterObj({ ...filterObj, op: "" });
                await searchResult();
                setfilterString([]);
              }}
              disabled={filterArray.length === 0 && auditShow ? true : false}
            >
              <FcSearch className="button-icon" />
              Search
            </Button>
            <Button
              className="button-name"
              onClick={async () => {
                // setUserAuditArray([]);
                setUserAuditValue(initialState);
                dispatch(getAllUserAuditDetail({ page: 1, pageSize: 10 }));
                setResetFlag(!resetFlag);
              }}
            >
              <MdClose className="button-icon" />
              Reset
            </Button>
            <Button
              className="button-name"
              onClick={() => {
                setAuditShow(!auditShow);
                setFilterArray([]);
                setfilterString([]);
                setFilterObj({
                  fieldVal: "",
                  oldVal: "",
                  newVal: "",
                  op: "or",
                });
                // if (auditShow) {
                //   setFilterArray([]);
                // }
              }}
            >
              <SlMagnifierAdd className="button-icon" />
              {auditShow ? "Remove Filter" : "More"}
            </Button>
          </div>

          <div style={{ overflow: "auto", height: auditShow ? "0vh" : "58vh" }}>
            {(!auditShow && userAuditLillyId.length !== 0) ||
            userAuditApiStatus === API_LOADING ? (
              <DataTable
                className="data-table"
                columns={columnsHeader}
                data={userAuditLillyIdValue}
                fixedHeader
                fixedHeaderScrollHeight="300px"
                highlightOnHover
                striped
                pagination
                paginationServer
                sortServer
                progressComponent={<Loader />}
                onSort={handleSort}
                progressPending={isLoading}
                paginationPerPage={pageSize}
                paginationTotalRows={dataTableCount}
                paginationDefaultPage={1}
                paginationResetDefaultPage={resetFlag}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlPageChange}
              />
            ) : null}
          </div>

          <div style={{ overflow: "auto", height: "58vh" }}>
            {auditShow && filterArray.length !== 0 ? (
              <div
                style={{
                  height: "50%",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  marginLeft: "2%",
                }}
              >
                {filterArray.map((data: any, key: any) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px",
                    }}
                    key={key}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        background:
                          "linear-gradient(rgb(111, 247, 232), rgb(31, 126, 161))",
                        margin: "10px",
                        height: "70px",
                        width: "95%",
                        borderRadius: "10px",
                        color: "#fff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "10%",
                        }}
                      >
                        <MdDelete
                          style={{
                            fontSize: "30px",
                            marginLeft: "25px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDelete(data)}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            margin: "4px",
                            width: "80%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontWeight: "bold",
                          }}
                          title={data.fieldVal}
                        >
                          {filterString[key]}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            margin: "4px",
                            width: "80%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontWeight: "bold",
                          }}
                          title={data.oldVal}
                        >
                          {data.oldVal}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            margin: "4px",
                            width: "80%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontWeight: "bold",
                          }}
                          title={data.newVal}
                        >
                          {data.newVal}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            margin: "4px",
                            width: "80%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontWeight: "bold",
                          }}
                          title={data.op}
                        >
                          {data.op}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "18%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
    </>
     
  );
};

export default ResourceManagement;
