import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import "./LearnersContent.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { getassignedquiz } from "../../../store/commonActions";
import { API_SUCCEEDED, QUIZ_INSTRUCTIONS, QUIZ_INSTRUCTIONS_CHECK } from "../../../utilities/helper/constants";

const QuizInstructionModal = (props: any) => {
  const dispatch = useAppDispatch();
  const { quizmasterid, maxQuestion, assignTableId } = props;
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const { quizApiStatus } = useAppSelector((store) => store.learnerNavigation);
  const handleCheck = (e: any) => {
    if (flag === e.target.checked) {
      setFlag(!flag);
    }
  };
  const handleSubmit = () => {
    let obj = {
      id: quizmasterid,
      assignTableId: assignTableId,
    };
    dispatch(getassignedquiz(obj));
    setFlag(!flag);
  };
  useEffect(() => {
    if (quizApiStatus === API_SUCCEEDED) {
      navigate("/TakeQuiz");
    }
  }, [quizApiStatus]);

  return (
    <>
      <Card className="outercard-quizInstruction">
        <Card.Title className="title-quizInstruction">
          QUIZ INSTRUCTIONS
        </Card.Title>
        <Card.Body className="card-body">
          <ol>
            <li >
              This test consists of {maxQuestion} questions.
            </li>
          {QUIZ_INSTRUCTIONS.map((item: string, key:any)=> (
            <li key={key}>{item}</li>
          ))}
          </ol>
          <input
            className="checkbox-style"
            type="checkbox"
            onChange={handleCheck}
          />
          <span className="checkbox-style" >
            {QUIZ_INSTRUCTIONS_CHECK}
          </span>
        </Card.Body>
        <Card.Footer className="text-center">
          <Button variant="primary" disabled={flag} onClick={handleSubmit}>
            Start Quiz
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default QuizInstructionModal;
