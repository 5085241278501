import { Card } from "react-bootstrap";
import "./Content.css";

const PharmaTrainings = () => {
  return (
    <>
      <Card className="cardStyle"
      >
       
        <div
         className="card-outerdiv"
        >
        <div className="card-innerdiv">
        <ul>
                      <Card className="pharma">Protecting Consumer Privacy Policy (iEvolve ID-15072)</Card>
                      <Card className="pharma">Good Clinical Practices Process (iEvolve ID-42894)</Card>
                      <Card className="pharma">Clinical Data Integrity Process (iEvolve ID-21938)</Card>
                      <Card className="pharma">Drug Development Process (iEvolve ID-6116)</Card>
                      <Card className="pharma">21 CFR Part 11 Process (iEvolve ID-18478)</Card>
        </ul>
        </div>
        </div>
      </Card>
    </>
  );
};
export default PharmaTrainings;
