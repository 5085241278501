import { createSlice} from "@reduxjs/toolkit";
import { NavState } from "../../models/redux-models";



const initialState: NavState = {
    navPath: "Trainings",
   
}

const navSlice = createSlice({
    name: "moduleNavigation",
    initialState,
    reducers: {
        setModule: (state, action) => {
            state.navPath= action.payload
        },
      
    },
})

export default navSlice;
export {navSlice};
export const { setModule} = navSlice.actions;