import { Card } from "react-bootstrap";
const Other = () => {
  return (
    <>
      <Card className="cardStyle"
      >
        <div
         className="card-outerdiv"
        >
          <div
          className="card-innerdiv"
          >
            <ul>
              <Card className="other">
                ITIL 2011 Foundation Course
              </Card>
              <Card className="other">
                Application Operation Awareness - Course and Assessment
              </Card>
            </ul>
          </div>
        </div>
      </Card>
    </>
  );
};
export default Other;
