import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import {
  assigndeleteId,
  getAreaSubarea1,
  getSearchUserData,
  getSubarea1,
  userStatusTableArea,
} from "../../../../store/commonActions";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { convertDate, sessionGetter, uniqueArea } from "../../../../utilities/helper/common";
import SelectInput from "../../../../utilities/SelectInput/SelectInput";
import Loader from "../../../../utilities/Loader/Loader";
import Swal from "sweetalert2";
import {
  ACTIVE_FLAG,
  API_SUCCEEDED,
  DELETE_SUCCESS,
  DELETE_WARNING,
  NOT_ATTEMPTED_FLAG,
} from "../../../../utilities/helper/constants";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
interface LearnerProps {
  name: string;
  lillyId: string;
  masterSubareaId: string;
  area: string;
  subarea: string;
  areaId: string;
  areaList: any;
}

const LearnersReport = (props: any) => {
  const dispatch = useAppDispatch();
  const [usersData, setUsersData] = useState<any[]>([]);
  const [selectedAreaList, setSelectedAreaList] = useState<any>([]);
  const [areaList, setAreaList] = useState<any>([]);
  const [subareaList, setSubareaList] = useState<any>([]);
  const [userQuizDetails, setUserQuizDetails] = useState<any>({});
  const [searchResult, setSearchResult] = useState<any>([]);
  // const [searchInput, setSearchInput] = useState<string>("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  // const [containNull, setContainNull] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [allValues, setAllValues] = useState<LearnerProps>({
    areaList: [],
    name: "",
    lillyId: "",
    masterSubareaId: "",
    area: "",
    areaId: "",
    subarea: "",
  });
  const {
    searchApiStatus,
    searchUserData,
    areaSubareaApiStatus1,
    areaSubareaData1,
    subareaApiStatus,
    subareaData1,
  } = useAppSelector((store) => store.userNavigation);
  const {
    userStatusAreaList,
    userStatusSubAreaList,
    userStatusTableAreaApi,
    apiStatus,
  } = useAppSelector((store) => store.quizzesNavigation);

  useEffect(() => {
    if (apiStatus === API_SUCCEEDED) {
      const obj = { lillyId: allValues.lillyId };
      if(allValues.lillyId !== "")
        dispatch(userStatusTableArea(obj));
      else
        dispatch(userStatusTableArea({lillyId: sessionGetter('uid')}))
      toast.success(DELETE_SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus]);

  const columnsHeader = [
    {
      name: "Action",
      cell: (row: any) => (
        <div style={{ fontSize: "12px", margin: "4px", width: "30px" }}>
          {row.state === NOT_ATTEMPTED_FLAG ? (
            <Button style={{ background: "none", border: "none" }}>
              <MdDelete 
                style={{
                  fontSize: "30px",
                  marginLeft: "5px",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => handleDelete(row.quizAssignTableId)}
              />
            </Button>
          ) : (
            <></>
            // <Button
            //   style={{ background: "none", border: "none" }}
            //   disabled={true}
            // >
            //   <MdDelete 
            //     style={{
            //       fontSize: "30px",
            //       marginLeft: "5px",
            //       color: "black",
            //       cursor: "pointer",
            //     }}
            //   />
            // </Button>
          )}
        </div>
      ),

      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row: any) => row.name,
      sortable: true,
      wrap: true,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Assignee ID",
      selector: (row: any) => row.lillyId,
      sortable: true,
      wrap: true,
    },
    {
      name: "Quiz Name",
      selector: (row: any) => row.activity,
      sortable: true,
      wrap: true,
      grow: 2,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Area",
      selector: (row: any) => row.area,
      sortable: true,
      wrap: true,
      grow: 2,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Subarea",
      selector: (row: any) => row.subarea,
      sortable: true,
      wrap: true,
      grow: 2,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "State",
      selector: (row: any) => row.state,
      sortable: true,
      wrap: true,
      grow: 1.5,
      style: {
        textAlign: 'left'  as 'left'
      }
    },
    {
      name: "Attempts",
      selector: (row: any) => (
        <div style={{ fontSize: "12px", margin: "4px", width: "40px" }}>
          {row.attempts}/{row.maxAttempt}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Assigned By",
      selector: (row: any) => row.assignedBy,
      sortable: true,
      wrap: true,
    },
    {
      name: "Due Date",
      selector: (row: any) => row.dueDate,
      cell: (row: any) => convertDate(row.dueDate),

      sortable: true,
      wrap: true,
    },
    {
      name: "Assigned Date",
      selector: (row: any) => row.assignedDate,
      cell: (row: any) => convertDate(row.assignedDate),

      sortable: true,
      wrap: true,
    },
    {
      name: "Completion Date",
      selector: (row: any) => row.completionDate,
      cell: (row: any) => convertDate(row.completionDate),

      sortable: true,
      wrap: true,
    },
    {
      name: "Last Attempt Date",
      selector: (row: any) => row.lastAttempted,
      cell: (row: any) => convertDate(row.lastAttempted),

      sortable: true,
      wrap: true,
    },
  ];

  useEffect(() => {
    setUserQuizDetails(userStatusAreaList);
  }, [userStatusAreaList]);

  useEffect(() => {
    setUserQuizDetails(userStatusSubAreaList);
  }, [userStatusSubAreaList]);

  useEffect(() => {
    const obj = { lillyId: sessionGetter("uid") };
    dispatch(getSearchUserData({ searchText: "", page: 1, pageSize: 1000 }));
    dispatch(getAreaSubarea1({ status: ACTIVE_FLAG }));
    dispatch(userStatusTableArea(obj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userStatusTableAreaApi === API_SUCCEEDED) {
      setIsLoading(false);
      setSearchResult(userStatusAreaList);
    } else {
      setIsLoading(true);
    }
  }, [userStatusTableAreaApi]);

  useEffect(() => {
    if (searchApiStatus === API_SUCCEEDED) {
      setUsersData(searchUserData);
    }
  }, [searchApiStatus]);

  useEffect(() => {
    if (areaSubareaApiStatus1 === API_SUCCEEDED) {
      setAreaList(uniqueArea(areaSubareaData1));
    }
  }, [areaSubareaApiStatus1]);

  // useEffect(() => {
  //   dispatch(
  //     getSearchUserData({ searchText: searchInput, page: 1, pageSize: 1000 })
  //   );
  // }, [searchInput]);

  useEffect(() => {
    dispatch(getSubarea1({ area: selectedArea }));
  }, [selectedArea]);

  useEffect(() => {
    if (subareaApiStatus === API_SUCCEEDED) setSubareaList(subareaData1);
  }, [subareaApiStatus]);

  // useEffect(() => {
  //   if (subareaData1.filter((item: any) => item.subarea === null).length !== 0)
  //     setContainNull(true);
  //   else setContainNull(false);
  // }, [subareaList]);    

  const changeHandler1 = async (e: any) => {
    setAllValues({
      ...allValues,
      [e.target.name]: e.target.value,
      areaId: allValues.areaId,
    });
  };
  const changeHandler2 = (e: any) => {
    setSelectedAreaList(e);
    if (e.length === 1) {
      setSelectedArea(e[0].value);
    } else {
      setSubareaList([]);
      setSelectedArea("");
    }

    let arr: any = [];
    e.map((data: any) => {
      return arr.push(data.value);
    });

    setAllValues({ ...allValues, subarea: JSON.stringify(arr) });

    if (arr.length === 1 && allValues.subarea !== "") {
      setAllValues({
        ...allValues,
        areaId: arr[0],
        lillyId: "",
        name: "",
      });
    } else {
      setAllValues({
        ...allValues,
        areaId: arr,
        lillyId: "",
        name: "",
        subarea: "",
      });
    }
  };
  const search = () => {
    props.setIssCollasped(true);
    if (allValues.lillyId !== "") {
      let obj = {
        lillyId: allValues.lillyId,
      };
      dispatch(userStatusTableArea(obj));
    } else if (
      allValues.subarea === "" &&
      allValues.lillyId === "" &&
      allValues.areaId !== ""
    ) {
      let obj = {
        area: allValues.areaId,
      };
      dispatch(userStatusTableArea(obj));
    } else if (allValues.subarea !== "" && allValues.areaId !== "") {
      let obj = {
        subarea: allValues.subarea,
      };
      dispatch(userStatusTableArea(obj));
    }
  };

  const reset = () => {
    setAllValues({
      ...allValues,
      areaId: "",
      lillyId: "",
      name: "",
      subarea: "",
    });
    setSelectedAreaList([]);
    setSearchResult([]);
    dispatch(userStatusTableArea({lillyId: sessionGetter('uid')}));
  };

  useEffect(() => {
    setUserQuizDetails(userStatusAreaList);
  }, [userStatusAreaList]);

  useEffect(() => {
    if (Object.keys(userQuizDetails).length !== 0) {
      setSearchResult(userQuizDetails);
    }
  }, [userQuizDetails]);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Lilly ID", key: "lillyId" },
    { label: "Area", key: "area" },
    { label: "Subarea", key: "subarea" },
    { label: "Quiz Name", key: "activity" },
    { label: "Assigned By", key: "assignedBy" },
    { label: "Assigned Date", key: "assignedDate" },
    { label: "Attempts", key: "attempts" },
    { label: "Completion Date", key: "completionDate" },
    { label: "Due Date", key: "dueDate" },
    { label: "State", key: "state" },
    { label: "Last Atttempt Date", key: "lastAttempted" },
  ];

  const handleDelete = (deleteId: number) => {
    Swal.fire({
      title: DELETE_WARNING,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(assigndeleteId({ id: deleteId }));
        search();
      }
    });
  };
  return (
    <>
      <div style={{ height: "93vh" }}>
        <h4
          className="title2"
        >
          Learner's Report
        </h4>
        <div
          style={{
            display: "inline-block",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <Button
            variant="secondary"
            style={{
              backgroundColor: "#582630",
              border: "none",
              marginTop: "20px",
              marginRight: "10px",
            }}
            disabled={
              allValues.subarea === "" &&
              allValues.areaId.length === 0 &&
              allValues.lillyId === ""
                ? true
                : false
            }
            onClick={search}
          >
            Search
          </Button>
          <Button
            variant="secondary"
            onClick={reset}
            style={{
              backgroundColor: "#582630",
              border: "none",
              marginTop: "20px",
              marginRight: "10px",
            }}
            disabled={
              allValues.subarea === "" &&
              allValues.areaId.length === 0 &&
              allValues.lillyId === "" &&
              searchResult.length === 0
                ? true
                : false
            }
          >
            Reset
          </Button>

          <CSVLink
            data={searchResult}
            headers={headers}
            filename={"Learners-Report.csv"}
          >
            <Button
              variant="secondary"
              disabled={searchResult.length === 0 ? true : false}
              style={{
                backgroundColor: "#582630",
                border: "none",
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              Export
            </Button>
          </CSVLink>
        </div>

        <Form style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              paddingBottom: "0",
              marginBottom: "0",
            }}
          >
            <div
              style={{
                display: "inline-block",
                margin: "10px",
                marginBottom: "0",
                width: "250px",
                zIndex: 100,
                marginTop: "0",
              }}
            >
              <SelectInput
                allValues={allValues}
                setAllValues={setAllValues}
                usersData={usersData}
                title={"Lilly ID"}
                setUsersData={setUsersData}
                setSelectedAreaList={setSelectedAreaList}
              />
            </div>

            <strong style={{ display: "inline-block", marginTop: "16px" }}>
              OR
            </strong>

            <div
              style={{
                display: "inline-block",
                margin: "10px",
                marginTop: "11px",
                marginBottom: "0",
                width: "180px",
                zIndex: 100,
              }}
            >
              <Form.Group
                className="mb-3 quiz"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Area</Form.Label>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      maxHeight: "35px",
                    })
                  }}
                  placeholder="Select"
                  closeMenuOnSelect={false}
                  isMulti
                  isSearchable
                  value={selectedAreaList}
                  options={areaList.map((area: any, key: any) => ({
                    value: area,
                    label: area,
                  }))}
                  onChange={changeHandler2}
                />
              </Form.Group>
            </div>
            <div
              style={{
                display: "inline-block",
                margin: "10px",
                marginBottom: "0",
                marginTop: "-5px",
                width: "180px",
                zIndex: 100,
              }}
            >
              <Form.Label>Sub-Area</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="subarea"
                value={allValues.subarea}
                onChange={changeHandler1}
                style={{
                  color:
                    selectedAreaList.length === 0 || selectedAreaList.length > 1
                      ? "grey"
                      : "black",
                }}
              >
                <option value={""}>Select Sub Area</option>
                {subareaList.map((item: any, index: any) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.subarea === null ? "No Subarea" : item.subarea}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
          </div>
        </Form>
        {userStatusAreaList.length > 0 && !isLoading ? (
          <div
            style={{
              height: "72vh",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              marginLeft: "2%",
              marginTop: "1%",
            }}
          >
            <DataTable
              columns={columnsHeader}
              data={searchResult}
              fixedHeader
              fixedHeaderScrollHeight="390px"
              highlightOnHover
              pagination
              striped
              subHeaderWrap
            />
          </div>
        ) : userStatusAreaList.length === 0 && !isLoading ? (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            No result to display
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              paddingTop: "5%",
            }}
          >
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default LearnersReport;
