import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {addscore, initialScore, takequiz, userquizdetails} from "../../service/learnersApi";
import { LearnerState } from "../../models/redux-models";
import { API_FAILED, API_LOADING, API_SUCCEEDED, FAIL_FLAG } from "../../utilities/helper/constants";




export const  getassignedquiz = createAsyncThunk(
    "/getassignedquiz",
    async (data: any, thunkAPI) => {
      const response = await takequiz(data.id,data.assignTableId);
      return response;
    }
  );
  export const  userquizList = createAsyncThunk(
    "/userquizList",
    async (lillyId: any, thunkAPI) => {
      const response = await userquizdetails(lillyId);
      return response;
    }
  );
  export const scoreAdd  = createAsyncThunk(
    "/scoreAdd",
    async (data: any, thunkAPI) => {
      const response = await addscore(data);
      return response;
    }
  );
  export const setInitialScore  = createAsyncThunk(
    "/setInitialScore",
    async (data: any, thunkAPI) => {
      const response = await initialScore(data);
      return response;
    }
  );

const initialState: LearnerState = {
    selectedQuizPath: "all",
    renderQuiz: "",
    quizDetails: {},
    questionDetailsList:{},
    assignedQuizzesDetails:[],
    userQuizzesDetails:{},
    quizApiStatus:"",
    apiStatus: "",
    scoreApiStatus:"",
    result:FAIL_FLAG,
    percentage:0,
    updateId:0,
    attemptHour: 24
}

const learnerSlice = createSlice({
    name: "learnerNavigation",
    initialState,
    reducers: {
        setSelectedQuizPath: (state, action) => {
            state.selectedQuizPath = action.payload;
            state.quizDetails = {}
        },
        setRenderQuiz: (state, action) => {
            state.renderQuiz = action.payload
        },
        setQuizDetails: (state, action) => {
            state.quizDetails = action.payload
        },
        
        resetLearner: () => initialState

    },
    extraReducers(builder) {
        builder.addCase(getassignedquiz.pending, (state) => {
          state.questionDetailsList = {};
          state.assignedQuizzesDetails = [];
          
          state.quizApiStatus = API_LOADING;
        });
        builder.addCase(getassignedquiz.fulfilled, (state, { payload }) => {
          state.assignedQuizzesDetails =payload.data.quizAssignData;
          state.questionDetailsList = payload.data.quizdata;
          state.quizApiStatus = API_SUCCEEDED;
        });
        builder.addCase(getassignedquiz.rejected, (state, action) => {
          state.questionDetailsList = {};
          state.assignedQuizzesDetails = [];
          state.quizApiStatus = API_FAILED;
        });
        
        
        builder.addCase(userquizList.pending, (state) => {
          state.userQuizzesDetails = {};
          state.apiStatus = API_LOADING;
        });
        builder.addCase(userquizList.fulfilled, (state, { payload }) => { 
          state.apiStatus = API_SUCCEEDED;        
          state.userQuizzesDetails = payload.data;
          state.attemptHour = payload.attempHour
        });
        builder.addCase(userquizList.rejected, (state) => {
          state.userQuizzesDetails = {};
          state.apiStatus = API_FAILED;
        });
    
       
        builder.addCase(scoreAdd.pending, (state) => {
         
          state.scoreApiStatus = API_LOADING;
        });
        builder.addCase(scoreAdd.fulfilled, (state, { payload }) => {         
          state.result=payload.data.result;
          state.percentage=payload.data.percentage;
          state.scoreApiStatus = API_SUCCEEDED;
          
        });
        builder.addCase(scoreAdd.rejected, (state) => {
        
          state.scoreApiStatus = API_FAILED;
        });
        
        builder.addCase(setInitialScore.pending, (state) => {
          state.apiStatus = API_LOADING;
        });
        builder.addCase(setInitialScore.fulfilled, (state, { payload }) => {         
          state.updateId=payload.data.updateId;
          state.apiStatus = API_SUCCEEDED;
        });
        builder.addCase(setInitialScore.rejected, (state) => {
          state.apiStatus = API_FAILED;
        });
    
      },
})

export default learnerSlice;
export { learnerSlice };
export const {setSelectedQuizPath , setRenderQuiz, setQuizDetails,resetLearner } = learnerSlice.actions;