import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import EditUser from "./resourceManagement/EditUser";
import QuizHistory from "./userReport/QuizHistory";
import Hrp from "./userReport/Hrp";
import CheckList from "./userReport/Checklist";
import UserReport from "./userReport/UserReport";
import ResourceManagement from "./userReport/ResourceManagement";
import { setModule } from "../../../store/commonActions";


const UserManagementContent = (props:any) => {
  const {showEdit, userReport} = useAppSelector((store)=>store.userNavigation);
  const dispatch = useAppDispatch();
  useEffect(()=> {
    dispatch(setModule("UserManage"));
  },[])
  return (
    <>
      <div
        style={{
          display: "inline-block",
          width: props.isCollasped?"100%":"70%",
          height: "100%",
          borderRadius: "20px 0px 0px 20px "
        }}
      >
         
        {showEdit==="showUser" || showEdit==="editUser" || showEdit==="addUser"?<EditUser/>:null}
        {userReport==="quizHistory"?<QuizHistory/>:null}
        {userReport==="hrp"?<Hrp/>:null}
        {userReport==="checklist"?<CheckList/>:null}
        {showEdit==="userReport"?<UserReport/>:null}
        {showEdit==="resourceReport"?<ResourceManagement/>:null}
       
      </div>
    </>
  );
};

export default UserManagementContent;
