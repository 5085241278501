import { useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import QuizAssignConfirmModal from "./QuizAssignConfirmModal";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import {
  createAssignment,
  getallactivequizzes,
  getAreaSubarea1,
  getSearchUserData,
  checkQuizAssign,
} from "../../../../store/commonActions";
import Select from "react-select";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  ACTIVE_FLAG,
  ALREADY_ASSIGN_ERROR,
  API_SUCCEEDED,
  ASSIGN_ERROR,
  SEARCH_ERROR,
} from "../../../../utilities/helper/constants";
import { sessionGetter } from "../../../../utilities/helper/common";
import { MdDelete } from "react-icons/md";

interface AssignProps {
  title: any;
  assigneeName: string;
  assigneeId: string;
  masterSubareaId: string;
  area: string;
  subarea: string;
}

const MultipleAssign = () => {
  const [data, setData] = useState<any[]>([]);
  const initialState = {
    title: [],
    assigneeName: "",
    assigneeId: "",
    masterSubareaId: "",
    area: "",
    subarea: "",
  };
  const [allValues, setAllValues] = useState<AssignProps>(initialState);
  const [show, setShow] = useState(false);
  const [usersData, setUsersData] = useState<any[]>([]);
  const [activeQuizzes, setActiveQuizzes] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  // const [areaList, setAreaList] = useState<any>([]);
  // const [subareaList, setSubareaList] = useState<any>([]);
  // const [searchInput, setSearchInput] = useState<string>("");
  const { areaSlice, apiStatusArea, apiStatusSubarea, subareaSlice } =
    useAppSelector((store) => store.userNavigation);

  useEffect(() => {
    dispatch(
      getSearchUserData({
        searchText: "",
        page: 1,
        pageSize: 1000,
        status: ACTIVE_FLAG,
      })
    );
    dispatch(getallactivequizzes(sessionGetter('uid')));
  }, []);

  const { searchUserData, searchApiStatus } = useAppSelector(
    (store) => store.userNavigation
  );
  const {
    activeQuizzesList,
    apiStatusActiveQuizzesList,
    checkQuizAssignDependencyData,
    checkAlreadyQuizAssignApiData,
    checkQuizAssignData,
    checkQuizAssignApiStatus,
    checkQuizAssignMessage,
  } = useAppSelector((store) => store.quizzesNavigation);

  useEffect(() => {
    if (searchApiStatus === API_SUCCEEDED) {
      setUsersData(searchUserData);
    }
  }, [searchApiStatus]);

  useEffect(() => {
    if (apiStatusActiveQuizzesList === API_SUCCEEDED) {
      setActiveQuizzes(activeQuizzesList);
    }
  }, [apiStatusActiveQuizzesList]);

  useEffect(() => {
    dispatch(getAreaSubarea1({ status: ACTIVE_FLAG }));
  }, []);

  useEffect(() => {
    if (apiStatusArea === API_SUCCEEDED) {
      // const { data } = areaSlice;
      // setAreaList(data);
    }
  }, [apiStatusArea]);

  useEffect(() => {
    if (apiStatusSubarea === API_SUCCEEDED) {
      // const { data } = subareaSlice;
      // setSubareaList(data);
    }
  }, [apiStatusSubarea]);

  const changeHandler = async (e: any) => {
    let userDetails: any = await usersData.filter(
      (item: any) => item.lillyId === e.value
    );
    await setAllValues({
      ...allValues,
      assigneeName: e?.name,
      assigneeId: e?.value,
      masterSubareaId: userDetails[0].masterSubareaId,
      area: e?.area,
      subarea: e?.subarea,
    });
  };

  useEffect(() => {
    if (checkQuizAssignApiStatus === API_SUCCEEDED) {
      if (
        checkQuizAssignDependencyData.length !== 0 ||
        checkAlreadyQuizAssignApiData.length !== 0
      )
        toast.error(checkQuizAssignMessage);
     if (
        checkQuizAssignData.length !== 0
      ) {
        let assigableData=[];
        assigableData=checkQuizAssignData.map((obj1:any)=>{
          const assignableQuiz =allValues.title.find((obj2:any)=>obj2.value===obj1.quizmasterid)
          return assignableQuiz
        })
        let assignableUserData={...allValues,title:assigableData}     
        setData((current) => [...current, assignableUserData]);
        setErrorMessage("");
      }
    }
  }, [checkQuizAssignApiStatus]);

  const changeHandlerMuliti = (e: any) => {
    setAllValues({ ...allValues, title: e });
  };

  const handleAssign = async (e: any) => {
    if (
      allValues.assigneeId === "" ||
      allValues.assigneeName === "" ||
      allValues.title.length === 0
    ) {
      setErrorMessage(ASSIGN_ERROR);
    } else if (
      data.map((value) => value.assigneeId).indexOf(allValues.assigneeId) === -1
    ) {
      let arr: any = [];
      allValues.title.map((elm: any, key: any) => {
        var date = new Date();
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        date.setHours(date.getHours() - 4);
        date.setDate(date.getDate() + 30);
        var date1 = new Date();
        date1.setMinutes(date1.getMinutes() + date1.getTimezoneOffset());
        date1.setHours(date1.getHours() - 4);
        let obj = {
          area: allValues.area,
          assigneeId: allValues.assigneeId,
          createdAt: date1.toISOString(),
          createdBy: sessionGetter("uid"),
          dueDate: date.toISOString(),
          title: elm.label,
          quizmasterid: elm.value,
          remarks: "Insertion Remarks",
          subarea: allValues.subarea,
          masterSubareaId: allValues.masterSubareaId,
        };
        return arr.push(obj);
      });

      await dispatch(checkQuizAssign(arr));
    } else {
      setErrorMessage(ALREADY_ASSIGN_ERROR);
    }
  };
  const handleDelete = (row: any) => {
    setData(data.filter((value) => row !== value));
  };
  const handleSubmit = (e: any) => {
    let arr: any = [];
    data.map((item) => {
      return item.title.map((elm: any, key: any) => {
        var date = new Date();
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        date.setHours(date.getHours() - 4);
        date.setDate(date.getDate() + 30);
        var date1 = new Date();
        date1.setMinutes(date1.getMinutes() + date1.getTimezoneOffset());
        date1.setHours(date1.getHours() - 4);
        let obj = {
          area: item.area,
          assigneeId: item.assigneeId,
          createdAt: date1.toISOString(),
          createdBy: sessionGetter("uid"),
          dueDate: date.toISOString(),
          title: elm.label,
          quizmasterid: elm.value,
          remarks: "Insertion Remarks",
          subarea: item.subarea,
          masterSubareaId: item.masterSubareaId,
        };
        return arr.push(obj);
      });
    });
    dispatch(createAssignment(arr));
    setErrorMessage("");
    setShow(true);
  };

  const debounceEffect = (searchValue: string) => {
    if (searchValue.length <= 3) {
      toast.error(`${SEARCH_ERROR}`);
      return;
    }
    dispatch(
      getSearchUserData({
        searchText: searchValue,
        page: 1,
        pageSize: 100,
        status: ACTIVE_FLAG,
      })
    );
  };
  const debounceFn = useCallback(_.debounce(debounceEffect, 500), []);

  const handleInputChange = (searchValue: any) => {
    // setSearchInput(searchValue);
    if (searchValue === "") {
      dispatch(
        getSearchUserData({
          searchText: "",
          page: 1,
          pageSize: 1000,
          status: ACTIVE_FLAG,
        })
      );
      return;
    }
    debounceFn(searchValue);
    setUsersData(searchUserData);
  };

  return (
    <>
      <div style={{ height: "80vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <h4 className="title2">Multiple Assign</h4>
          <Button
            variant="secondary"
            style={{
              margin: "10px",
              backgroundColor: "#582630",
              height: "40px",
              width:"15%"
            }}
            onClick={handleAssign}
          >
            Add Assignee
          </Button>
          <Button
            variant="secondary"
            style={{
              margin: "10px",
              backgroundColor: "#582630",
              height: "40px",
            }}
            onClick={handleSubmit}
            disabled={data.length ? false : true}
          >
            Assign
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{ display: "inline-block", margin: "10px", width: "250px" }}
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Assignee Id</Form.Label>
              <Select
                isSearchable
                value={{
                  value: allValues.assigneeId,
                  label:
                    allValues.assigneeName.length !== 0 &&
                    allValues.assigneeId.length !== 0
                      ? `${allValues.assigneeName} (${allValues.assigneeId})`
                      : "",
                }}
                onInputChange={handleInputChange}
                options={usersData.map((data: any) => ({
                  value: data.lillyId,
                  name: data.name,
                  label: `${data.name} (${data.lillyId})`,

                  area: data.area,
                  subarea: data.subarea,
                }))}
                onChange={changeHandler}
              />
            </Form.Group>
          </div>
          <div
            style={{ display: "inline-block", margin: "10px", width: "280px" }}
          >
            <Form.Group
              className="mb-3 quiz"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Quiz Categories</Form.Label>
              <Select
                isMulti
                isSearchable
                value={allValues.title}
                name="title"
                placeholder="Select"
                options={activeQuizzes.map((data: any) => ({
                  value: data.id,
                  label: data.title
                }))}
                onChange={changeHandlerMuliti}
              />
            </Form.Group>
          </div>
        </div>
        {errorMessage ? (
          <p style={{ color: "red", marginLeft: "30px" }}>{errorMessage}</p>
        ) : null}

        {show ? (
          <QuizAssignConfirmModal
            show={show}
            setShow={setShow}
            setData={setData}
            setAllValues={setAllValues}
            initialState={initialState}
          />
        ) : null}
        {data.length !== 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              width: "95%",
              margin: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px",
                borderRadius: "10px",
                color: "#000",
              }}
            >
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "700" }}>
                  Action
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "700" }}>Name</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "700" }}>
                  Assignee ID
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "700" }}>
                  Quiz Category
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "700" }}>Area</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "18%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "700" }}>
                  Subarea
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div
          style={{
            height: "70%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginLeft: "2%",
          }}
        >
          {data.map((quiz: any, key: any) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0px",
              }}
              key={key}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  background:
                    "linear-gradient(rgb(111, 247, 232), rgb(31, 126, 161))",
                  margin: "10px",
                  height: "55px",
                  width: "95%",
                  borderRadius: "10px",
                  color: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "10%",
                  }}
                >
                  <MdDelete 
                    style={{
                      fontSize: "30px",
                      marginLeft: "25px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(quiz)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "18%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "4px",
                      width: "80%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontWeight: "bold",
                    }}
                    title={quiz.assigneeName}
                  >
                    {quiz.assigneeName}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "18%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "4px",
                      width: "80%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontWeight: "bold",
                    }}
                    title={quiz.assigneeId}
                  >
                    {quiz.assigneeId}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "18%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "4px",
                      width: "80%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontWeight: "bold",
                    }}
                    title= {quiz.title.map((title: any, index: number) => `${title.label}` )}
                  >
                    {quiz.title.map((title: any, index: number) => {return index === quiz.title.length-1 ?`${title.label}`: `${title.label},` })}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "18%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "4px",
                      width: "80%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontWeight: "bold",
                    }}
                    title={quiz.area}
                  >
                    {quiz.area}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "18%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "4px",
                      width: "80%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontWeight: "bold",
                    }}
                    title={quiz.subarea}
                  >
                    {quiz.subarea}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MultipleAssign;
