import { Card } from "react-bootstrap";
import "./Content.css";
import { CHANGE_MANAGE_LINK, CONFIG_MANAGE_LINK, INCIDENT_MANAGE_LINK, MAJOR_INCIDENT_LINK, PROBLEM_MANAGE_LINK, REQUEST_FULLFILLMENT_LINK } from "../../../../utilities/helper/constants";

const ITCommon = () => {
  return (
    <>
      <Card className="cardStyle">
       
        <div
         className="card-outerdiv"
        >
          <div
          className="card-innerdiv"
          >
            <ul>
              <Card className="ITCommon">
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={CONFIG_MANAGE_LINK}
                >
                  Configuration Management
                </a>
              </Card>
              <Card className="ITCommon">
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={INCIDENT_MANAGE_LINK}
                >
                  Incident Management
                </a>
              </Card>
              <Card className="ITCommon">
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={PROBLEM_MANAGE_LINK}
                >
                  Problem Management
                </a>
              </Card>
              <Card className="ITCommon">
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={CHANGE_MANAGE_LINK}
                >
                  Change Management
                </a>
              </Card>
              <Card className="ITCommon">
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={MAJOR_INCIDENT_LINK}
                >
                  Major Incident Process
                </a>
              </Card>
              <Card className="ITCommon">
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={REQUEST_FULLFILLMENT_LINK}
                >
                  Request Fullfillment Prcoess SOP
                </a>
              </Card>
            </ul>
          </div>
        </div>
      </Card>
    </>
  );
};
export default ITCommon;
