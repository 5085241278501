import HrpContent from "./HrpContent";
import Nav from "./Nav";

const Hrp = () => {
    return (
      <>
        <div
        className="content-area"
      >
        <Nav />
        <HrpContent />
      </div>
      </>
    );
  };
  export default Hrp;