import { AuthProvider } from "@elilillyco/spa_auth";
import appConfig from "./Config";
import { sessionSetter } from "./utilities/helper/common";

const config = {
  clientId: appConfig.CLIENT_ID,
  openIdCallback: "/",
  scopes: ["openid", "profile", "user.read"],
  isGatewayScopeRequired: true,
  gatewayScopeType: "production",
  logLevel: "INFO",
};

export const authProvider = new AuthProvider(config);

var isUserLoggedIn = null;
export const processLogin = async () => {
  // Actions you want to perform when the user is successfully authenticated.
  const { accessToken } = await authProvider.getGatewayAccessToken();
  const { name, idTokenClaims, username } =
  await authProvider.getIdTokenClaims();
  sessionSetter("uid", idTokenClaims?.uid);
  sessionSetter("userName", name);
  sessionSetter("email", username);
  sessionSetter("token", accessToken);
  const isRoleAssigned = "DEV";
  if (isRoleAssigned) {
    isUserLoggedIn = true;
  } else {
    isUserLoggedIn = false;
  }
};

const LoginHandlers = async () => {
  // registering the auth callback handler
  // you need to pass a callback function which will get called when the user logs in successfully, you may use this function to alter the state to authenticated, make changes to state of the app.
  await authProvider.handlePromiseRedirectCallback(processLogin);
  return isUserLoggedIn;
};

export default LoginHandlers;
