import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import TeamAssign from "./quizAssignment/TeamAssign";
import MultipleAssign from "./quizAssignment/MultipleAssign";
import DefaultersReport from "./learnerHistory/DefaultersReport";
import LearnersReport from "./learnerHistory/LearnersReport";
import QuizHome from "./new quizManagement/create quiz/QuizHome";
import QuizTitle from "./new quizManagement/create quiz/QuizTitle";
import ModifyQuiz from "./new quizManagement/modify quiz/ModifyQuiz";
import AddQuestion from "./new quizManagement/modify quiz/AddQuestion";
import { setModule } from "../../../store/commonActions";

const QuizzesContent = (props:any) => {
  const {selectedAssignPath, editQuizDetails} = useAppSelector((store)=> store.quizzesNavigation)
  const dispatch = useAppDispatch();
  useEffect(()=> {
    dispatch(setModule("Quizzes"))
  },[])
  return (
    <>
      <div
        style={{
          display: "inline-block",
          width: props.isCollasped?"100%":"70%",
          height: "100%",
          overflowY:"hidden",
          borderRadius: "20px 0px 0px 20px ",
          overflowX:"hidden"
        }}
      > 
      {selectedAssignPath==="Learner"?<LearnersReport  isCollasped={props.isCollasped} setIssCollasped={props.setIssCollasped} />:null}
      {selectedAssignPath==="Defaulter"?<DefaultersReport isCollasped={props.isCollasped} setIssCollasped={props.setIssCollasped}/>:null}
      {selectedAssignPath==="team"?<TeamAssign/>:null}
      {selectedAssignPath==="multiple"?<MultipleAssign/>:null}
      {selectedAssignPath==="AddQuestion"?<AddQuestion id={editQuizDetails.id} title={editQuizDetails.title}/>:null}
      {selectedAssignPath==="createQuiz"|| selectedAssignPath==="createQuestion"?<QuizTitle/>:null}
      {selectedAssignPath==="showQuiz"|| selectedAssignPath==="EditQuestions"?<ModifyQuiz/>:null}
      {selectedAssignPath==="editQuiz"?<QuizHome/>:null}
      </div>
    </>
  );
};

export default QuizzesContent;
