import { Card } from "react-bootstrap";
import { CSV_HUB_LINK, CYBER_ARK_LINK, FAQ_LINK, IT_SYSTEM_CONTROL_LINK, MY_ACCESS_LINK, MY_PASSWORD_LINK, PROTECT_LINK, SNOW_LINK } from "../../../../utilities/helper/constants";
const UsefulInfo = () => {
  return (
    <>
      <Card className="cardStyle"
      >
        <div
         className="card-outerdiv"
        >
          <div
          className="card-innerdiv"
          >
            <ul>
              <Card className="Useful-info">
                <a
                  rel="noreferrer noopener"
                  href={MY_PASSWORD_LINK}
                  target="_blank"
                >
                  My Password
                </a>
              </Card>
              <Card className="Useful-info">
                <a
                  rel="noreferrer noopener"
                  href={PROTECT_LINK}
                  target="_blank"
                >
                  Protect Lilly
                </a>
              </Card>
              <Card className="Useful-info">
                <a
                  rel="noreferrer noopener"
                  href={CSV_HUB_LINK}
                  target="_blank"
                >
                  Computer System Validation (CSV) Hub
                </a>
              </Card>
              <Card className="Useful-info">
                <a
                  rel="noreferrer noopener"
                  href={MY_ACCESS_LINK}
                  target="_blank"
                >
                  My Access
                </a>
              </Card>
              <Card className="Useful-info">
                <a
                  rel="noreferrer noopener"
                  href={CYBER_ARK_LINK}
                  target="_blank"
                >
                  CyberArk
                </a>
              </Card>
              <Card className="Useful-info">
                <a
                  rel="noreferrer noopener"
                  href={SNOW_LINK}
                  target="_blank"
                >
                  Service Now (SNOW)
                </a>
              </Card>
              <Card className="Useful-info">
                <a
                  rel="noreferrer noopener"
                  href={IT_SYSTEM_CONTROL_LINK}
                  target="_blank"
                >
                  IT System Control Expectation: Red CCI
                </a>
              </Card>
              <Card className="Useful-info">
                <a
                  rel="noreferrer noopener"
                  href={FAQ_LINK}
                  target="_blank"
                >
                  FAQs - IT Control Expectations Systems with Red CCI
                </a>
              </Card>
            </ul>
          </div>
        </div>
      </Card>
    </>
  );
};
export default UsefulInfo;
