// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-style{
    width: 250px;
    float: right;
    margin: 10px;
}

.container-div-status {
    display: flex;
    flex-direction: row;
}

.status-style-modify{
    width: 150px;
}

.error {
    width:"100%";
    margin: auto;
}

.title-style{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width:80%;
}`, "",{"version":3,"sources":["webpack://./src/components/modules/quizzes/new quizManagement/modify quiz/Modify.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;AACX","sourcesContent":[".status-style{\n    width: 250px;\n    float: right;\n    margin: 10px;\n}\n\n.container-div-status {\n    display: flex;\n    flex-direction: row;\n}\n\n.status-style-modify{\n    width: 150px;\n}\n\n.error {\n    width:\"100%\";\n    margin: auto;\n}\n\n.title-style{\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  width:80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
