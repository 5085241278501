import {
  setShowChecklistUserDetails,
  setShowChecklistUserType,
  setShowHrpContent,
  resetHrp,
} from "./slices/hrpSlice";
import {
  showUserDetails,
  setShowEditType,
  setSelectedUser,
  resetuser,
  getUserInfo,
  createUser,
  updateUser,
  getRoles,
  setUserReport,
} from "./slices/userSlice";
import { setTraining, getRole } from "./slices/trainingSlice";
import {
  setEditableQuestion,
  setQuestionDetails,
  setQuizesDetails,
  setQuestionNav,
  setQuestionIndex,
  setDeleteQuestion,
  setDeleteQuestionIndex,
  setEditQuestion,
  setEditQuestionIndex,
  setSelectedAssignNav,
  setCreatedQuestion,
  setSelectedQuizNav,
  setEditQuizDetails,
  setEnableEdit,
  setQuestionFlag,
  resetquizzes,
  createQuiz,
  getAllQuizzesByOwner,
  updateQuiz,
  getAllQuizOwner,
  getallqueslist,
  updateQuestion,
  addQues,
  getallactivequizzes,
  createAssignment,
  userStatusTableArea,
  userStatusTableSubarea,
  assigndeleteId,
  quizAreaSubareaDefaulter,
  checkQuizAssign,
  setCreateQuizNavFlag,
  setQuizStatus
} from "./slices/quizzesSlice";
import {
  setSelectedQuizPath,
  setRenderQuiz,
  setQuizDetails,
  resetLearner,
  getassignedquiz,
  userquizList,
  scoreAdd,
  setInitialScore,
} from "./slices/learnerSlice";
import { setModule } from "./slices/navSlice";
import {
  getAreaSubarea1,
  getSubarea1,
  getSearchUser,
  getSearchUserData,
  excelApiSampleData,
  excelUserCreateOrUpdate,
} from "./slices/userSlice";
import {
  createAreaSubarea,
  getAreaSubarea,
  updateCurrentAreaSubarea,
  getSelectedSubarea,
  resetAreaSubarea,
  resetStatus
} from "./slices/areaSubareaSlice";

export {
  setShowChecklistUserDetails,
  setShowChecklistUserType,
  setShowHrpContent,
  resetHrp,
  showUserDetails,
  setShowEditType,
  setSelectedUser,
  resetuser,
  getUserInfo,
  createUser,
  updateUser,
  getRoles,
  setUserReport,
  setTraining,
  setEditableQuestion,
  setQuestionDetails,
  setQuizesDetails,
  setQuestionNav,
  setQuestionIndex,
  setDeleteQuestion,
  setDeleteQuestionIndex,
  setEditQuestion,
  setEditQuestionIndex,
  setSelectedAssignNav,
  setCreatedQuestion,
  setSelectedQuizNav,
  setEditQuizDetails,
  setEnableEdit,
  setQuestionFlag,
  resetquizzes,
  createQuiz,
  setCreateQuizNavFlag,
  getAllQuizzesByOwner,
  updateQuiz,
  getAllQuizOwner,
  getallqueslist,
  updateQuestion,
  addQues,
  getallactivequizzes,
  createAssignment,
  userStatusTableArea,
  userStatusTableSubarea,
  assigndeleteId,
  quizAreaSubareaDefaulter,
  setSelectedQuizPath,
  setRenderQuiz,
  setQuizDetails,
  resetLearner,
  getassignedquiz,
  userquizList,
  scoreAdd,
  setInitialScore,
  getRole,
  setModule,
  getAreaSubarea1,
  getSubarea1,
  getSearchUser,
  getSearchUserData,
  checkQuizAssign,
  excelApiSampleData,
  excelUserCreateOrUpdate,
  createAreaSubarea,
  getAreaSubarea,
  updateCurrentAreaSubarea,
  getSelectedSubarea,
  setQuizStatus,
  resetAreaSubarea,
  resetStatus
};
