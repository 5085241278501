import { Fragment, ReactNode, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import TakeQuiz from "./components/modules/learners/quizPage/TakeQuiz";
import Result from "./components/modules/learners/Result";
import ModuleNavigation from "./components/homePage/ModuleNavigation";
import Learners from "./components/modules/learners/Learners";
import Trainings from "./components/modules/trainings/Trainings";
import Quizzes from "./components/modules/quizzes/Quizzes";
import Hrp from "./components/modules/hrp/Hrp";
import UserManagement from "./components/modules/userManagement/UserManagement";
import LoginHandlers, { authProvider, processLogin } from "./LoginHandlers";

import { FaSpinner } from "react-icons/fa";
import AreaSubarea from "./components/modules/areaSubarea/AreaSubarea";

import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "./hooks/redux-hooks";
import { getRole } from "./store/commonActions";
import { API_FAILED, API_SUCCEEDED, ROUTE_ACCESS } from "./utilities/helper/constants";
import { checkRoles, sessionGetter } from "./utilities/helper/common";

const App = () => {
  const dispatch = useAppDispatch();
  const { userRole, apiStatus } = useAppSelector(
    (store) => store.trainingNavigation
  );
  const [state, setState] = useState({
    isLoggedIn: false,
    isLoading: true,
    restrictUser: false,
  });

  useEffect(() => {
    (async () => {
      let isUserAuthenticated = await authProvider.isLoggedIn();
      if (isUserAuthenticated) {
        await processLogin();
        checkUserAuthRole();
      } else {
        await LoginHandlers();
        isUserAuthenticated = await authProvider.isLoggedIn();
        if (isUserAuthenticated) {
          await processLogin();
          checkUserAuthRole();
        } else {
          setState({ ...state, isLoggedIn: false, isLoading: true });
        }
      }
    })();
  }, []);

  const checkUserAuthRole = async () => {
    try {

      await dispatch(getRole(sessionGetter("uid")));
    } catch (error) {
      setState({
        ...state,
        isLoggedIn: false,
        restrictUser: true,
        isLoading: false,
      });
      throw error;
    }
  };
 useEffect(() => {
    if (apiStatus === API_SUCCEEDED || apiStatus === API_FAILED)
     { if (userRole === "[]" || !checkRoles(JSON.parse(userRole))) {
        setState({
          ...state,
          restrictUser: true,
          isLoggedIn: true,
          isLoading: false,
        });
      } else
        setState({
          ...state,
          isLoggedIn: false,
          restrictUser: false,
          isLoading: false,
        });}
  }, [apiStatus]);
  
  function RolesAuthRoute({
    children,
    roles,
  }: {
    children: ReactNode;
    roles: Array<(typeof userRole)[number]>;
  }) {
    const userRole1 = JSON.parse(userRole);
    const canAccess = userRole1?.some((userRole: any) =>
      roles.includes(userRole)
    );

    if (canAccess) return <Fragment>{children}</Fragment>;

    return <Navigate to="/" />;
  }

  return state.isLoading ? (
    <div className="loader">
      <FaSpinner />
    </div>
  ) :( state.restrictUser ? (
    <div className="unauthorized_Error">
      <h1>Unauthorized Access</h1>
      <p>
        You are not authorized to use this application. <br />
        Please raise access request through myAccess.
      </p>
    </div>
  ) : (
    <div className="iselectApp">
      <div className="background">
        <div className="innerBackground">
          <BrowserRouter>
            <ModuleNavigation />
            <div className="contentNav">
              <Routes>
                <Route
                  path="/trainings"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.trainings}>
                      <Trainings />
                    </RolesAuthRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.trainings}>
                      <Trainings />
                    </RolesAuthRoute>
                  }
                />
                <Route
                  path="/learners"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.learners}>
                      <Learners />
                    </RolesAuthRoute>
                  }
                />

                <Route
                  path="/quiz"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.quizzes}>
                      <Quizzes />
                    </RolesAuthRoute>
                  }
                />

                <Route
                  path="/usermanage"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.usermanage}>
                      <UserManagement />
                    </RolesAuthRoute>
                  }
                />
                <Route
                  path="/hrp"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.hrp}>
                      <Hrp />
                    </RolesAuthRoute>
                  }
                />

                <Route
                  path="/TakeQuiz"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.learners}>
                      <TakeQuiz />
                    </RolesAuthRoute>
                  }
                />
                <Route
                  path="/Result"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.learners}>
                      <Result />
                    </RolesAuthRoute>
                  }
                />
                <Route
                  path="/areaSubarea"
                  element={
                    <RolesAuthRoute roles={ROUTE_ACCESS.usermanage}>
                      <AreaSubarea />
                    </RolesAuthRoute>
                  }
                />
              </Routes>
            </div>
          </BrowserRouter>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          limit={1}
        />
      </div>
    </div>
  ));
};
export default App;