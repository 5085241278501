// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-input {
    width: 85%;
    margin: 1% 5% 5% 1%;
}

.option-input {
    width: 82%;
    margin: 1%
}

.delete-button {
    font-size: 25px;
    cursor: pointer;
}

.button-container {
    margin: 1%;
}

.checkbox {
    border: 2px solid black;
}
`, "",{"version":3,"sources":["webpack://./src/components/modules/quizzes/new quizManagement/create quiz/QuizHome.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV;AACJ;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".question-input {\n    width: 85%;\n    margin: 1% 5% 5% 1%;\n}\n\n.option-input {\n    width: 82%;\n    margin: 1%\n}\n\n.delete-button {\n    font-size: 25px;\n    cursor: pointer;\n}\n\n.button-container {\n    margin: 1%;\n}\n\n.checkbox {\n    border: 2px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
