import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import {
  getAllQuizzesByOwner,
  getallqueslist,
  setEditQuestionIndex,
  setEditQuizDetails,
  setSelectedAssignNav,
  setQuestionFlag,
  setCreateQuizNavFlag,
  setQuizStatus,
} from "../../../../store/slices/quizzesSlice";
import Button from "react-bootstrap/esm/Button";
import { useEffect, useRef, useState } from "react";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import "./ShowEditQuizNav.css";
import "../../../../utilities/Loader/NavLoader.css";
import { SiMusicbrainz } from "react-icons/si";
import { Form } from "react-bootstrap";
import CreatedQuestionNav from "./create quiz/CreatedQuestionNav";
import ModifyQuestionNav from "./modify quiz/ModifyQuestionNav";
import {
  ACTIVE_FLAG,
  API_LOADING,
  API_SUCCEEDED,   
  INACTIVE_FLAG,
} from "../../../../utilities/helper/constants";
import {
  Search,
  sessionGetter,
  sessionSetter,
} from "../../../../utilities/helper/common";
import { IoMdAddCircle } from "react-icons/io";

const ShowEditQuizNav = (props: any) => {
  const dispatch = useAppDispatch();
  const { updateApiStatus, editQuizDetails, apiStatus, selectedQuizNav } = useAppSelector(
    (store) => store.quizzesNavigation
  );
  const {setSelectedNav} = props;

  const handleShow = async (quiz: Object) => {
    await dispatch(setEditQuizDetails(quiz));
  };
  const firstUpdate = useRef(true);
  const [status, setStatus] = useState<string>(ACTIVE_FLAG);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (firstUpdate.current) {
      const obj = {
        status: status,
        lillyId: sessionGetter("uid"),
      };
      dispatch(getAllQuizzesByOwner(obj));
      setData(allQuizzesByOwner);
    }
  }, [status]);

  useEffect(()=> {
    if(selectedQuizNav==="management")
    {
      setSelectedNav("management")
      const obj = {
        status: status,
        lillyId: sessionGetter("uid"),
      };
      dispatch(getAllQuizzesByOwner(obj));
      setData(allQuizzesByOwner);
    }
  },[selectedQuizNav])
  const { allQuizzesByOwner, selectedAssignPath, createQuizNavFlag } =
    useAppSelector((store) => store.quizzesNavigation);

  useEffect(() => {
    sessionSetter("QuizStatus", status);
  }, [status]);

  useEffect(() => {
    if (updateApiStatus === API_SUCCEEDED) {
      const obj = {
        status: status,
        lillyId: sessionGetter("uid"),
      };
      dispatch(getAllQuizzesByOwner(obj));
    }
  }, [updateApiStatus]);

  useEffect(() => {
    setData(allQuizzesByOwner);
  }, [allQuizzesByOwner]);

  const [searchInput, setSearchInput] = useState<any>("");
  const [searchParam] = useState(["title", "createdBy", "maxQuestion"]);


  return (
    <>
      {selectedAssignPath !== "EditQuestions" &&
      selectedAssignPath !== "AddQuestion" &&
      selectedAssignPath !== "createQuestion" ? (
        <div>
          <Button
            style={{
              margin: "1%",
              float: "left",
              width: "150px",
              /*backgroundColor: "#A25035",*/
              marginLeft: "1%",
              background: "none",
              border: "none",
              color: "rgb(85, 83, 83)",
              fontSize: "18px",
              fontWeight: "600",
            }}
            onClick={() => {
              dispatch(setCreateQuizNavFlag(""));
              props.selectedMode("createQuiz");
              dispatch(setEditQuizDetails({}));
              dispatch(setSelectedAssignNav("createQuestion"));
            }}
          >
            <AiOutlineAppstoreAdd style={{ fontSize: "25px" }} />
            &nbsp; Create
          </Button>
        </div>
      ) : null}

      {selectedAssignPath === "EditQuestions" ||
      selectedAssignPath === "AddQuestion" ? (
        <>
          <Button
            variant="secondary"
            style={{
              margin: "2%",
              float: "left",
              width: "45%",
              // backgroundColor: "#A25035",
              marginLeft: "5%",
              background: "none",
              border: "none",
              color: "rgb(85, 83, 83)",
              fontSize: "15px",
              fontWeight: "600",
            }}
            onClick={() => {
              dispatch(setSelectedAssignNav("AddQuestion"));
              dispatch(setEditQuestionIndex(-1));
            }}
            type="submit"
          >
            <IoMdAddCircle style={{ fontSize: "25px", marginBottom: "3px" }} />
            Add Question
          </Button>
          <Form.Select
            className="details-style"
            aria-label="Default select example"
            name="status"
            onChange={(e) => {
              dispatch(
                getallqueslist({
                  id: editQuizDetails.id,
                  status: e.target.value,
                })
              );
              dispatch(setQuestionFlag(e.target.value));
            }}
            required
          >
            <option value={ACTIVE_FLAG}>Active</option>
            <option value={INACTIVE_FLAG}>Inactive</option>
          </Form.Select>{" "}
          <ModifyQuestionNav />
        </>
      ) : null}
      {selectedAssignPath === "createQuestion" &&
      createQuizNavFlag === "createNavFlag" ? (
        <CreatedQuestionNav />
      ) : null}
      {selectedAssignPath !== "AddQuestion" &&
      selectedAssignPath !== "EditQuestions" &&
      selectedAssignPath !== "createQuestion" ? (
        <>
          <Form.Select
            className="details-style"
            aria-label="Default select example"
            name="status"
            value={status}
            style={{marginRight:"4%"}}
            onChange={(e) => {
              setStatus(e.target.value);
              dispatch(setQuizStatus(e.target.value))
            }}
            required
          >
            <option value={ACTIVE_FLAG}>Active</option>
            <option value={INACTIVE_FLAG}>Inactive</option>
            <option value="ALL">All</option>
          </Form.Select>
          <div style={{ display: "block" }}>
            <Form.Control
              type="search"
              placeholder="🕵️‍♂️ Search"
              style={{
                margin: "2%",
                marginLeft: "3%",
                width: "93%",
                display: "block",
              }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          {apiStatus === API_LOADING ? (
            <div className="loader-wrapper">
              <div className="loader-circle"></div>
            </div>
          ) : Search(data, searchInput, searchParam).length === 0 ? (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              No result to display
            </div>
          ) : (
            <div className="wrapper-div">
              <div className="quizzes-nav">
                {Search(data, searchInput, searchParam).map(
                  (quiz: any, key: any) => (
                    <div
                      style={{
                        width: "95%",
                        height: "65px",
                        // backgroundColor: selected === quiz ? "#A25035" : "#fff",
                        background:
                          editQuizDetails.id === quiz.id
                            ? "linear-gradient(#b1f085, #1ca97b)"
                            : "#fff",
                        // color: selected === quiz ? "#fff" : "#000",
                        borderRadius: "8px",
                        margin: "5px 2%",
                        cursor: "pointer",
                      }}
                      title={`${quiz.title}`}
                      onClick={() => {
                        handleShow(quiz);
                        dispatch(setSelectedAssignNav("showQuiz"));
                        // setSelected(quiz);
                      }}
                      key={key}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                          boxShadow:
                            "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                          padding: "4px 10px",
                        }}
                      >
                        {/* Left div */}
                        <div
                          style={{
                            display: "inline-block",
                            width: "19%",
                            height: "100%",
                          }}
                        >
                          <SiMusicbrainz
                            style={{
                              margin: "auto",
                              marginTop: "5px",
                              padding: "0px 4px",
                              fontSize: "50px",
                            }}
                          />
                        </div>
                        {/* right div  */}
                        <div
                          style={{
                            display: "inline-block",
                            width: "71%",
                            height: "100%",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          <strong>{quiz.title}</strong>
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color:
                                editQuizDetails.id === quiz.id
                                  ? "#fff"
                                  : "grey",
                            }}
                          >
                            Status:{" "}
                            {quiz.quizStatus === ACTIVE_FLAG
                              ? "Active"
                              : quiz.quizStatus === INACTIVE_FLAG
                              ? "Inactive"
                              : null}
                            &nbsp;&nbsp;| &nbsp;&nbsp;Created By:{" "}
                            {quiz.createdBy}
                            <br />
                            <div
                              style={{
                                display: "inline-block",
                                width:
                                  quiz.quizStatus === INACTIVE_FLAG
                                    ? "88px"
                                    : "79.5px",
                              }}
                            >
                              Test Ques: {quiz.maxQuestion}
                            </div>
                            |
                            <div
                              style={{
                                display: "inline-block",
                                width: "80px",
                                marginLeft: "10px",
                              }}
                            >
                              Total Active Ques: {quiz.questionDetails.length}
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}

          <div
            style={{
              position: "absolute",
              top: "96%",
              marginLeft: "25%",
              width: "70%",
              display: "block",
              fontWeight: "500",
              fontSize: "12px",
              color: "grey",
            }}
          >
            {Search(data, searchInput, searchParam).length} Results
          </div>
        </>
      ) : null}
    </>
  );
};

export default ShowEditQuizNav;
