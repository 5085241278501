import { Button, Form } from "react-bootstrap";
import { useAppSelector } from "../../../../../hooks/redux-hooks";
import Multiselect from "multiselect-react-dropdown";
import { IoIosSave } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import "../ShowEditQuizNav.css";
import "./Modify.css";
import { useAppDispatch } from "../../../../../hooks/redux-hooks";
import {
  getAllQuizOwner,
  getAllQuizzesByOwner,
  setSelectedAssignNav,
  updateQuiz,
  setEditQuestionIndex,
  getallqueslist,
} from "../../../../../store/slices/quizzesSlice";
import "../ShowQuiz.css";
import { useEffect, useState } from "react";
import ModifyQuestion from "./ModifyQuestion";
import AddQuestion from "./AddQuestion";
import { toast } from "react-toastify";
import {
  ACTIVE_FLAG,
  API_SUCCEEDED,
  INACTIVE_FLAG,
  QUIZ_EDIT_SUCCESS,
  QUIZ_OWNER_ERROR,
  TEST_QUES,
} from "../../../../../utilities/helper/constants";
import {
  convertDate,
  sessionGetter,
} from "../../../../../utilities/helper/common";

interface Props {
  quizStatus: string;
  quizOwner: Array<string>;
  maxQuestion: string;
  frequency: string;
  lastAssignmentDate: string;
  id: string;
  title: string;
  quizOwnerObject: Array<Object>;
}

interface QuestionProps {
  ques: string;
  ans: string;
  questionTy: string;
  options: string;
  id: number;
  status: string;
}

const ModifyQuiz = () => {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState<String>("FrontPage");
  const [validated, setValidated] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [quizOwnerError, setQuizOwnerError] = useState("");
  const [maxQuestionError, setMaxQuestionError] = useState("");
  const {
    editQuizDetails,
    quizList,
    quizOwner,
    editQuestionIndex,
    apiUpdateQues,
    quizOwnerApiStatus,
    quizOwnerArray,
    updateApiStatus,
    selectedAssignPath,
  } = useAppSelector((store) => store.quizzesNavigation);
  const [allValues, setAllValues] = useState<Props>({
    quizOwner: [],
    maxQuestion: "",
    quizStatus: "",
    frequency: "",
    lastAssignmentDate: "",
    id: "",
    title: "",
    quizOwnerObject: [],
  });
  const [allQuizOwner, setAllQuizOwner] = useState<any>([]);
  const [navStatus, setNavStatus] = useState(editQuizDetails.quizStatus);
  const [questionObjectValues, setQuestionObjectValues] =
    useState<QuestionProps>({
      ques: "",
      ans: "",
      questionTy: "multiple",
      options: "",
      id: -1,
      status: "",
    });
  useEffect(() => {
    dispatch(getallqueslist({ id: editQuizDetails.id, status: ACTIVE_FLAG }));
    dispatch(setEditQuestionIndex(-1));
    setValidated(false);
    setQuizOwnerError("");
    setMaxQuestionError("");
    // setEdit(true);
  }, [editQuizDetails]);
  useEffect(() => {
    if (updateApiStatus === API_SUCCEEDED) {
      toast.success(QUIZ_EDIT_SUCCESS);
      // setAllValues({
      //   quizOwner: [],
      //   maxQuestion: "",
      //   quizStatus: "",
      //   frequency: "",
      //   lastAssignmentDate: "",
      //   id: "",
      //   title: "",
      //   quizOwnerObject: [],
      // });
    }
  }, [updateApiStatus]);

  useEffect(() => {
    dispatch(getAllQuizOwner());
    dispatch(setEditQuestionIndex(-1));
  }, []);

  useEffect(() => {
    if (apiUpdateQues === API_SUCCEEDED) {
      setEditQuestionIndex(-1);
    }
  }, [apiUpdateQues]);

  useEffect(() => {
    if (quizList.length !== 0) {
      setQuestionObjectValues({
        ques: quizList[editQuestionIndex]?.questions,
        ans: quizList[editQuestionIndex]?.answers,
        questionTy: quizList[editQuestionIndex]?.questiontype,
        options: quizList[editQuestionIndex]?.options,
        id: quizList[editQuestionIndex]?.question_id,
        status: quizList[editQuestionIndex]?.status,
      });
    }
  }, [editQuestionIndex, quizList]);

  useEffect(() => {
    if (quizOwnerApiStatus === API_SUCCEEDED) {
      const { data } = quizOwner;
      setAllQuizOwner(data);
    }
  }, [quizOwnerApiStatus]);

  const [flag, setflag] = useState(false);

  useEffect(() => {
    setShow("FrontPage");
  }, [editQuizDetails]);

  useEffect(() => {
    if (selectedAssignPath === "EditQuestions") {
      setShow("EditQuestions");
      dispatch(setEditQuestionIndex(editQuestionIndex));
    }
  }, [selectedAssignPath]);

  useEffect(() => {
    setAllValues({
      ...editQuizDetails,
      quizOwnerObject: quizOwnerArray,
    });
    setflag(!flag);
  }, [editQuizDetails, quizOwnerArray]);

  useEffect(() => {
    setNavStatus(editQuizDetails.quizStatus);
  }, [flag]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(true);
    if (buttonName === "Edit Question") {
      setShow("EditQuestions");
    }
    if (form.checkValidity()) {
      if (buttonName === "Add Question") {
        setShow("AddQuestion");
        dispatch(setSelectedAssignNav("AddQuestion"));
      } else if (buttonName === "Save") {

        if (allValues.maxQuestion <= quizList.length) {      
          setMaxQuestionError("");
          if ( allValues.quizOwner.length!=0 ) {
            handleSave();
            setQuizOwnerError("");
          }
          else{
            setQuizOwnerError(QUIZ_OWNER_ERROR);
          }         
        } else {
          setMaxQuestionError(TEST_QUES);
        }
      }
    }
  };

  const handleSave = async () => {
    let obj = {
      quizOwner: allValues.quizOwner,
      maxQuestion: allValues.maxQuestion,
      quizStatus: allValues.quizStatus,
      frequency: allValues.frequency,
      lastAssignmentDate: allValues.lastAssignmentDate,
      id: allValues.id,
      title: allValues.title,
    };
    await dispatch(updateQuiz(obj));
    const obj1 = {
      status: navStatus,
      lillyId: sessionGetter("uid"),
    };
    dispatch(getAllQuizzesByOwner(obj1));
    dispatch(setSelectedAssignNav("showQuiz"));
    // dispatch(setSelectedAssignNav(""));
  };

  const onSelectQuizOwner = (selectedList: any) => {
    const liilyIds = selectedList.map((elm: any, key: any) => elm.lillyId);
    setAllValues({
      ...allValues,
      quizOwner: liilyIds,
      quizOwnerObject: selectedList,
    });
    setQuizOwnerError("");
  };
  const onRemoveQuizOwner = (selectedList: any) => {
    const liilyIds = selectedList.map((elm: any, key: any) => elm.lillyId);
    setAllValues({
      ...allValues,
      quizOwner: liilyIds,
      quizOwnerObject: selectedList,
    });
    if (selectedList.length === 0) {
      setQuizOwnerError(QUIZ_OWNER_ERROR);
    }
  };
  const handleChange = async (e: any) => {
    if(e.target.name === 'frequency'){
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.slice(0,2),
      });
      return;
    }
    setAllValues({
      ...allValues,
      [e.target.name]: e.target.value.trimStart(),
    });
  };

  let genQuestions = [];

  for (let i = 1; i <= 6; i++) {
    genQuestions.push(i * 5);
  }

  return (
    <div style={{ height: "100%" }}>
      {show === "FrontPage" ? (
        <div style={{ height: "98%", overflow: "auto" }}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div
              style={{
                backgroundColor: "#582630",
                height: "40px",
                borderRadius: "5px",
                width: "90%",
                margin: "40px auto 10px auto",
              }}
            >
              <h5
                style={{
                  display: "inline-block",
                  float: "left",
                  marginTop: "5px",
                  marginLeft: "10px",
                  color: "white",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "90%",
                }}
                title={allValues.title}
              >
                {allValues.title}
              </h5>
            </div>

            <div className="container-div">
              <Form.Label className="label-style">Quiz Title</Form.Label>
              <Form.Control
                type="input"
                name="title"
                value={allValues.title ? allValues.title : ""}
                onChange={handleChange}
                className="details-style"
                required
              />
            </div>

            <div className="container-div">
              <Form.Label className="label-style">Status</Form.Label>
              <Form.Select
                className="details-style"
                aria-label="Default select example"
                name="quizStatus"
                value={allValues.quizStatus}
                onChange={handleChange}
                required
              >
                <option value={ACTIVE_FLAG}>Active</option>
                <option value={INACTIVE_FLAG}>Inactive</option>
              </Form.Select>
            </div>

            <div className="container-div">
              <Form.Label className="label-style">
                Total Active Questions
              </Form.Label>
              <Form.Control
                value={editQuizDetails.questionDetails.length}
                aria-label="Default select example"
                disabled
                name="activeQUestion"
                className="details-style"
                required
              ></Form.Control>
            </div>

            <div className="container-div">
              <Form.Label className="label-style">
                No. of Test Questions
              </Form.Label>
              <Form.Select
                value={allValues.maxQuestion ? allValues.maxQuestion : ""}
                aria-label="Default select example"
                name="maxQuestion"
                className="details-style"
                onChange={handleChange}
                required
              >
                <option value="">Select from list</option>
                {genQuestions.map((option: any, index: any) => (
                  <option value={`${option}`}>{option}</option>
                ))}
              </Form.Select>
            </div>
            <div
              style={{ overflow: "wrap", width: "350px", marginLeft: "40%" }}
            >
              {maxQuestionError ? (
                <span style={{ color: "red" }}>{maxQuestionError}</span>
              ) : null}
            </div>

            <div className="container-div">
              <Form.Label className="label-style">Quiz Owner</Form.Label>
              <div
                style={{
                  width: "365px",
                  marginTop: "15px",
                  marginLeft: "15px",
                }}
              >
                <Multiselect
                  isObject={true}
                  onKeyPressFn={function noRefCheck() {}}
                  options={allQuizOwner.map((data: any, key: any) => ({
                    name: data.name,
                    lillyId: data.lillyId,
                  }))}
                  selectedValues={allValues.quizOwnerObject}
                  displayValue="name"
                  onSelect={onSelectQuizOwner}
                  onRemove={onRemoveQuizOwner}
                  showCheckbox
                />
                {quizOwnerError ? (
                  <span style={{ color: "red" }}>{quizOwnerError}</span>
                ) : null}
              </div>
            </div>

            <div className="container-div">
              <Form.Label className="label-style">Frequency (in months) </Form.Label>
              <Form.Control
                type="number"
                min="0"
                name="frequency"
                value={allValues.frequency ? allValues.frequency : ""}
                maxLength={2}
                onChange={handleChange}
                className="details-style"
              />
            </div>

            <div className="container-div">
              <Form.Label className="label-style">
                Last Assignment Date
              </Form.Label>
              <Form.Control
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                min="1970-01-01"
                max="2050-12-31"
                name="lastAssignmentDate"
                value={
                  allValues.lastAssignmentDate
                    ? convertDate(allValues.lastAssignmentDate)
                    : ""
                }
                onChange={handleChange}
                className="details-style"
                
              />
            </div>
            <div className="icons-div">
              <Button
                variant="secondary"
                style={{ backgroundColor: "#582630", margin: "5px" }}
                onClick={() => setButtonName("Save")}
                type="submit"
              >
                <IoIosSave style={{ fontSize: "25px", marginBottom: "3px" }} />
                &nbsp; Update
              </Button>
              <Button
                variant="secondary"
                style={{ backgroundColor: "#582630" }}
                type="submit"
                onClick={() => {
                  setButtonName("Edit Question");
                  dispatch(setSelectedAssignNav("EditQuestions"));
                }}
              >
                <MdModeEditOutline
                  style={{ fontSize: "25px", marginBottom: "3px" }}
                />
                &nbsp; Edit Questions
              </Button>
            </div>
          </Form>
        </div>
      ) : null}

      {show === "EditQuestions" ? (
        <ModifyQuestion
          allValues={allValues}
          questionObjectValues={questionObjectValues}
          setAllValues={setAllValues}
          setQuestionObjectValues={setQuestionObjectValues}
          setShow={setShow}
        />
      ) : null}

      {show === "AddQuestion" ? (
        <AddQuestion
          id={allValues.id}
          title={allValues.title}
          show={show}
          setShow={setShow}
        />
      ) : null}
    </div>
  );
};

export default ModifyQuiz;
