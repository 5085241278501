// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-quiz {
  margin-left: 65%;
  padding-bottom: 0px;
  height: 40px;
  background: none;
  border: none;
  color: black;
  font-weight: 500;
  font-style: Merriweather Sans;
}

.wrapper-div {
  height: calc(100vh - 5vh);
  overflow: auto;
}

.create-quizselected {
  margin-left: 65%;
  padding-bottom: 0px;
  height: 40px;
  background: none;
  border: none;
  background: #a25035;
  color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.quizzes-nav {
  display: inline-block;
  width: 100%;
  height: calc(100vh - 230px);
  border-radius: 20px 0px 0px 20px;
  overflow-y: auto;
}
@media only screen and (min-width : 600px) and (max-width : 1200px) {
  .quizzes-nav{
    display: inline-block;
  width: 100%;
  height: calc(100vh - 270px);
  border-radius: 20px 0px 0px 20px;
  overflow-y: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/modules/quizzes/new quizManagement/ShowEditQuizNav.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,4EAA4E;AAC9E;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,2BAA2B;EAC3B,gCAAgC;EAChC,gBAAgB;AAClB;AACA;EACE;IACE,qBAAqB;EACvB,WAAW;EACX,2BAA2B;EAC3B,gCAAgC;EAChC,gBAAgB;EAChB;AACF","sourcesContent":[".create-quiz {\n  margin-left: 65%;\n  padding-bottom: 0px;\n  height: 40px;\n  background: none;\n  border: none;\n  color: black;\n  font-weight: 500;\n  font-style: Merriweather Sans;\n}\n\n.wrapper-div {\n  height: calc(100vh - 5vh);\n  overflow: auto;\n}\n\n.create-quizselected {\n  margin-left: 65%;\n  padding-bottom: 0px;\n  height: 40px;\n  background: none;\n  border: none;\n  background: #a25035;\n  color: white;\n  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n\n.quizzes-nav {\n  display: inline-block;\n  width: 100%;\n  height: calc(100vh - 230px);\n  border-radius: 20px 0px 0px 20px;\n  overflow-y: auto;\n}\n@media only screen and (min-width : 600px) and (max-width : 1200px) {\n  .quizzes-nav{\n    display: inline-block;\n  width: 100%;\n  height: calc(100vh - 270px);\n  border-radius: 20px 0px 0px 20px;\n  overflow-y: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
