import { PASS_FLAG } from "../utilities/helper/constants";

export const hrp = [
  {
        id: 44,
        firstName: "Ankit",
        middleName: "",
        lastName: "Varshney",
        employeeId: 17264546,
        dateOfBirth: "2005-12-31",
        location: "Offshore",
        PAN: 8485,
        SSN: null,
        projectName: "iSelect",
        valueCycle: "Research IT",
        dateOfJoiningAccount: "2020-05-08",
        projectSponsorName: "Vibhor",
        projectSponsorLillyId: "V0X5302",
        bgcCompletionDate: "2008-05-08",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-05-06",
        workedBefore: "Yes",
        comments: "create",
        lastRequestedAction: "Modify",
        status: "Complete",
        updatedBy: "C293837",
        updatedAt: "2023-05-31T14:01:35.914Z",
        completedBy: "C293837",
        completedAt: "2020-05-08T10:13:13.897Z",
        lillyId: "C394859",
        globalId: "2394859",
      },
      {
        id: 43,
        firstName: "Chinkush",
        middleName: "",
        lastName: "Varshney",
        employeeId: 9876543,
        dateOfBirth: "2005-12-31",
        location: "Onsite",
        PAN: 2345,
        SSN: 5124,
        projectName: "Lilly",
        valueCycle: "Research IT",
        dateOfJoiningAccount: "2020-05-04",
        projectSponsorName: "Meenakshi",
        projectSponsorLillyId: "V0X5302",
        bgcCompletionDate: "2020-06-04",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-06-04",
        workedBefore: "Yes",
        comments: "chinskudh",
        lastRequestedAction: "Modify",
        status: "Complete",
        updatedBy: "C293837",
        updatedAt: "2020-05-04T13:12:27.120Z",
        completedBy: "C293837",
        completedAt: "2020-05-04T13:12:39.078Z",
        lillyId: "C293836",
        globalId: "2293836",
      },
      {
        id: 10,
        firstName: "New Test",
        middleName: "",
        lastName: "User",
        employeeId: 1618192,
        dateOfBirth: "2000-12-05",
        location: "Offshore",
        PAN: 876,
        SSN: null,
        projectName: "Test Project",
        valueCycle: "MD IT/ Research IT",
        dateOfJoiningAccount: "2020-04-16",
        projectSponsorName: "Test Sponsor",
        projectSponsorLillyId: "C276456",
        bgcCompletionDate: "2020-04-17",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-04-21",
        workedBefore: "No",
        comments: "New HRP creation",
        lastRequestedAction: "Create",
        status: "Complete",
        updatedBy: "V0X5302",
        updatedAt: "2020-05-01T11:16:40.990Z",
        completedBy: "V0X5302",
        completedAt: "2020-05-01T11:18:37.555Z",
        lillyId: "C987456",
        globalId: "1987456",
      },
      {
        id: 9,
        firstName: "New",
        middleName: "",
        lastName: "User",
        employeeId: 1617181,
        dateOfBirth: "2000-12-01",
        location: "Offshore",
        PAN: 987,
        SSN: null,
        projectName: "Change Project",
        valueCycle: "MD IT",
        dateOfJoiningAccount: "2020-04-16",
        projectSponsorName: "New Sponsor",
        projectSponsorLillyId: "C456213",
        bgcCompletionDate: "2020-05-16",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-05-17",
        workedBefore: "No",
        comments: "User has moved to MD IT area",
        lastRequestedAction: "Modify",
        status: "Pending",
        updatedBy: "V0X5302",
        updatedAt: "2020-05-01T10:58:44.013Z",
        completedBy: null,
        completedAt: null,
        lillyId: "C280912",
        globalId: "2280912",
      },
      {
        id: 8,
        firstName: "Test three",
        middleName: "",
        lastName: "last",
        employeeId: 1253563,
        dateOfBirth: "2005-12-31",
        location: "Offshore",
        PAN: 8797,
        SSN: null,
        projectName: "bkjcd",
        valueCycle: "Research IT",
        dateOfJoiningAccount: "2020-05-01",
        projectSponsorName: "skjdf",
        projectSponsorLillyId: "C387282",
        bgcCompletionDate: "2020-05-01",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-05-01",
        workedBefore: "No",
        comments: "creat",
        lastRequestedAction: "Create",
        status: "Complete",
        updatedBy: "C293837",
        updatedAt: "2020-05-01T09:55:46.293Z",
        completedBy: "C293837",
        completedAt: "2020-05-01T09:56:09.637Z",
        lillyId: "C223456",
        globalId: "2223456",
      },
      {
        id: 6,
        firstName: "Test two",
        middleName: "",
        lastName: "nkjn",
        employeeId: 6262663,
        dateOfBirth: "2005-12-31",
        location: "Offshore",
        PAN: 6676,
        SSN: null,
        projectName: "jbjk",
        valueCycle: "Research IT",
        dateOfJoiningAccount: "2020-05-01",
        projectSponsorName: "vkjdfv",
        projectSponsorLillyId: "C636363",
        bgcCompletionDate: "2020-05-01",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-05-01",
        workedBefore: "Yes",
        comments: "ffdkn",
        lastRequestedAction: "Create",
        status: "Complete",
        updatedBy: "C293837",
        updatedAt: "2020-05-01T09:53:22.010Z",
        completedBy: "C293837",
        completedAt: "2020-05-01T09:53:48.990Z",
        lillyId: "C123456",
        globalId: "2123456",
      },
      {
        id: 5,
        firstName: "Test One",
        middleName: "",
        lastName: "Last One",
        employeeId: 1727773,
        dateOfBirth: "2005-12-31",
        location: "Offshore",
        PAN: 8765,
        SSN: null,
        projectName: "Lilly",
        valueCycle: "Research IT",
        dateOfJoiningAccount: "2020-05-01",
        projectSponsorName: "Vibhor",
        projectSponsorLillyId: "V0X5302",
        bgcCompletionDate: "2020-05-01",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-05-01",
        workedBefore: "Yes",
        comments: "Create",
        lastRequestedAction: "Create",
        status: "Pending",
        updatedBy: "C293837",
        updatedAt: "2020-05-01T09:46:24.793Z",
        completedBy: null,
        completedAt: null,
        lillyId: null,
        globalId: null,
      },
      {
        id: 4,
        firstName: "Sonu",
        middleName: "",
        lastName: "Gupta",
        employeeId: 23243243,
        dateOfBirth: "2005-12-15",
        location: "Offshore",
        PAN: 3456,
        SSN: null,
        projectName: "lilly",
        valueCycle: "Research IT",
        dateOfJoiningAccount: "2020-04-17",
        projectSponsorName: "Vibhor",
        projectSponsorLillyId: "C345345",
        bgcCompletionDate: "2020-04-23",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-04-23",
        workedBefore: "No",
        comments: "create\n",
        lastRequestedAction: "Create",
        status: "Complete",
        updatedBy: "C293837",
        updatedAt: "2020-04-23T14:05:24.800Z",
        completedBy: "C293837",
        completedAt: "2020-04-23T14:06:50.667Z",
        lillyId: "V9X6789",
        globalId: "2096789",
      },
      {
        id: 3,
        firstName: "Sonu",
        middleName: "",
        lastName: "Gupta",
        employeeId: 1283848,
        dateOfBirth: "2005-12-31",
        location: "Offshore",
        PAN: 8789,
        SSN: null,
        projectName: "lilly",
        valueCycle: "Research IT",
        dateOfJoiningAccount: "2020-04-23",
        projectSponsorName: "Vibhor",
        projectSponsorLillyId: "V0X5302",
        bgcCompletionDate: "2020-04-23",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-04-23",
        workedBefore: "No",
        comments: "Create",
        lastRequestedAction: "Create",
        status: "Complete",
        updatedBy: "C293837",
        updatedAt: "2020-04-23T13:40:51.016Z",
        completedBy: "C293837",
        completedAt: "2020-04-23T13:41:25.978Z",
        lillyId: "C2938475",
        globalId: "22938475",
      },
      {
        id: 2,
        firstName: "Dr",
        middleName: "",
        lastName: "Test",
        employeeId: 1238488,
        dateOfBirth: "2005-12-14",
        location: "Offshore",
        PAN: 3345,
        SSN: null,
        projectName: "lilly",
        valueCycle: "Research IT",
        dateOfJoiningAccount: "2020-04-23",
        projectSponsorName: "Vibhor",
        projectSponsorLillyId: "C344859",
        bgcCompletionDate: "2020-04-23",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-04-23",
        workedBefore: "No",
        comments: "Creation test req",
        lastRequestedAction: "Create",
        status: "Complete",
        updatedBy: "C293837",
        updatedAt: "2020-04-23T08:30:52.782Z",
        completedBy: "C293837",
        completedAt: "2020-04-23T08:31:29.465Z",
        lillyId: "C234657",
        globalId: "2234657",
      },
      {
        id: 1,
        firstName: "Chinkush ",
        middleName: "",
        lastName: "Varshney",
        employeeId: 1716422,
        dateOfBirth: "2005-12-13",
        location: "Offshore",
        PAN: 2848,
        SSN: null,
        projectName: "iselect",
        valueCycle: "MD IT",
        dateOfJoiningAccount: "2020-04-13",
        projectSponsorName: "vibhor",
        projectSponsorLillyId: "V0X5302",
        bgcCompletionDate: "2020-04-15",
        ndaAdfSigned: "Yes",
        drugTestCleared: PASS_FLAG,
        drugTestDate: "2020-04-10",
        workedBefore: "No",
        comments: "New Joinee",
        lastRequestedAction: "Create",
        status: "Complete",
        updatedBy: "C293837",
        updatedAt: "2020-04-21T07:05:00.264Z",
        completedBy: "C293837",
        completedAt: "2020-04-21T07:05:24.176Z",
        lillyId: "C283747",
        globalId: "2337474",
      },
];
