import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createNewUser,
  getAllUser,
  updateCurrentUser,
  getAllSubarea,
  getAllRoles,
  getAllUserAuditDetails,
  getAllAreaSubarea,
  getUsers,
  excelApiSample,
  excelUserCreateorUpdate,
  excelAllUserData,
} from "../../service/userManageApi";
import { UserState } from "../../models/redux-models";
import { API_FAILED, API_LOADING, API_SUCCEEDED } from "../../utilities/helper/constants";



const initialState: UserState = {
  showUser: {},
  showEdit: "addUser",
  selectedUser: "",
  areaSlice:[],
  subareaSlice:[],
  apiStatus: "",
  apiStatusArea:"",
  apiStatusSubarea:"",
  roleSlice:[],
  apiStatusRoles:"",
  userInfo: [],
  userAuditApiStatus: "",
  userAuditData: [],
  userAuditLillyId: [],
  userReport: "",
  areaSubareaApiStatus1:"",
  areaSubareaData1: [],
  subareaData1: [],
  subareaApiStatus: "",
  userCount: 0,
  searchUserData: [],
  searchApiStatus: "",
  searchUserInfo: [],
  apiCreateUser: "",
  apiUpdateUser:"",
  apiCreateError:"",
  dataTableCount: 0,
};

export const getUserInfo = createAsyncThunk("/user", async (thunkAPI) => {
  const response = await getAllUser();
  return response;
});

export const getAreaSubarea1 = createAsyncThunk("/getAllAreaSubarea", async (data: any,thunkAPI) => {
  const response = await getAllAreaSubarea(data);
  return response;
});

export const getSubarea1 = createAsyncThunk("/getSubarea", async (data: any,thunkAPI) => {
  const response = await getAllSubarea(data);
  return response;
});

export const getSearchUser = createAsyncThunk("/getSearchUser", async (data: any,thunkAPI) => {
  const response = await getUsers(data);
  return response;
});

export const getSearchUserData = createAsyncThunk("/getSearchUserData", async (data: any,thunkAPI) => {
  const response = await getUsers(data);
  return response;
});

export const createUser = createAsyncThunk(
  "/createUser",
  async (data: any, thunkAPI) => {
    const response = await createNewUser(data);
    return response;
  }
);
export const updateUser = createAsyncThunk(
  "/updateUser",
  async (data: any, thunkAPI) => {
    const response = await updateCurrentUser(data);
    return response;
  }
);
export const getRoles = createAsyncThunk(
  "/getAllRoles",
  async (thunkAPI) => {
    const response = await getAllRoles();
    return response;
  }
);
export const getAllUserAuditDetail = createAsyncThunk("/useraudit", async (data: any, thunkAPI) => {
  const response = await getAllUserAuditDetails(data);
  return response;
});

export const excelApiSampleData = createAsyncThunk("/excelApiSample", async (data: any, thunkAPI) => {
  const response = await excelApiSample(data);
  return response;
});
export const excelApiAllUserData = createAsyncThunk("/excelAllUserData", async (data: any, thunkAPI) => {
  const response = await excelAllUserData(data);
  return response;
});
export const excelUserCreateOrUpdate = createAsyncThunk("/excelUserCreateOrUpdate", async (data: any, thunkAPI) => {
  const response = await excelUserCreateorUpdate(data);
  return response;
});

const userSlice = createSlice({
  name: "userNavigation",
  initialState,
  reducers: {
    showUserDetails: (state, action) => {
      state.showUser = action.payload;
      state.apiUpdateUser = "";
      state.apiCreateUser = "";
    },
    setShowEditType: (state, action) => {
      state.showEdit = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setUserReport: (state, action) => {
      state.userReport = action.payload;
    },
    resetuser: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getUserInfo.pending, (state) => {
      state.userInfo = [];
      state.apiStatus = API_LOADING;
    });
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.showUser= {};
      state.userInfo = payload;
      state.apiStatus = API_SUCCEEDED;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.userInfo = [];
      state.apiStatus = API_FAILED;
    });

    builder.addCase(createUser.pending, (state) => {
      state.apiCreateUser = API_LOADING;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.apiCreateUser = API_SUCCEEDED;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.apiCreateUser = API_FAILED;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.apiUpdateUser = API_LOADING;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.apiUpdateUser = API_SUCCEEDED;
    });
    builder.addCase(updateUser.rejected, (state, action) => {

      state.apiUpdateUser = API_FAILED;

    });
    builder.addCase(getSubarea1.pending, (state) => {
      state.subareaApiStatus = API_LOADING;
    });
    builder.addCase(getSubarea1.fulfilled, (state, { payload }) => {
      state.subareaData1 = payload.data;
      state.subareaApiStatus = API_SUCCEEDED;
    });
    builder.addCase(getSubarea1.rejected, (state, action) => {
      state.subareaData1 = [];
      state.subareaApiStatus = API_FAILED;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.roleSlice = [];
      state.apiStatusRoles = API_FAILED;
    });
    builder.addCase(getRoles.fulfilled, (state, {payload}) => {
      state.roleSlice = payload;
      state.apiStatusRoles = API_SUCCEEDED;
    });
    builder.addCase(getRoles.pending, (state, action) => {
      state.roleSlice = [];
      state.apiStatusRoles = API_LOADING;
    });
    builder.addCase(getAllUserAuditDetail.rejected, (state, action) => {
      state.userAuditLillyId = [];
      state.dataTableCount = 0;
      state.userAuditApiStatus = API_FAILED;
    });
    builder.addCase(getAllUserAuditDetail.fulfilled, (state, {payload}) => {
      state.userAuditLillyId = payload.data.rows;
      state.dataTableCount = payload.data.count
      state.userAuditApiStatus = API_SUCCEEDED;
    });
    builder.addCase(getAllUserAuditDetail.pending, (state, action) => {
      state.userAuditLillyId = [];
      state.dataTableCount = 0;
      state.userAuditApiStatus = API_LOADING;
    });
    builder.addCase(getAreaSubarea1.rejected, (state, action) => {
      state.areaSubareaData1 = [];
      state.areaSubareaApiStatus1 = API_FAILED;
    });
    builder.addCase(getAreaSubarea1.fulfilled, (state, {payload}) => {
      state.areaSubareaData1 = payload.data;
      state.areaSubareaApiStatus1 = API_SUCCEEDED;
    });
    builder.addCase(getAreaSubarea1.pending, (state, action) => {
      state.areaSubareaData1 = [];
      state.areaSubareaApiStatus1 = API_LOADING;
    });
    builder.addCase(getSearchUser.rejected, (state, action) => {
      state.searchUserInfo = [];
      state.userCount = 0;
      state.apiStatus = API_FAILED;
    });
    builder.addCase(getSearchUser.fulfilled, (state, {payload}) => {
      state.searchUserInfo = payload.data.rows;
      state.userCount = payload.data.count;
      state.apiStatus = API_SUCCEEDED;
    });
    builder.addCase(getSearchUser.pending, (state, action) => {
      // state.searchUserInfo = [];
      state.apiStatus = API_LOADING;
    });
    builder.addCase(getSearchUserData.rejected, (state, action) => {
      state.searchUserData = [];
      state.userCount = 0;
      state.searchApiStatus = API_FAILED;
    });
    builder.addCase(getSearchUserData.fulfilled, (state, {payload}) => {
      state.searchUserData = payload.data.rows;
      state.userCount = payload.data.count;
      state.searchApiStatus = API_SUCCEEDED;
    });
    builder.addCase(getSearchUserData.pending, (state, action) => {
      state.searchUserData = [];
      state.searchApiStatus = API_LOADING;
    });
  },
});

export default userSlice;
export { userSlice };
export const { showUserDetails, setShowEditType, setSelectedUser, resetuser, setUserReport } =
  userSlice.actions;
