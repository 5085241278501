import { BsPersonLinesFill } from "react-icons/bs";

const UserReport = () => {
 
  return (
    <>
      <div
        style={{
          display: "inline-block",
          width: "100%",
          height: "100%",
          borderRadius: "20px 0px 0px 20px ",
          textAlign:"center"
          
        }}
      >
      
     <BsPersonLinesFill  style={{margin:"auto",padding:"8px",fontSize:"340px",marginTop:"100px"}}/>
      </div>
    </>
  );
};

export default UserReport;