import { FullScreen, useFullScreenHandle } from "react-full-screen";
import TakeQuizContent from "./TakeQuizContent";
import TakeQuizNav from "./TakeQuizNav";
import { useEffect, useRef, useState } from "react";
import "./TakeQuiz.css";
import Swal from "sweetalert2";
import ReactDOM from "react-dom/client";
import Timer from "../../../../utilities/Timer/Timer";
import { EXIT_TEST_WARNING } from "../../../../utilities/helper/constants";

const TakeQuiz = () => {
  const handle = useFullScreenHandle();
  const firstUpdate = useRef(true);
  const [count, setCount] = useState<number>(0);
  const [displayQuizNo, setDisplayQuizNo] = useState<number>(0);
  const [submitQuiz, setSubmitQuiz] = useState<boolean>(false);
  useEffect(() => {
    if (firstUpdate.current) {
      handle.enter();
      firstUpdate.current = false;
      return;
    } else if (handle.active === false) {
      handleEscape();
    }
  }, [handle]);
  const handleContextMenu = (e: any) => {
    e.preventDefault();
  };

  const handleKeyboardEvent = (e: any) => {
    if (e.keyCode === 116) {
      Swal.fire({
        title: EXIT_TEST_WARNING,
        icon: "warning",
        target: "#swal2-container",
      });
      e.preventDefault();
      e.stopPropagation();
    }

    //ctrl+R
    if (e.keyCode === 82 && e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      Swal.fire({
        title: EXIT_TEST_WARNING,
        text: " ",
        icon: "warning",
        target: "#swal2-container",
      });
    }
    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const lostFocus = async () => {
    let timerInterval: any;
    return Swal.fire({
      title: EXIT_TEST_WARNING,
      icon: "warning",
      html: '<div id="clock-container"></div>',
      confirmButtonText: "Re-enter full screen",
      target: "#swal2-container",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        const clockContainer = ReactDOM.createRoot(
          document.getElementById("clock-container") as HTMLElement
        );
        clockContainer.render(<Timer />);
        timerInterval = setTimeout(() => {
          setSubmitQuiz(true);
          Swal.close();
        }, 10000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    })
  };

  const handleEscape = async () => {
    let confirmEvent= await lostFocus();
      if(confirmEvent.value){
        handle.enter()
      }
  };


  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardEvent);
    window.addEventListener("blur", lostFocus);
    return () => {
      document.removeEventListener("keydown", handleKeyboardEvent);
      window.removeEventListener("blur", lostFocus);
    };
  }, []);

  return (
    <>
      <div onContextMenu={handleContextMenu}>
        <FullScreen handle={handle}>
          <div className="takequizOuterdiv" id="swal2-container">
            <TakeQuizNav
              count={count}
              displayQuizNo={displayQuizNo}
              setDisplayQuizNo={setDisplayQuizNo}
            />
            <TakeQuizContent
              count={count}
              setCount={setCount}
              displayQuizNo={displayQuizNo}
              setDisplayQuizNo={setDisplayQuizNo}
              submitQuiz={submitQuiz}
            />
          </div>
        </FullScreen>
      </div>
    </>
  );
};
export default TakeQuiz;