import { useEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import {
  createQuiz,
  getAllQuizOwner,
  getAllQuizzesByOwner,
  setCreateQuizNavFlag,
  setQuestionIndex,
  setQuestionNav,
  setSelectedAssignNav,
} from "../../../../../store/slices/quizzesSlice";
import { IoMdAddCircle, IoMdArrowRoundBack } from "react-icons/io";
import { Button, Form, InputGroup } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { BiAddToQueue } from "react-icons/bi";
import { toast } from "react-toastify";
import {
  ACTIVE_FLAG,
  API_SUCCEEDED,
  MIN_QUESTION_ERROR,
  QUIZ_CREATION_SUCCESS,
  SIMILAR_QUES_PRESENT,
} from "../../../../../utilities/helper/constants";
import {
  sessionGetter,
  sessionSetter,
} from "../../../../../utilities/helper/common";
import Swal from "sweetalert2";
import { TiDelete } from "react-icons/ti";

interface CreateQuizProps {
  title: string;
  quizOwner: Array<string>;
  quizOwnerObject: Array<string>;
  maxQuestion: number;
  questions: Array<object>;
  createdBy: string | null;
}

interface QuestionProps {
  ques: string;
  ans: string;
  questionTy: string;
  options: string;
}

// let form: any;
const QuizTitle = () => {
  const dispatch = useAppDispatch();
  const {
    questionNav,
    editableQuestion,
    questionIndex,
    deleteQuestion,
    deleteQuestionIndex,
    quizOwner,
    quizOwnerApiStatus,
    createQuizApiStatus
  } = useAppSelector((store) => store.quizzesNavigation);
  const [showAddQuestion, setShowAddQuestion] = useState<Boolean>(false);
  const [lessOptionError, setLessOptionError] = useState<String>("");
  const [allValues, setAllValues] = useState<CreateQuizProps>({
    title: "",
    quizOwner: [],
    questions: [],
    maxQuestion: 0,
    createdBy: sessionGetter("uid"),
    quizOwnerObject: [],
  });
  const [questionObjectValues, setQuestionObjectValues] =
    useState<QuestionProps>({
      ques: "",
      ans: "",
      questionTy: "multiple",
      options: "",
    });
  const [optionObject, setOptionObject] = useState<Object>({});
  const [answerObject, setAnswerObject] = useState<Object>({});
  const [questionsobj, setQuestionsobj] = useState<any>([]);
  // const [save, setSave] = useState<boolean>(true);
  const firstUpdate = useRef(true);
  const [optionCount, setOptionCount] = useState<String[]>([]);
  const [allQuizOwner, setAllQuizOwner] = useState<any>([]);
  const [validated, setValidated] = useState(false);
  const [quizOwnerError, setQuizOwnerError] = useState<string>();
  let flag = false;
  // dispatch(setCreateQuizNavFlag("showCreateNav"));
  const handlePush = (e: any) => {
    if (showAddQuestion) {
     
      setQuestionObjectValues({
        ...questionObjectValues,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({ ...allValues, [e.target.name]: e.target.value });
    }
  };

  const handleOption = (e: any) => {
    setOptionObject({ ...optionObject, [e.target.name]: e.target.value });
  };

  const handleAnswer = async (e: any, index: string) => {
    if (e.target.checked) {
      await setAnswerObject({
        ...answerObject,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.checked === false) {
      await setAnswerObject((current: any) => {
        const copy = { ...current };
        delete copy[index];
        return copy;
      });
    }
  };

  useEffect(()=> {
    setLessOptionError("")
  },[editableQuestion])

  const deleteOption = async (value: string) => {
    let newArr = optionCount;
    newArr.splice(newArr.indexOf(value), 1);
    setOptionCount(newArr);
    await setOptionObject((current: any) => {
      const copy = { ...current };
      delete copy[value];
      return copy;
    });
    await setAnswerObject((current: any) => {
      const copy = { ...current };
      delete copy[value];
      return copy;
    });
  };

  useEffect(() => {
    setQuestionObjectValues({
      ...questionObjectValues,
      ques: editableQuestion.ques,
    });
    if (
      editableQuestion.questionTy === "single" ||
      editableQuestion.questionTy === "multiple"
    ) {
      setOptionCount([]);
      setOptionObject({});
      setAnswerObject({});
      let arr = JSON.parse(editableQuestion.options);
      arr.map((option: string, index: any) => {
        let generateKey: string = Math.random().toString();
        setOptionCount((oldArray) => [...oldArray, generateKey]);
        setOptionObject((currentState) => {
          return { ...currentState, [generateKey]: option };
        });
        if (
          JSON.parse(editableQuestion.ans).filter((ans: any) => ans === option)
            .length !== 0
        ) {
          setAnswerObject((currentState) => {
            return { ...currentState, [generateKey]: option };
          });
        }
        return;
      });
    }
  }, [editableQuestion]);

  useEffect(() => {
    setQuestionObjectValues({
      ...questionObjectValues,
      options: JSON.stringify(Object.values(optionObject)),
      ans: JSON.stringify(Object.values(answerObject)),
      questionTy:
        Object.values(answerObject).length > 1 ? "multiple" : "single",
    });
  }, [optionObject, answerObject, optionCount]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    sessionSetter("Option Object", JSON.stringify(optionObject));
    sessionSetter("Question", questionObjectValues.ques);
    sessionSetter("Answer Object", JSON.stringify(answerObject));
    sessionSetter("Option Count", JSON.stringify(optionCount));
  }, [optionObject, answerObject, optionCount, questionObjectValues.ques]);

  useEffect(() => {
    setAllValues({ ...allValues, questions: questionsobj });
  }, [questionsobj]);

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();
  //   form = e.currentTarget;
  //   setSave(!save);
  // };

  const handleCreateSubmit = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    e = form;
  };

  const onSelectQuizOwner = (selectedList: any) => {
    const liilyIds = selectedList.map((elm: any, key: any) => elm.id);
    setAllValues({
      ...allValues,
      quizOwner: liilyIds,
      quizOwnerObject: selectedList,
    });
    setQuizOwnerError("");
  };
  const onRemoveQuizOwner = (selectedList: any) => {
    const liilyIds = selectedList.map((elm: any, key: any) => elm.id);
    setAllValues({
      ...allValues,
      quizOwner: liilyIds,
      quizOwnerObject: selectedList,
    });
    if (selectedList.length === 0) {
      setQuizOwnerError("Please select the Owner of the Quiz");
    }
  };

  const isExists = (currentQuestion: any, newQuestion: any) => {
    if (
      newQuestion.replace(/[^\w\s]/gi, "").trim().toLowerCase() ===
      currentQuestion.replace(/[^\w\s]/gi, "").trim().toLowerCase()
    ) {
      flag = true;
      Swal.fire({
        title: SIMILAR_QUES_PRESENT,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          setQuestionsobj((oldObject: any) => [
            ...oldObject,
            questionObjectValues,
          ]);
          setOptionObject({});
          setAnswerObject({});
          setOptionCount([]);
          setQuestionObjectValues({
            ...questionObjectValues,
            ques: "",
          });
        }
      });
    }
  };

  useEffect(() => {
    const quiz = sessionGetter("Create Quiz");
    const question = sessionGetter("Question");
    const answer = sessionGetter("Answer Object");
    const option = sessionGetter("Option Object");
    const option_count = sessionGetter("Option Count");

    if (!quiz) {
      setAllValues({
        title: "",
        quizOwner: [],
        questions: [],
        maxQuestion: 0,
        createdBy: sessionGetter("uid"),
        quizOwnerObject: [],
      });
    } else {
      setAllValues(JSON.parse(quiz));
      let newArr = JSON.parse(quiz).questions;
      setQuestionsobj(newArr);
    }

    if (!question) {
      setQuestionObjectValues({ ...questionObjectValues, ques: "" });
    } else {
      setQuestionObjectValues({ ...questionObjectValues, ques: question });
    }
    if (!answer) {
      setAnswerObject({});
    } else {
      setAnswerObject(JSON.parse(answer));
    }
    if (!option) {
      setOptionObject({});
    } else {
      setOptionObject(JSON.parse(option));
    }
    if (!option_count) {
      setOptionCount([]);
    } else {
      setOptionCount(JSON.parse(option_count));
    }
    dispatch(getAllQuizOwner());
  }, []);

  useEffect(() => {
    sessionSetter("Create Quiz", JSON.stringify(allValues));
    dispatch(setQuestionNav(!questionNav));
  }, [allValues]);
  

  useEffect(() => {
    const value = sessionGetter("New Question List");
    if (value) {
      if (
        JSON.stringify(allValues.questions[deleteQuestionIndex]) ===
        JSON.stringify(questionObjectValues)
      ) {
        setQuestionsobj(JSON.parse(value));
        setOptionObject({});
        setAnswerObject({});
        setOptionCount([]);
        setQuestionObjectValues({
          ...questionObjectValues,
          ques: "",
        });
        dispatch(setQuestionIndex(-1));
      } else {
        setQuestionsobj(JSON.parse(value));
      }
    }
  }, [deleteQuestion]);

  useEffect(() => {
    if (quizOwnerApiStatus === API_SUCCEEDED) {
      const { data } = quizOwner;
      setAllQuizOwner(data);
    }
  }, [quizOwnerApiStatus]);

  useEffect(() => {
    if (createQuizApiStatus === API_SUCCEEDED) {
      toast.success(QUIZ_CREATION_SUCCESS);
      dispatch(setCreateQuizNavFlag(""));
      sessionSetter("Create Quiz", "");
    }
  }, [createQuizApiStatus]);
  let genQuestions = [];

  for (let i = 1; i <= 6; i++) {
    genQuestions.push(i * 5);
  }
  // useEffect(() => {
  //   setShowAddQuestion(true);
  // }, [editableQuestion]);
  return (
    <>
      {!showAddQuestion ? (
        <Form noValidate validated={validated} onSubmit={handleCreateSubmit}>
          <h4 className="title">Create Quiz</h4>
          <div className="d-inline-block" style={{ float: "left" }}>
          <IoMdArrowRoundBack
            className="delete-button"
            onClick={() => {dispatch(setSelectedAssignNav("modifyQuiz")); dispatch(setSelectedAssignNav(""));}}
          />
        </div>
          <div
            style={{
              width: "50%",
              margin: "auto",
            }}
          >
            <div style={{ margin: "10px", display: "inline-block" }}>
              <Form.Label>Quiz Title</Form.Label>

              <Form.Control
                style={{ width: "350px" }}
                type="text"
                placeholder="Quiz Title"
                name="title"
                value={allValues.title}
                onChange={(e) => {
                  handlePush(e);
                }}
                required
              />
            </div>

            <div style={{ margin: "10px", display: "inline-block" }}>
              <Form.Label>Select Minimum No. of Questions</Form.Label>
              <Form.Select
                value={allValues.maxQuestion}
                aria-label="Default select example"
                style={{ width: "350px" }}
                name="maxQuestion"
                onChange={(e) => {
                  handlePush(e);
                }}
                required
              >
                <option value="">Select from list</option>
                {genQuestions.map((option: any, index: any) => (
                  <option value={`${option}`}>{option}</option>
                ))}
              </Form.Select>
            </div>

            <div
              style={{
                margin: "10px",
                display: "inline-block",
                width: "350px",
              }}
            >
              <Form.Label>Quiz Owner</Form.Label>
              <Multiselect
                style={{ width: "350px" }}
                isObject={true}
                onKeyPressFn={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={onSelectQuizOwner}
                onRemove={onRemoveQuizOwner}
                options={
                  allQuizOwner
                    ? allQuizOwner.map((data: any, key: any) => ({
                        name: data.name,
                        id: data.lillyId,
                      }))
                    : []
                }
                showCheckbox
                selectedValues={allValues.quizOwnerObject}
                displayValue="name"
              />
              {quizOwnerError ? (
                <span style={{ color: "red" }}>{quizOwnerError}</span>
              ) : null}
            </div>

            <div style={{ textAlign: "center" }}>
              <Button
                variant="secondary"
                style={{ backgroundColor: "#582630" }}
                onClick={(e) => {
                  setValidated(true);
                  if (
                    allValues.title === "" &&
                    allValues.maxQuestion === 0 &&
                    allValues.quizOwner.length === 0
                  ) {
                    setQuizOwnerError("Please select the Owner of the Quiz");
                    return;
                  }
                  if (
                    allValues.title !== "" &&
                    allValues.maxQuestion !== 0 &&
                    allValues.quizOwner.length !== 0
                  ) {
                    setShowAddQuestion(true);
                    dispatch(setCreateQuizNavFlag("createNavFlag"));
                    dispatch(setSelectedAssignNav("createQuestion"));
                   
                    
                  }
                }}
              >
                <IoMdAddCircle
                  style={{ fontSize: "25px", marginRight: "5px" }}
                />
                Create Quiz
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <div>
          <div className="text-center" style={{ margin: "20px" }}>
            <div className="d-inline-block" style={{ float: "left" }}>
              <IoMdArrowRoundBack
                className="delete-button"
                onClick={async () => {
                  dispatch(setCreateQuizNavFlag(""));
                  await dispatch(setSelectedAssignNav("createQuiz"));
                  setShowAddQuestion(false);
                }}
              />
            </div>
            {sessionGetter("QuizDetails") !== null ? (
              <h3 className="text-center d-inline-block">{allValues.title}</h3>
            ) : (
              ""
            )}

            <div className="d-inline-block" style={{ float: "right" }}>
              <Button
                variant="secondary"
                style={{
                  backgroundColor: "#582630",
                  border: "none",
                }}
                disabled={allValues.questions.length === 0 ? true : false}
                onClick={async () => {
                  if (allValues.questions.length < allValues.maxQuestion) {
                    toast.error(
                      `${MIN_QUESTION_ERROR} ${allValues.maxQuestion}.`
                    );
                    return;
                  }
                  await dispatch(createQuiz(allValues));
                  await dispatch(setSelectedAssignNav(""));
                  const obj = {
                    status: ACTIVE_FLAG,
                    lillyId: sessionGetter("uid"),
                  };
                  await dispatch(getAllQuizzesByOwner(obj));
                }}
              >
                <BiAddToQueue
                  style={{ fontSize: "25px", marginRight: "5px" }}
                />{" "}
                Submit
              </Button>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <InputGroup className="question-input">
              <InputGroup.Text>Question</InputGroup.Text>
              <Form.Control
                as="textarea"
                value={questionObjectValues.ques}
                name="ques"
                onChange={(e) => {handlePush(e);}}
                style={{resize:'none'}}
                required
              />
            </InputGroup>
          </div>
          <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
            {optionCount.map((option, index) => (
              <div
                key={`option${index}`}
                className="d-flex justify-content-center"
              >
                <InputGroup className="option-input">
                  <InputGroup.Checkbox
                    className="checkbox"
                    name={option.toString()}
                    value={Object.values(optionObject)[index]}
                    onClick={(e: any) => handleAnswer(e, option.toString())}
                    checked={
                      Object.keys(answerObject).indexOf(option.toString()) !==
                      -1
                        ? true
                        : false
                    }
                  />
                  <Form.Control
                    name={option.toString()}
                    required
                    value={Object.values(optionObject)[index]}
                    onChange={async (e) => {
                      handleOption(e);
                      await setAnswerObject((current: any) => {
                        const copy = { ...current };
                        delete copy[option.toString()];
                        return copy;
                      });
                      if (Object.keys(optionObject).length >= 2) {
                        setLessOptionError("");
                      }
                    }}
                  />
                </InputGroup>
                <div className="d-flex justify-content-center align-items-center">
                  <TiDelete
                    className="delete-button"
                    onClick={(e: any) => deleteOption(option.toString())}
                  />
                </div>
              </div>
            ))}
          </div>
          {lessOptionError ? (
            <span style={{ color: "red",marginLeft:"5%" }}>{lessOptionError}</span>
          ) : null}
          <div className="text-center">
            <div className="d-inline-block button-container">
              <Button
              style={{ backgroundColor: "#582630", border:"none" }}
                onClick={() => {
                  setLessOptionError("");
                  setOptionCount((oldArray) => [
                    ...oldArray,
                    new Date().getTime().toString(),
                  ]);
                }}
                disabled={
                  optionCount.length > Object.values(optionObject).length ||
                  Object.values(optionObject).includes("")
                    ? true
                    : false
                }
              >
                Add Option
              </Button>
            </div>

            <div className="d-inline-block button-container">
              {questionIndex !== -1 ? (
                <Button
                style={{ backgroundColor: "#582630", border:"none" }}
                  onClick={async () => {
                    if(questionObjectValues.ques.length === 0)
                    {
                      setLessOptionError("Question cannot be empty !");
                    }
                    else if (
                      Object.keys(optionObject).length >
                      Array.from(
                        new Set(Object.values(optionObject).map((item: any) => item))
                      ).length
                    ) {
                      setLessOptionError("Options should be unique !");
                    }else {
                    if (Object.keys(optionObject).length >= 2) {
                      setLessOptionError("");
                      let newArr = questionsobj;
                      newArr[questionIndex] = questionObjectValues;
                      await setQuestionsobj(newArr);
                      await sessionSetter(
                        "Create Quiz",
                        JSON.stringify(allValues)
                      );
                      await dispatch(setQuestionNav(!questionNav));
                      await dispatch(setQuestionIndex(-1));
                      await setOptionObject({});
                      await setAnswerObject({});
                      await setOptionCount([]);
                      await setQuestionObjectValues({
                        ...questionObjectValues,
                        ques: "",
                      });
                    } else {
                      setLessOptionError("Please add atleast 2 options.");
                    }
                  }}}
                  disabled={
                    optionCount.length > Object.values(optionObject).length ||
                    Object.keys(optionObject).length < 1 ||
                    Object.values(optionObject).includes("") ||
                    Object.keys(answerObject).length < 1
                      ? true
                      : false
                  }
                >
                  Update
                </Button>
              ) : (
                <Button
                style={{ backgroundColor: "#582630", border:"none" }}
                  onClick={async () => {
                    if(questionObjectValues.ques.length === 0)
                    {
                      setLessOptionError("Question cannot be empty !");
                    }
                    else if (
                      Object.keys(optionObject).length >
                      Array.from(
                        new Set(Object.values(optionObject).map((item: any) => item))
                      ).length
                    ) {
                      setLessOptionError("Options should be unique !");
                    } else {
                    if (Object.keys(optionObject).length >= 2) {
                      setLessOptionError("");
                      allValues.questions.map((question: any) =>
                        isExists(question.ques, questionObjectValues.ques)
                      );
                      if (flag === false) {
                        setQuestionsobj((oldObject: any) => [
                          ...oldObject,
                          questionObjectValues,
                        ]);
                        setOptionObject({});
                        setAnswerObject({});
                        setOptionCount([]);
                        setQuestionObjectValues({
                          ...questionObjectValues,
                          ques: "",
                        });
                      }
                    } else {
                      setLessOptionError("Please add atleast 2 options.");
                    }
                  }
                  }}
                  disabled={
                    optionCount.length > Object.values(optionObject).length ||
                    Object.keys(optionObject).length < 1 ||
                    Object.values(optionObject).includes("") ||
                    Object.keys(answerObject).length < 1
                      ? true
                      : false
                  }
                >
                  Add Question
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuizTitle;
