import { useState } from "react";
import "../HrpNav.css";
import { Button, Form } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { BsFillEyeFill, BsPersonFillAdd } from "react-icons/bs";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import {
  setShowChecklistUserDetails,
  setShowChecklistUserType,
} from "../../../../store/commonActions";

const ChecklistNav = () => {
    // const [selectedHrp, setSelectedHrp] = useState<String>("Hrp");
  // const { checklistUsers } = checklist;
  const [selectedNavContent, setSelectedNavContent] =
    useState<any>("createChecklistt");

  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  // useEffect(() => {
  //   if (selectedHrp === "Checklist") setData(checklistUsers);
  //   else if (selectedHrp === "Hrp") setData(hrp);
  // }, []);

  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState<any>("");
  const searchUsers = (searchValue: any) => {
    setSearchInput(searchValue);
    // if (searchInput !== "") {
    //   const filteredData = data.filter((item: any) => {
    //     return Object.values(item)
    //       .join("")
    //       .toLowerCase()
    //       .includes(searchInput.toLowerCase());
    //   });
    //   setFilteredResults(filteredData);
    // } else {
    //   setFilteredResults(data);
    // }
  };

  const handleShow = (name: object) => {
    dispatch(setShowChecklistUserDetails(name));
  };
  return (
    <>
    <div>
        <Button
          className="add-user"
          onClick={() => {
            dispatch(setShowChecklistUserType("createChecklist"));
            setSelectedNavContent("createChecklist");
          }}
        >
          <BsPersonFillAdd style={{ fontSize: "25px", marginBottom: "3px" }} />
          &nbsp; Create
        </Button>
        <Form.Control
          type="search"
          placeholder="🕵️‍♂️ Search"
          style={{
            margin: "2%",
            marginLeft: "3%",
            marginTop: "3px",
            width: "90%",
          }}
          onChange={(e) => searchUsers(e.target.value)}
        />
      </div>
      <div
        style={{
          display: "inline-block",
          width: "100%",
          height: "60%",
          borderRadius: "20px 0px 0px 20px ",
          overflowY: "auto",
        }}
      >
      {searchInput.length > 1
            ? filteredResults.map((name: any, key: any) => (
                <div
                  className={
                    selectedNavContent === name
                      ? "selectedChecklist"
                      : "notSelectedChecklist"
                  }
                  onClick={() => {
                    setSelectedNavContent(name);
                  }}
                  key={key}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: "100%",
                      borderRadius: "20px 20px 20px 20px ",
                      boxShadow:
                        "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19",
                      padding: "2%",
                    }}
                  >
                    {/* Left div */}
                    <div
                      style={{
                        display: "inline-block",
                        width: "30%",
                        height: "100%",
                      }}
                    >
                      <FaUser
                        style={{
                          margin: "10%",
                          display: "block",
                          width: "80%",
                          height: "80%",
                          borderRadius: "5%",
                        }}
                      />
                    </div>
                    {/* right div  */}
                    <div
                      style={{
                        display: "inline-block",
                        width: "70%",
                        height: "100%",
                        margin: "2%",
                      }}
                    >
                      <strong>{name.lillyId}</strong>

                      <br />
                      <p
                        className={
                          selectedNavContent === name
                            ? "selectedChecklistUser"
                            : "notSelectedChecklistUser"
                        }
                      >
                        Status: {name.status}
                        <br />
                        <MdModeEditOutline
                          className={
                            selectedNavContent === name
                              ? "selectedChecklistIcon"
                              : "notSelectedChecklistIcon"
                          }
                          onClick={() => {
                            handleShow(name);
                            dispatch(setShowChecklistUserType("editChecklist"));
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              ))
            : data.map((name: any, key: any) => (
                <div
                  className={
                    selectedNavContent === name
                      ? "selectedChecklist"
                      : "notSelectedChecklist"
                  }
                  key={key}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: "100%",
                      borderRadius: "20px 20px 20px 20px ",
                      boxShadow:
                        "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19",
                      padding: "2%",
                    }}
                  >
                    {/* Left div */}
                    <div
                      style={{
                        display: "inline-block",
                        width: "30%",
                        height: "100%",
                      }}
                    >
                      <FaUser
                        style={{
                          margin: "10%",
                          display: "block",
                          width: "80%",
                          height: "80%",
                          borderRadius: "5%",
                        }}
                      />
                    </div>
                    {/* right div  */}
                    <div
                      style={{
                        display: "inline-block",
                        width: "70%",
                        height: "100%",
                        margin: "2%",
                      }}
                    >
                      <strong>{name.lillyId}</strong>

                      <br />
                      <p
                        className={
                          selectedNavContent === name
                            ? "selectedChecklistUser"
                            : "notSelectedChecklistUser"
                        }
                      >
                        Status: {name.status}
                        <br />
                        {name.status === "Complete" ? (
                          <BsFillEyeFill
                            className={
                              selectedNavContent === name
                                ? "selectedChecklistIcon"
                                : "notSelectedChecklistIcon"
                            }
                            onClick={() => {
                              setSelectedNavContent(name);
                              handleShow(name);
                              dispatch(
                                setShowChecklistUserType("showChecklist")
                              );
                            }}
                          />
                        ) : (
                          <MdModeEditOutline
                            className={
                              selectedNavContent === name
                                ? "selectedChecklistIcon"
                                : "notSelectedChecklistIcon"
                            }
                            onClick={() => {
                              setSelectedNavContent(name);
                              handleShow(name);
                              dispatch(
                                setShowChecklistUserType("editChecklist")
                              );
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              </div>
    </>
  )
}

export default ChecklistNav
