// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-circle {
    border: 6px solid #3498db;
    border-radius: 50%;
    border-top: 4px solid transparent;
    width: 50px;
    height: 50px;
    animation: spin 2.5s liner infinite linear;
    -webkit-animation: spin 2.5s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
}`, "",{"version":3,"sources":["webpack://./src/utilities/Loader/NavLoader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,iCAAiC;IACjC,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,4CAA4C;AAChD;;AAaA;IACI;QAEI,uBAAuB;MACzB;MACA;QAEE,yBAAyB;MAC3B;AACN","sourcesContent":[".loader-wrapper {\n    width: 100%;\n    height: 20%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.loader-circle {\n    border: 6px solid #3498db;\n    border-radius: 50%;\n    border-top: 4px solid transparent;\n    width: 50px;\n    height: 50px;\n    animation: spin 2.5s liner infinite linear;\n    -webkit-animation: spin 2.5s infinite linear;\n}\n\n@-webkit-keyframes spin {\n    0% {\n      -webkit-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    100% {\n      -webkit-transform: rotate(359deg);\n      transform: rotate(359deg);\n    }\n  }\n\n@keyframes spin {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n      }\n      100% {\n        -webkit-transform: rotate(359deg);\n        transform: rotate(359deg);\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
