import { useEffect, useState } from "react";
import "../HrpNav.css";
import { Button, Form } from "react-bootstrap";
import { hrp } from "../../../../dummyData/hrp";
import { FaHistory, FaUser } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import {
  setShowChecklistUserDetails,
  setShowHrpContent,
} from "../../../../store/commonActions";

const HrpNav = () => {
  const [selectedNavContent, setSelectedNavContent] =
    useState<any>("createChecklistt");
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    setData(hrp);
  }, []);

  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState<any>("");
  const searchUsers = (searchValue: any) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data.filter((item: any) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };

  const handleShow = (name: object) => {
    dispatch(setShowChecklistUserDetails(name));
  };
  return (
    <>
      <div>
        <Button
          className="add-user"
          onClick={() => {
            dispatch(setShowHrpContent("Hrp"));
            setSelectedNavContent("");
          }}
        >
          <BsPersonFillAdd style={{ fontSize: "25px", marginBottom: "3px" }} />
          &nbsp; Create
        </Button>
        <Form.Control
          type="search"
          placeholder="🕵️‍♂️ Search"
          style={{
            margin: "2%",
            marginLeft: "3%",
            marginTop: "3px",
            width: "90%",
          }}
          onChange={(e) => searchUsers(e.target.value)}
        />
      </div>
      <div
        style={{
          display: "inline-block",
          width: "100%",
          height: "60%",
          borderRadius: "20px 0px 0px 20px ",
          overflowY: "auto",
        }}
      >
        {searchInput.length > 1
          ? filteredResults.map((name: any, key:any) => (
            <div
            style={{margin:"3%"}}
            key={key}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  borderRadius: "20px 20px 20px 20px ",
                  boxShadow:
                    "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19",
                  padding: "2%",
                  backgroundColor: selectedNavContent===name?"#A25035":""
                }}
              >
                {/* Left div */}
                <div
                  style={{
                    display: "inline-block",
                    width: "30%",
                    height: "100%",
                  }}
                >
                  <FaUser
                    style={{
                      margin: "10%",
                      display: "block",
                      width: "80%",
                      height: "80%",
                      borderRadius: "5%",
                      color: selectedNavContent===name?"white":""
                    }}
                  />
                </div>
                {/* right div  */}
                <div
                  style={{
                    display: "inline-block",
                    width: "70%",
                    height: "100%",
                    margin: "2%",
                  }}
                >
                  <strong style={{color:selectedNavContent===name?"white":""}}>
                    {name.firstName}&nbsp;{name.lastName}
                  </strong>

                  <br />
                  <p
                    className={
                      selectedNavContent === name
                        ? "selectedHrpUser"
                        : "notSelectedHrpUser"
                    }
                  >
                    Employee ID: {name.employeeId}
                    <br />
                    Status: {name.status}
                    <FaHistory
                      className={
                        selectedNavContent === name
                          ? "selectedHrpIcon"
                          : "notSelectedHrpIcon"
                      }
                      onClick={() => {
                        setSelectedNavContent(name);
                        handleShow(name);
                        dispatch(setShowHrpContent("requestHistory"));
                      }}
                    />
                    
                    <MdModeEditOutline
                      className={
                        selectedNavContent === name
                          ? "selectedHrpIcon"
                          : "notSelectedHrpIcon"
                      }
                      onClick={() => {
                        setSelectedNavContent(name);
                        handleShow(name);
                        dispatch(setShowHrpContent("editHrpUser"));
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
            ))
          : data.map((name: any, key: any) => (
              <div
              style={{margin:"3%"}}
              key={key}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px 20px 20px 20px ",
                    boxShadow:
                      "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19",
                    padding: "2%",
                    backgroundColor: selectedNavContent===name?"#A25035":""
                  }}
                >
                  {/* Left div */}
                  <div
                    style={{
                      display: "inline-block",
                      width: "30%",
                      height: "100%",
                    }}
                  >
                    <FaUser
                      style={{
                        margin: "10%",
                        display: "block",
                        width: "80%",
                        height: "80%",
                        borderRadius: "5%",
                        color: selectedNavContent===name?"white":""
                      }}
                    />
                  </div>
                  {/* right div  */}
                  <div
                    style={{
                      display: "inline-block",
                      width: "70%",
                      height: "100%",
                      margin: "2%",
                    }}
                  >
                    <strong style={{color:selectedNavContent===name?"white":""}}>
                      {name.firstName}&nbsp;{name.lastName}
                    </strong>

                    <br />
                    <p
                      className={
                        selectedNavContent === name
                          ? "selectedHrpUser"
                          : "notSelectedHrpUser"
                      }
                    >
                      Employee ID: {name.employeeId}
                      <br />
                      Status: {name.status}
                      <FaHistory
                        className={
                          selectedNavContent === name
                            ? "selectedHrpIcon"
                            : "notSelectedHrpIcon"
                        }
                        onClick={() => {
                          setSelectedNavContent(name);
                          handleShow(name);
                          dispatch(setShowHrpContent("requestHistory"));
                        }}
                      />
                      
                      <MdModeEditOutline
                        className={
                          selectedNavContent === name
                            ? "selectedHrpIcon"
                            : "notSelectedHrpIcon"
                        }
                        onClick={() => {
                          setSelectedNavContent(name);
                          handleShow(name);
                          dispatch(setShowHrpContent("editHrpUser"));
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default HrpNav;
