import { Button } from "react-bootstrap";

import {
  Modal,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";

const QuizAssignConfirmModal = (props: any) => {
  const{show,setShow, setData, setAllValues, initialState}=props;
  

  // const [flag, setFlag] = useState(false);

  const handleClose = () => {
    setShow(false);
    setData([]);
    setAllValues(initialState);
  };

  

  const handleSubmit = () => {
    handleClose();
  
  };

  return (
    <>
        <Modal show={show} onHide={handleClose} size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
       
        <ModalBody>
        <span style={{ padding: "5px" }}>
            Quizzes successfully assigned
             </span>

        </ModalBody>

        <ModalFooter>
          <Button
            variant="primary"
            disabled={false}
            onClick={handleSubmit}
            style={{ margin: "auto" }}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default QuizAssignConfirmModal;
