import { useEffect, useState, useCallback, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";

import { MdCloudUpload, MdModeEditOutline } from "react-icons/md";
import { BsPersonFillAdd } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import {
  showUserDetails,
  setShowEditType,
  getSearchUser,
  getSearchUserData,
  excelApiSampleData,
  excelUserCreateOrUpdate,
  resetAreaSubarea,
  resetStatus,
} from "../../../../store/commonActions";
import { Form } from "react-bootstrap";
import "./ResourceManagementNav.css";
import "../../../../utilities/Loader/NavLoader.css";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  ACTIVE_FLAG,
  API_LOADING,
  API_SUCCEEDED,
  INACTIVE_FLAG,
  SEARCH_ERROR,
} from "../../../../utilities/helper/constants";
import * as XLSX from "xlsx";
import { sessionGetter } from "../../../../utilities/helper/common";
import { IoMdCloudDownload } from "react-icons/io";
import { excelApiAllUserData } from "../../../../store/slices/userSlice";
import { FaFileArrowDown } from "react-icons/fa6";
import { HiViewGridAdd } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const ResourceManagementNav = () => {
  const {
    showUser,
    searchUserInfo,
    apiStatus,
    searchUserData,
    searchApiStatus,
    apiUpdateUser,
    apiCreateUser,
    userCount,
  } = useAppSelector((store) => store.userNavigation);

  const dispatch = useAppDispatch();
  const navigate= useNavigate();
  const [userData, setUserData] = useState<Array<Object>>([]);
  const [searchInput, setSearchInput] = useState<any>("");
  // const [filteredResults, setFilteredResults] = useState<any>("");
  const [pageNo, setPageNo] = useState<number>(2);
  // const [excelData, setExcelData] = useState<any>([]);
  const [navStatus, setnavStatus] = useState<string>("A");

  useEffect(() => {
     setScrollFlag(false);
    if (navStatus === ACTIVE_FLAG || navStatus === INACTIVE_FLAG)
      dispatch(
        getSearchUserData({
          searchText: searchInput,
          page: 1,
          status: navStatus,
          pageSize: 100,
        })
      );
    else
      dispatch(
        getSearchUserData({ searchText: searchInput, page: 1, pageSize: 100, status:'A' })
      );
    // dispatch(showUserDetails({}));
  }, [apiUpdateUser, apiCreateUser, navStatus]);

  useEffect(() => {
    if (apiCreateUser === API_SUCCEEDED) {
      setUserData(searchUserInfo);
      setSearchInput("");
      dispatch(
        getSearchUserData({
          searchText: "",
          page: 1,
          status: navStatus,
          pageSize: 100,
        })
      );
    }
  }, [apiCreateUser]);

  useEffect(() => {
    if (apiUpdateUser === API_SUCCEEDED) {
      setUserData(searchUserInfo);
    }
  }, [apiUpdateUser]);

  useEffect(() => {
    if (apiStatus === API_SUCCEEDED) {
      searchUserInfo.map((item: object, key: any) =>
      {
        setUserData((oldArray) => [...oldArray, item])
      }
      );
      setPageNo(pageNo + 1);
      setScrollFlag(false);
    }
  }, [apiStatus]);

  // useEffect(() => {
  //   if (searchApiStatus === API_SUCCEEDED) {
  //     setFilteredResults(searchUserData);
  //   }
  // }, [searchApiStatus]);

  useEffect(() => {
    if (searchApiStatus === API_SUCCEEDED) {
      setUserData(searchUserData);
    }
  }, [searchApiStatus]);

  const debounceEffect = (searchValue: string, navValue: string) => {

    if (searchValue.length <= 3) {
      toast.error(`${SEARCH_ERROR}`);
      return;
    } else if (navValue === ACTIVE_FLAG || navValue === INACTIVE_FLAG) {
      setUserData([]);
      dispatch(
        getSearchUserData({
          searchText: searchValue,
          page: 1,
          status: navValue,
          pageSize: 100,
        })
      );
    }
    // else if (searchValue.toLowerCase() === "inactive") {
    //   setUserData([]);
    //   dispatch(
    //     getSearchUserData({
    //       searchText: "NA",
    //       page: 1,
    //       pageSize: 100,
    //     })
    //   );
    // } else if (searchValue.toLowerCase() === "active") {
    //   setUserData([]);
    //   dispatch(
    //     getSearchUserData({
    //       searchText: "A",
    //       page: 1,
    //       pageSize: 100,
    //     })
    //   );
    // }
    else {
      setUserData([]);
      dispatch(
        getSearchUserData({
          searchText: searchValue,
          page: 1,
          pageSize: 100,
        })
      );
    }
  };

  const debounceFn = useCallback(_.debounce(debounceEffect, 1000), []);

  const handleSearch = (searchValue: string) => {
    setSearchInput(searchValue);
     setPageNo(2);
    if (searchValue === "") {
      setPageNo(1);
      if (navStatus === "")
          {dispatch(
            getSearchUser({
              searchText: searchValue,
              page: 1,
              pageSize: 100,
            })
          );
          }
          else {
            dispatch(
              getSearchUser({
                searchText: searchValue,
                status: navStatus,
                page: 1,
                pageSize: 100,
              })
            );
          }
          setUserData([]);
          return true;
    }
    debounceFn(searchValue, navStatus);
    setUserData(searchUserData);
  };

  const handleEdit = (user: object) => {
    dispatch(showUserDetails(user));
  };
  const handleAddUser = async (type: String) => {
    await dispatch(setShowEditType(""));
    dispatch(setShowEditType(type));
  };
  const fileUploadFn = async (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(e.target.files[0]);

    fileReader.onload = (e) => {
      const bufferArray = e.target?.result;

      const wb = XLSX.read(bufferArray, { type: "buffer" });

      const wsname = wb.SheetNames[0];

      const worksheet = wb.Sheets[wsname];
      worksheet["A1"] = { t: "s", v: "index", r: "<t>index</t>" };
      worksheet["B1"] = { t: "s", v: "status", r: "<t>name</t>" };
      worksheet["C1"] = { t: "s", v: "tcsId", r: "<t>tcsId</t>" };
      worksheet["D1"] = { t: "s", v: "lillyId", r: "<t>lillyId</t>" };
      worksheet["E1"] = { t: "s", v: "globalId", r: "<t>globalId</t>" };
      worksheet["F1"] = { t: "s", v: "name", r: "<t>name</t>" };
      worksheet["G1"] = { t: "s", v: "tcsEmailId", r: "<t>tcsEmailId</t>" };
      worksheet["H1"] = { t: "s", v: "lillyEmailId", r: "<t>lillyEmailId</t>" };
      worksheet["I1"] = {
        t: "s",
        v: "applicationRole",
        r: "<t>applicationRole</t>",
      };
      worksheet["J1"] = { t: "s", v: "projectRole", r: "<t>projectRole</t>" };
      worksheet["K1"] = {
        t: "s",
        v: "onsiteOffshore",
        r: "<t>onsiteOffshore</t>",
      };
      worksheet["L1"] = {
        t: "s",
        v: "tcsAccountManager",
        r: "<t>tcsAccountManager</t>",
      };
      worksheet["M1"] = {
        t: "s",
        v: "lillyManagerLillyId",
        r: "<t>lillyManagerLillyId</t>",
      };
      worksheet["N1"] = {
        t: "s",
        v: "tcsSupervisor",
        r: "<t>tcsSupervisor</t>",
      };
      worksheet["O1"] = { t: "s", v: "lillyManager", r: "<t>lillyManager</t>" };
      worksheet["P1"] = {
        t: "s",
        v: "keySupplierPersonnel",
        r: "<t>keySupplierPersonnel</t>",
      };
      worksheet["Q1"] = { t: "s", v: "comments", r: "<t>comments</t>" };
      worksheet["R1"] = {
        t: "s",
        v: "lillyWorkerStartDate",
        r: "<t>lillyWorkerStartDate</t>",
      };
      worksheet["S1"] = {
        t: "s",
        v: "lillyWorkerEndDate",
        r: "<t>lillyWorkerEndDate</t>",
      };
      worksheet["T1"] = { t: "s", v: "areaObj", r: "<t>areaObj</t>" };
      var paresedData = XLSX.utils.sheet_to_json(worksheet);

      // setExcelData(paresedData);

      dispatch(
        excelUserCreateOrUpdate({
          excelData: paresedData,
          createdBy: sessionGetter("uid"),
        })
      );
    };
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleUplaodClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const[scrollFlag,setScrollFlag]=useState(false)
  useEffect(()=> {
    if(scrollFlag){
      if (userData.length < userCount) {
        if (navStatus === "")
          dispatch(
            getSearchUser({
              searchText: searchInput,
              page: pageNo,
              pageSize: 100,
            })
          );
          else {
            dispatch(
              getSearchUser({
                searchText: searchInput,
                status: navStatus,
                page: pageNo,
                pageSize: 100,
              })
            );
          }
      }    
    }  
  },[scrollFlag])
  const handleScroll = (e: any) => {
    if(userData.length  !=0){
      if(Math.floor(e.target.scrollHeight - e.target.scrollTop)<= e.target.clientHeight+150){
        setScrollFlag(true)
      }
    }
  };

  return (
    <div className="resourcenav-outerdiv">
      <div className="icons-div-usermanage">
        <BsPersonFillAdd
          title={`Add User`}
          className="adduser-icon"
          onClick={() => {
            handleAddUser("addUser");
          }}
        />
        &nbsp;
        <FaFileArrowDown
          title={`Sample File`}
          onClick={() => {
            dispatch(excelApiSampleData({ area: "all" }));
          }}
          style={{fontSize:"20px"}}
          className="adduser-icon"
        />
        &nbsp;
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => {
            fileUploadFn(e);
          }}
        />
        <MdCloudUpload
          title={`Import`}
          className="adduser-icon"
          onClick={handleUplaodClick}
        />
        &nbsp;
        <IoMdCloudDownload
          className="adduser-icon"
          title={`Download All User Data`}
          onClick={() => {
            dispatch(excelApiAllUserData({ area: "all" }));
          }}
        />
        <HiViewGridAdd className="adduser-icon"
          title={`Add new area`}
          onClick={() => {
           navigate("/areaSubarea");
           dispatch(resetAreaSubarea());
          }}/>
        <Form.Select
          className="dropdown-style"
          aria-label="Default select example"
          name="navStatus"
          value={navStatus}
          onChange={(e) => {
            setnavStatus(e.target.value);
            dispatch(setShowEditType("addUser"));
            setUserData([]);
            // setPageNo(1);
            setPageNo(2);
            setScrollFlag(false);
          }}
          required
        >
          <option value={ACTIVE_FLAG}>Active</option>
          <option value={INACTIVE_FLAG}>Inactive</option> 
          <option value={""}>All</option>
        </Form.Select>
      </div>
      <div>
        <Form.Control
          value={searchInput}
          type="search"
          placeholder="🕵️‍♂️ Search"
          className="resourcenav-search"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      {userData.length === 0 &&
      !(apiStatus === API_LOADING || searchApiStatus === API_LOADING) ? (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          No result to display
        </div>
      ) : null}

      <div className="resourcenav-innerdiv" onScroll ={handleScroll}>
        {userData.map((user: any, key: any) => (
          <div
            style={{
              width: "95%",
              height: "17%",
              background:
                showUser === user
                  ? "linear-gradient(#6ff7e8, #1f7ea1)"
                  : "#fff",
              borderRadius: "8px",
              margin: "2%",
              cursor: "pointer",
            }}
            title={`${user.name}`}
            onClick={() => {
              handleEdit(user);
              dispatch(setShowEditType("showUser"));
            }}
            key={key}
          >
            <div className="usercard-innerdiv">
              {/* Left div */}
              <div className="resourcenav-leftdiv">
                <FaUser className="fauser-icon" />
              </div>
              {/* right div  */}
              <div className="resourcenav-rightdiv">
                <div
                  style={{
                    width: "95%",
                    height: "100%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  <strong
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {user.name}
                  </strong>
                  <br />
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: showUser === user ? "#fff" : "#000",
                    }}
                  >
                    Lilly ID: {user.lillyId}
                    &nbsp;&nbsp;| &nbsp;&nbsp;TCS ID: {user.tcsId}
                    <br />
                    Status: {user.status === "NA" ? "Inactive" : "Active"}
                    <MdModeEditOutline
                      className="icon-style"
                      onClick={(event) => {
                        handleEdit(user);
                        dispatch(setShowEditType("editUser"));
                        event.stopPropagation();
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="loader-wrapper">
          {apiStatus === API_LOADING || searchApiStatus === API_LOADING ? (
            <div className="loader-circle"></div>
          ) : null}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          top: "96%",
          marginLeft: "22%",
          width: "70%",
          display: "inline-block",
          fontWeight: "500",
          fontSize: "12px",
          color: "grey",
        }}
      >
        {userData.length > 0 ? 1 : 0}-{userData.length} of {userCount}
      </div>
    </div>
  );
};

export default ResourceManagementNav;
