import { useState } from "react";
import UserManagementContent from "./UserManagementContent";
import UserManagementNav from "./UserManagementNav";

const UserManagement = () => {
  const [isCollasped, setIssCollasped] = useState<Boolean>(false);
  
    return (
      <>
         <div
        className="content-area"
      >
       
        <UserManagementNav isCollasped={isCollasped} setIssCollasped={setIssCollasped}/>
        
        <UserManagementContent setIssCollasped={setIssCollasped} isCollasped={isCollasped} />
      </div>
      </>
    );
  };
  export default UserManagement;