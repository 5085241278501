import Quiz from "./Quiz";
import "./TakeQuiz.css"


const TakeQuizContent = (props: any) => {
  const { count, setCount, displayQuizNo, setDisplayQuizNo,submitQuiz } = props;
  
 
  
  return (
    <>
      <div
        className="takequizContentdiv"
      >
          
        <Quiz
          count={count}
          setCount={setCount}
          displayQuizNo={displayQuizNo}
          setDisplayQuizNo={setDisplayQuizNo}
          submitQuiz={submitQuiz}
        />
      </div>
    </>
  );
};

export default TakeQuizContent;
