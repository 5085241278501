import { format } from "date-fns";
import { ROLES, MODULE_ACCESS, DATE_FORMAT_2 } from "./constants";

export const convertDate = (date: string) => {
  if (!date) return "";
  else {
    const convertedDate = format(new Date(date), DATE_FORMAT_2);
    return convertedDate;
  }
};

export const uniqueArea = (area: Array<Object>) => {
  const uniqueArea = Array.from(new Set(area.map((item: any) => item.area)));
  return uniqueArea;
};

export function checkModuleAccess(role: any) {
  const arr: Array<string> = [];
  if (role.includes("System administrator")) {
    return MODULE_ACCESS["System administrator"];
  }

  role.map((item: any) => {
    if (ROLES.includes(item)) {
      return arr.push(...MODULE_ACCESS[item]);
    }
    return;
  });
  return Array.from(new Set(arr.map((item: any) => item)));
}

export function checkRoles(role: any) {
  let flag = false;
  role.map((item: any) => {
    if (ROLES.includes(item)) {
      return flag = true;
    }
    return;
  });
  return flag;
}

export function sessionSetter(key: string, value: string) {
  sessionStorage.setItem(`${key}`, value);
}

export function sessionGetter(key: string) {
  return sessionStorage.getItem(`${key}`);
}

export function Search(data: any, searchInput: string, searchParam: any) {
  if (searchInput.length > 1) {
    return data.filter((items: any) => {
      return searchParam.some((newItem: any) => {
        if (!items[newItem]) {
          return;
        }
        //  else {
          return (
            items[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchInput.toLowerCase()) > -1
          );
        // }
      });
    });
  }
  return data;
}
