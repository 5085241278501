import Api from "./Api";

export const getUserRole = async (lillyId: string) => {
  try {
    var response = await Api.post(`/usermanage/userrole`, {
      lillyId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getassignedquizzes = async (lillyId: string) => {
  try {
    var response = await Api.get(`/test/getassignedquizzes/${lillyId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const userquizdetails = async (lillyId: string) => {
  try {
    var response = await Api.get(`/test/userquizdetails/${lillyId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const takequiz = async (id: string, assignTableId: string) => {
  try {
    var response = await Api.get(`/test/takequiz/${id}/${assignTableId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addscore = async (data: any) => {
  try {
    var response = await Api.post(`/test/addscore`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const initialScore = async (data: any) => {
  try {
    var response = await Api.post(`/test/initialScore`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
