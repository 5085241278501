import { useEffect } from "react";
import { Card } from "react-bootstrap";
import "./TrainingsNav.css";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { setTraining } from "../../../store/commonActions";

const TrainingsNav = () => {
  const dispatch = useAppDispatch();
  const {trainingPath} = useAppSelector((store)=>store.trainingNavigation)
  const [selected, setSelected] = useState<String>("TCSPharma");
  const handleClick = (cardSelected: String) => {
    dispatch(setTraining(cardSelected));
  };

  useEffect(()=> {
    setSelected(trainingPath);
  },[trainingPath])

  return (
    <div className="nav-outerdiv">
      <h4 className="nav-title">Trainings</h4>
      <div className="nav-innerdiv">
        <div className="nav-card trainingCards">
          <Card
            className={
              selected === "TCSPharma"
                ? "TCSPharma"
                : "card-content1 card-content"
            }
            onClick={() => handleClick("TCSPharma")}
          >
            TCS Pharma Mandatory Trainings
          </Card>
          <Card
            className={
              selected === "ITProcedures"
                ? "ITProcedures"
                : "card-content2 card-content"
            }
            onClick={() => handleClick("ITProcedures")}
          >
            IT Common Procedures
          </Card>
          <Card
            className={
              selected === "UsefulInfo"
                ? "UsefulInfo"
                : "card-content3 card-content"
            }
            onClick={() => handleClick("UsefulInfo")}
          >
            Useful Information and Links
          </Card>
          <Card
            className={
              selected === "Other" ? "Other" : "card-content4 card-content"
            }
            onClick={() => handleClick("Other")}
          >
            Other Mandatory Trainings
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TrainingsNav;
